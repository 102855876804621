//import logo from './logo.svg';
import './App.css';
import Menu from './module/Menu';
//import Loading from './module/Loading';
import Dashboard from './scenes/Dashboard';
//import Portfolio from './module/Portfolio';
import React from 'react';
import { useSelector,useDispatch } from 'react-redux'
//import { login } from './features/login/loginSlice'
import { fetchData } from './features/data/dataSlice'
//import { fetchEarningsData } from './features/history/historySlice';
import { fetchEarningData } from './features/earningHistory/earningHistorySlice';
import { fetchTransactionData,setTrxPeriod } from './features/transactionHistory/transactionHistorySlice';
import { fetchHistoryValueData } from './features/valueHistory/valueHistorySlice';
import LoginForm from './module/LoginForm';
import Portfolio from './scenes/Portfolio';
import Asset from './scenes/Asset';
/*
class App extends React.Component{

  constructor(props) {
    super(props);
    this.state={}
    this.state.loggedIn = null;
    this.state.currentModule = 'dashboard';
    //console.log("constructor: " +sessionStorage.getItem('username') );
    this.state.username=sessionStorage.getItem('username');
    this.state.apikey=sessionStorage.getItem('apikey');
    this.mainFetchUrl = global.config.baseUrl+'/v1/account-portfolios';//https://api.sunvesty.com/v1/account-portfolios';
    this.state.showLogin=false;
    this.state.data=null;
    this.state.mainDataLoading=false;
    
    this.handleLogin = this.handleLogin.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.fetchMainData = this.fetchMainData.bind(this);
    
    //this.fetchUserProfileData = this.fetchMainData.bind(this);
    
  }
  componentDidMount() {
    let apiKey = sessionStorage.getItem('apikey');
    let username = sessionStorage.getItem('username');
    if(this.state.username != null ){
      console.log('App mounted with user: ' + this.state.username);
      this.fetchMainData();
    }
    else{
      console.log('App mounted without user');
    }
    
  }
  componentWillUnmount() {
    
  }
  updatePrice(){
    //useDispatch().setPrice(this.state.priceMap);
  }
  handleLogin(login){
    console.log('App Handle login : ' + login);
    console.log('App Handle login APIkey: '+sessionStorage.getItem('apikey'));
    this.setState({username:login});
    this.setState({apikey:sessionStorage.getItem('apikey')});
    this.fetchMainData();
    
  }
  handleLogout(){
    this.setState({username:null,data:null,currentModule:'dashboard'});
    
  }
  fetchMainData(){
    console.log('Fetch main data App.js url: ' + this.mainFetchUrl)
    this.setState({mainDataLoading:true});
    fetch(this.mainFetchUrl, {
        method: 'GET',
        headers: {
           'Content-type': 'application/json; charset=UTF-8',
           'Authorization': 'Bearer ' +this.state.apikey
        },
        })
        .then((response) => {
            if(response.status == 200){
                return response.json()
            }
            else{
                console.log('Code: ' + response.status)
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            
        })
        .then((data) => {
          //setPosts((posts) => [data, ...posts]);
           console.log(data);
           this.setState({data:data,mainDataLoading:false
          }) ;
          console.log("Received data: " + this.state.data);
           
            
        })
        .catch((err) => {
           console.log(err.message);
           this.setState({mainDataLoading:false
           }) ;
        });
  }

  render() {

    if(this.state.data != null){
      console.log('App render data:' + this.state.data.portfolios.length);
    }
    
    var mainDisplay=<Dashboard data={this.state.data} loading={this.state.mainDataLoading}/>
    if(this.state.currentModule == 'portfolio'){
      mainDisplay=<Portfolio data={this.state.data} portfolioId="1"/>
    }
    return (
      <div>
        <Menu onLogin={this.handleLogin} onLogout={this.handleLogout} userName={this.state.username} data={this.state.data}/>
        <div className="main">
          {mainDisplay}
        </div>
      </div>
      
    );
  }
  
}
*/
function App(){
  
  //const apiKey = sessionStorage.getItem('apikey');
  const dispatch = useDispatch();
  //const prices = useSelector(selectAllPrices);
  const userProfile = useSelector(state => state.data.userprofile);
  const ctx = useSelector(state => state.ctx);
  console.log('profile: ' + userProfile);
  const apiKey = useSelector(state => state.login.apiKey);
  const dataStatus = useSelector(state => state.data.status);
  //const dataError = useSelector(state => state.data.error)

  var currentModule=<Dashboard />;
  if(ctx.displayModule==='dashboard'){
    currentModule=<Dashboard />;
  }
  else if(ctx.displayModule==='portfolio'){
    currentModule=<Portfolio/>;
  }
  else if(ctx.displayModule==='asset'){
    currentModule=<Asset/>;
  }
  const earningHistoryStatus = useSelector(state => state.earningHistory.status);
  //const earningHistoryError = useSelector(state => state.earningHistory.error);

  const valueHistoryStatus = useSelector(state => state.valueHistory.status);
  //const valueHistoryError = useSelector(state => state.valueHistory.error);

  const transactionStatus = useSelector(state => state.transactions.status)
  console.log('userprofile: ' + userProfile);
  var loginDisplay=<div></div>
  if(apiKey == null){
    
    loginDisplay=<LoginForm/>
  }
  else{
    //console.log('App hide login');
    if (apiKey != null && dataStatus === 'idle') {
      dispatch(fetchData());
    }
    /*
    if(apiKey != null && earningHistoryStatus === 'idle'){
      dispatch(fetchEarningData());
    }*/
    if(apiKey != null && valueHistoryStatus === 'idle'){
      dispatch(fetchHistoryValueData());
    }
    
    if(apiKey != null && transactionStatus === 'idle'){
      
      //dispatch(setTrxPeriod('1year'));
      dispatch(fetchTransactionData());
    }

    
  }


  /*useEffect(() => {
    console.log('App useEffect Called with apiKey: ' + apiKey + ' and dataStatus: ' + dataStatus);
    if (apiKey != null && dataStatus === 'idle') {
      dispatch(fetchData())
    }
  }, [dataStatus, dispatch])*/

  

  return(
    <div>
        <Menu />
        {loginDisplay}
        {currentModule}
       
        
    </div>
    

  )
}

export default App;
