import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
    userProfile:null ,
    apiKey:null,
    status: 'idle',
    error: null
  };

  export const login = createAsyncThunk('login/login', async (loginObj) => {
    const mainFetchUrl = global.config.baseUrl+'/login';//https://api.sunvesty.com/v1/account-portfolios';
    
    console.log('Login thunk called: ' + mainFetchUrl);
    const response = await fetch(mainFetchUrl, {
        method: 'POST',
        body: JSON.stringify({
            username: loginObj.username,
            pwd: loginObj.password
         }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8'
        }
        });
        console.log('login finished: ' + response.status);  
        if(response.status == 200){
            return response.json();
        }
        else if(response.status == 403){
            //throw new Error("Unauthorized");
            throw 403;
        }
    /*try{
        const response = await fetch(mainFetchUrl, {
            method: 'POST',
            body: JSON.stringify({
                username: loginObj.username,
                pwd: loginObj.password
             }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
            });
            console.log('login finished: ' + response.status);  
            if(response.status == 200){
                return response.json();
            }
            else if(response.status == 403){
                throw new Error("Unauthorized");
            }

            
    }
    catch (error) {
        console.log(error);
    }*/
    
    });
    export const loginSlice = createSlice({
        name: 'login',
        initialState,
        reducers: {
            logout: (state) => {
                // Redux Toolkit allows us to write "mutating" logic in reducers. It
                // doesn't actually mutate the state because it uses the Immer library,
                // which detects changes to a "draft state" and produces a brand new
                // immutable state based off those changes
                state.userProfile = null;
                state.apiKey = null;
                state.error=null;
                state.status='idle';
                console.log('Logout reducer called');
              }
        },
        extraReducers(builder) {
          builder
            .addCase(login.pending, (state, action) => {
              state.status = 'loading'
              console.log('-------------------loading')
            })
            .addCase(login.fulfilled, (state, action) => {
              state.status = 'succeeded'
              console.log('--------------------succeeded')
              const data = JSON.stringify(action.payload)
              console.log('action.payload: ' + data);
              // Add any fetched posts to the array
              sessionStorage.setItem('apikey',action.payload.key);
              state.apiKey=action.payload.key;
              //state.userProfile='jangab@gmail.com';
              //
            })
            .addCase(login.rejected, (state, action) => {
              state.status = 'failed'
              console.log('--------------------failed');
              state.error = action.error.message
            })
        }
      })
      
      
    
      export default loginSlice.reducer
      export const { logout } = loginSlice.actions