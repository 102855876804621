import React,{useState} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useSelector } from 'react-redux'
import moment from 'moment';
import {sunvestyGetDisplayValueFromInternal} from "../util/SunvestyRender";
import settingsImage from '../module/images/table_sort_asc.svg'

function PortfolioValueChart(props){

    const [isSettingsOpen, setSettingsOpen] = useState(false);
    const [chartType, setChartType] = useState('total');
    const values = useSelector(state => state.valueHistory.values);
    const prices = useSelector(state => state.data.prices);
    const up = useSelector(state => state.data.userprofile);
    
    const handleChartSettings = (newDisplay) => {
        setChartType(newDisplay);
        console.log('Clicked: ' + newDisplay);
    }

    if(values == null || up ==null){
        return (<div className='skeleton bar-chart'></div>)
    }
    else{
        
        var categoryData = [];
        var categorySeries = [];
        var seriesObj;
        var indexOfDate;
        var valueMoment;
        var valueDecimals=0;
        if(up.displayCurrency=='btc'){
            valueDecimals=4;
        }
        const displayCurrency = up.displayCurrency.toUpperCase();
        seriesObj={};
        seriesObj.name='Total';
        seriesObj.color='#ffa317'; 
        seriesObj.data=[];
        categorySeries.push(seriesObj);
       
        const areaSettingsObj = {
            fillColor: {
                linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1
                },
                stops: [
                    [0, '#ffa317'],
                    [1, Highcharts.color('#ffa317').setOpacity(0).get('rgba')]
                ]
            },
            marker: {
                radius: 2
            },
            lineWidth: 4,
            states: {
                hover: {
                    lineWidth: 2
                }
            },
            threshold: null
        };
        //categorySeries.push(seriesObj);
        values.forEach(eObj => {
            if(eObj.account === up.email){
                if(eObj.portfolioId === props.portfolio){
                
                }
                valueMoment=moment(eObj.timeStamp);
                categoryData.push(valueMoment.format('YYYY-MM-DD'))
                indexOfDate = categoryData.indexOf(valueMoment.format('YYYY-MM-DD'));
                if(props.portfolio == null){
                    categorySeries[0].data[indexOfDate] = sunvestyGetDisplayValueFromInternal(eObj.totalValue,up.displayCurrency,up.baseCurrency,prices);
                }
                else{
                    if(eObj.portfolioValues[props.portfolio] != null){
                        const portfolioValue = eObj.portfolioValues[props.portfolio];
                        if(prices != null){
                            categorySeries[0].data[indexOfDate] = sunvestyGetDisplayValueFromInternal(portfolioValue,up.displayCurrency,up.baseCurrency,prices);
                            
                        }
                        else{
                            categorySeries[0].data[indexOfDate] = eObj.totalValue;
                        }
                    }
                    else{
                        categorySeries[0].data[indexOfDate] = 0;
                    }
                }
            }
            
            
            
            
            
            
            //categorySeries[0].data[indexOfDate] = eObj.cryptoValue;
        });
        const options = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: 0,
                plotShadow: false,
                backgroundColor:'rgba(255,255,255,0.0)',
                type:'area'
            },
            legend: {
                enabled: false
            },
            title: {
                text: 'Value',
                style:{color:'white'}
            },
            xAxis: {
                categories: categoryData,
                labels:{
                    style:{"color":'rgba(255,255,255,0.25)'}
                }
            },
            yAxis: {
                labels:{
                    style:{"color":'rgba(255,255,255,0.25)'}
                },
                title:{
                    style:{"color":"#FFF"},
                    text:displayCurrency
                },
                min:0
            },
            tooltip: {
                shared: true,
                valueDecimals: valueDecimals,
                valuePrefix:'',
                valueSuffix: ' '+up.displayCurrency.toUpperCase(),
                headerFormat: '<span style="font-size:12px"><b>{point.key}</b></span><br>'
            },
            plotOptions: {
                area: areaSettingsObj
            },     
            credits: {
                enabled: false
            },
            series: categorySeries
          };
          
        return (
            <div>
                
                
                <HighchartsReact highcharts={Highcharts} options={options} />
    
            </div>
        );
    }
    
}

export default PortfolioValueChart;