import React,{useState,useEffect} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useSelector } from 'react-redux'
import moment from 'moment';
import {sunvestyGetDisplayValueFromInternal} from "../util/SunvestyRender";
import settingsImage from '../module/images/table_sort_asc.svg'

function Chart(){

    const dateFormat= 'YYYY-MM-DD';
    const [isSettingsOpen, setSettingsOpen] = useState(false);

    const [chartType, setChartType] = useState('total');
    const [categoryData, setCategoryData] = useState([]);
    const [periodToShow, setPeriodToShow] = useState('1month');
    const values = useSelector(state => state.valueHistory.values);
    const prices = useSelector(state => state.data.prices);
    const up = useSelector(state => state.data.userprofile);
    const valuePeriod =  useSelector(state => state.valueHistory.period);
    useEffect(()=>{
        var iterMoment = moment();
        const todayMoment = moment();
        var categoryDays=1;
        var cData = [];
        if(periodToShow === '1month'){
            iterMoment = moment().subtract(1,'months');
        }
        else if(periodToShow === '2months'){
            iterMoment = moment().subtract(2,'months');
        }
        else if(periodToShow === '3months'){
            iterMoment = moment().subtract(3,'months');
            categoryDays=7;
        }
        else if(periodToShow === '6months'){
            iterMoment = moment().subtract(6,'months');
            categoryDays=7;
        }
        else if(periodToShow === '1year'){
            iterMoment = moment().subtract(11,'months');
            categoryDays=7;
        }
        while(iterMoment.isBefore(todayMoment)){
            cData.push(iterMoment.format(dateFormat));
            iterMoment = iterMoment.add(categoryDays,'days');
        }
        setCategoryData(cData);
       
    }, [values, periodToShow])
    useEffect(()=>{
        setPeriodToShow(valuePeriod);
       
    }, [valuePeriod])
    const handleChartSettings = (newDisplay) => {
        setChartType(newDisplay);
        console.log('Clicked: ' + newDisplay);
    }

    if(values == null || up ==null){
        return (<div className='skeleton bar-chart'></div>)
    }
    else{
        
        var categorySeries = [];
        var seriesObj;
        var indexOfDate;
        var valueMoment;
        var valueDecimals=0;
        if(up.displayCurrency==='btc'){
            valueDecimals=4;
        }
        const displayCurrency = up.displayCurrency.toUpperCase();
        seriesObj={};
        seriesObj.name='Total';
        seriesObj.color='#ffa317'; 
        seriesObj.data=[];
        categorySeries.push(seriesObj);
        seriesObj={};
        seriesObj.name='Crypto';
        seriesObj.color='#ffa317'; 
        seriesObj.data=[];
        var areaSettingsObj={};
        if(chartType === 'all'){
            categorySeries = [];
            
            seriesObj={};
            seriesObj.name='Crypto';
            //seriesObj.color='#ffa317'; 
            seriesObj.data=[];
            categorySeries.push(seriesObj);

            seriesObj={};
            seriesObj.name='Bank';
            //seriesObj.color='#ffa317'; 
            seriesObj.data=[];
            categorySeries.push(seriesObj);

            seriesObj={};
            seriesObj.name='Stocks';
            //seriesObj.color='#ffa317'; 
            seriesObj.data=[];
            categorySeries.push(seriesObj);

            seriesObj={};
            seriesObj.name='Real Estate';
            //seriesObj.color='#ffa317'; 
            seriesObj.data=[];
            categorySeries.push(seriesObj);

            areaSettingsObj = {
                stacking: 'normal',
                lineColor: '#666666',
                lineWidth: 1,
                fillColor:null,
                marker:{enabled:false}
                /*marker: {
                    lineWidth: 1,
                    lineColor: '#666666'
                }*/
            }

        }
        else{
            areaSettingsObj = {
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, '#ffa317'],
                        [1, Highcharts.color('#ffa317').setOpacity(0).get('rgba')]
                    ]
                },
                marker: {
                    radius: 2
                },
                lineWidth: 4,
                states: {
                    hover: {
                        lineWidth: 2
                    }
                },
                threshold: null
            };
        }
        categoryData.forEach((element,i) => {
            categorySeries.forEach((element,j) => {
                categorySeries[j].data[i]=0;
            });
        });
        //categorySeries.push(seriesObj);
        values.forEach(eObj => {
            valueMoment=moment(eObj.timeStamp);
            //categoryData.push(valueMoment.format(dateFormat));
		    indexOfDate = categoryData.indexOf(valueMoment.format(dateFormat));
            if(prices != null){
                if(chartType === 'total'){
                    categorySeries[0].data[indexOfDate] += sunvestyGetDisplayValueFromInternal(eObj.totalValue,up.displayCurrency,up.baseCurrency,prices);
                }
                else if(chartType === 'crypto'){
                    categorySeries[0].data[indexOfDate] += sunvestyGetDisplayValueFromInternal(eObj.cryptoValue,up.displayCurrency,up.baseCurrency,prices);
                }
                else if(chartType === 'bank'){
                    categorySeries[0].data[indexOfDate] += sunvestyGetDisplayValueFromInternal(eObj.bankValue,up.displayCurrency,up.baseCurrency,prices);
                }
                else if(chartType === 'stock'){
                    categorySeries[0].data[indexOfDate] += sunvestyGetDisplayValueFromInternal(eObj.stockValue,up.displayCurrency,up.baseCurrency,prices);
                }
                else if(chartType === 'all'){
                    categorySeries[0].data[indexOfDate] += sunvestyGetDisplayValueFromInternal(eObj.cryptoValue,up.displayCurrency,up.baseCurrency,prices);
                    categorySeries[1].data[indexOfDate] += sunvestyGetDisplayValueFromInternal(eObj.bankValue,up.displayCurrency,up.baseCurrency,prices);
                    categorySeries[2].data[indexOfDate] += sunvestyGetDisplayValueFromInternal(eObj.stockValue,up.displayCurrency,up.baseCurrency,prices);
                    categorySeries[3].data[indexOfDate] += sunvestyGetDisplayValueFromInternal(eObj.realEstateValue,up.displayCurrency,up.baseCurrency,prices);
                }
                
            }
            else{
                categorySeries[0].data[indexOfDate] = eObj.totalValue;
            }
            
            //categorySeries[0].data[indexOfDate] = eObj.cryptoValue;
        });
        const options = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: 0,
                plotShadow: false,
                backgroundColor:'rgba(255,255,255,0.0)',
                type:'area'
            },
            legend: {
                enabled: false
            },
            title: {
                text: 'Value',
                style:{color:'white'}
            },
            xAxis: {
                categories: categoryData,
                labels:{
                    style:{"color":'rgba(255,255,255,0.25)'}
                }
            },
            yAxis: {
                labels:{
                    style:{"color":'rgba(255,255,255,0.25)'}
                },
                title:{
                    style:{"color":"#FFF"},
                    text:displayCurrency
                },
                min:0
            },
            tooltip: {
                shared: true,
                valueDecimals: valueDecimals,
                valuePrefix:'',
                valueSuffix: ' '+up.displayCurrency.toUpperCase(),
                headerFormat: '<span style="font-size:12px"><b>{point.key}</b></span><br>'
            },
            plotOptions: {
                area: areaSettingsObj
            },     
            credits: {
                enabled: false
            },
            series: categorySeries
          };
          
        return (
            <div>
                
                <div className='text-right'>
                    <span className={`select-one ${isSettingsOpen?'open':''}`} onClick={() => setSettingsOpen(!isSettingsOpen)}>
                        <span className="selected">{chartType}</span>
                            <div className="select-items">
                                <div className="item" onClick={() => handleChartSettings('total')}>Total</div>
                                <div className="item" onClick={() => handleChartSettings('crypto')}>Crypto</div>
                                <div className="item" onClick={() => handleChartSettings('stock')}>Stocks</div>
                                <div className="item" onClick={() => handleChartSettings('bank')}>Bank</div>
                                <div className="item" onClick={() => handleChartSettings('all')}>All</div>
                            </div>
                    </span>
                </div>
    
                <HighchartsReact highcharts={Highcharts} options={options} />
    
            </div>
        );
    }
    
}

export default Chart;