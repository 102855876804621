import PortfolioAssetCard from "./PortfolioAssetCard";
import { useSelector } from 'react-redux'
import React,{useState,useEffect} from 'react';

function PortfolioAssetCardGrid(props) {
  const assets = props.assets;//useSelector(state => state.data.assets);
  const [filteredAssets, setFilteredAssets] = useState([]);
  

  useEffect(()=>{
    var filteredArray=[];
    if(props.assets != null){
      props.assets.forEach(aObj => {
        filteredArray.push(JSON.parse(JSON.stringify(aObj)));
      });
    }
    else if(assets != null){
      assets.forEach(aObj => {
        
        if(props.portfolio != null && aObj.portfolioId === props.portfolio.id){
          filteredArray.push(JSON.parse(JSON.stringify(aObj)));
        }
        else if(props.code != null && aObj.code === props.code){
          filteredArray.push(JSON.parse(JSON.stringify(aObj)));
        }
      });
    }
    filteredArray.sort(function(a, b){return b.value - a.value});
    setFilteredAssets(filteredArray);
   
}, [assets,props])
    
    
    const pAssetCards = filteredAssets.map((pfObj) =>
      <PortfolioAssetCard portfolioAsset={pfObj} key={pfObj.id}/>
    );
    

    return (
        <div>
          <div>Total: {filteredAssets.length}</div>
          <div className="card-layout">
            {pAssetCards}
          </div>
           
        </div>
      );
}
export default PortfolioAssetCardGrid;