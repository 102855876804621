import React,{useState,useEffect} from 'react';
import { useSelector,useDispatch } from 'react-redux'
import {setObjectId} from '../../features/context/contextSlice'

function SelectBlockchain(props) {
    const dispatch = useDispatch();
    const ctx = useSelector(state => state.ctx);
    var selectedAsset=null;
    const [isOpen, setOpen] = useState(false);
    const [availableAssetCodes, setAvailableAssetCodes] = useState([]);
    

    const handleSelect = (newObject) => {
        
        props.callback(newObject);
    }
    var selectItems = null;
    if(props.blockchains != null){
        selectItems = props.blockchains.map((aObj) =>
            <div className="item" onClick={() => handleSelect(aObj)} key={aObj.id}>{aObj}</div>
        );
    }
    
    

    if(ctx != null){
        return (
            <span className={`select-one ${isOpen?'open':''}`} onClick={() => setOpen(!isOpen)}>
                <span className="selected">{props.displayName}</span>
                    <div className="select-items">
                        <div className="item" onClick={() => handleSelect(null)}>All</div>
                        {selectItems}
                    </div>
            </span>
        )
    }
    else{
        return(<div>No ctx</div>)
    }
    
}
export default SelectBlockchain;