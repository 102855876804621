import React,{useEffect,useState} from 'react';
import PortfolioCardGrid from '../module/PortfolioCardGrid';
//import Loading from './Loading';
import Card from '../module/Card';
import SelectDisplayCurrency from '../module/ui/SelectDisplayCurrency';
import SelectDisplayPeriod from '../module/ui/SelectDisplayPeriod';
import {sunvestyGetDisplayValueFromInternal} from "../util/SunvestyRender";
import Chart from '../module/Chart'
import BarChart from '../module/BarChart';
import { useSelector,useDispatch } from 'react-redux'
import {setDisplayModule,setObjectId} from '../features/context/contextSlice'
//import { selectAllPrices,fetchPrices } from '../features/price/priceSlice'
/*class Dashboard extends React.Component{

    constructor(props) {
        super(props);
        this.state={}
        
        
      }
      componentDidMount() {
        console.log('Dashboard mounted');
        
        
      }  
    render() {
        const prices = useSelector(selectAllPrices)
        console.log('Dashboard prices: ' + prices['usd-sek']);
        var portfolios = null;
        var totalInvested=0.0;

        if(this.props.data != null){
            portfolios = this.props.data.portfolios;
            portfolios.forEach(pfObj => {
                totalInvested +=pfObj.invested;
            });
        }
        var loadingData=(<div></div>);
        if(this.props.loading){
            loadingData=(<div>Loading</div>);
        }
        const value=123.3;
        return (
            <div>
                <div className="card-layout">
                    <Card title="Earning (3 MONTHS)" value={value} loading={this.props.loading}/>
                    <Card title="Earning (ALL)" value={value} loading={this.props.loading}/>
                    <Card title="Value" value={value} loading={this.props.loading}/>
                    <Card title="Invested" value={totalInvested} loading={this.props.loading}/>
                </div>
                <div>Dashboard</div>

                {this.props.loading ? <Loading/>:<PortfolioCardGrid portfolios={portfolios}/>}
                
            </div>
        )

    }
}*/
/*function getPortfolio(portfolios,aObj){
    var returnObj = null;
    portfolios.forEach(pfObj => {
        if(aObj.portfolioId == pfObj.id){
            returnObj = pfObj;
        }
    });
    return returnObj;
}*/
function Dashboard(props) {
    const dispatch = useDispatch();
    //const prices = useSelector(state => state.data.prices)//useSelector(selectAllPrices);
    const portfolios = useSelector(state => state.data.portfolios);
    const assets = useSelector(state => state.data.assets);

    //const earnings = useSelector(state => state.earningHistory.earnings);
    const earnings = useSelector(state => state.transactions.earnings);
    const earningsPeriod = useSelector(state => state.transactions.period);
    var earningPeriodTitle='Earning (12 MONTHS)';
    if(earningsPeriod === '1month'){
        earningPeriodTitle='Earning (1 MONTH)';
    }
    else if(earningsPeriod === '3months'){
        earningPeriodTitle='Earning (3 MONTHS)';
    }
    else if(earningsPeriod === '6months'){
        earningPeriodTitle='Earning (6 MONTHS)';
    }
    const up = useSelector(state => state.data.userprofile);
    const prices = useSelector(state => state.data.prices);
    
    const [liquidFunds, setLiquidFunds] = useState(0.0);
    //const [investedFunds, setInvestedFunds] = useState(0.0);
    
    var totalInvested=0.0;
    var totalValue=0.0;
    var totalEarning=0.0;
    var perdiodEarning=0.0;
    const portoflioClick = (portfObj) => {
        //console.log('Portfolio clicked: ' + portfObj.id);
        dispatch(setDisplayModule('portfolio'));
        dispatch(setObjectId(portfObj.id));

    }
    const downloadFile = async () => {
        console.log('Download file');
        const mainFetchUrl = global.config.baseUrl+'/v1/api/xls-transactions';
        const apiKey = sessionStorage.getItem('apikey');
        const response = await fetch(mainFetchUrl, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer ' +apiKey
            },
        });
            
        const blob = await response.blob();
        //const url = window.URL.createObjectURL(blob);
        const url = window.URL.createObjectURL(
            new Blob([blob]),
          );
        /**/const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        // the filename you want
        a.download = "sunvesty-data-"+up.email+".xlsx";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        
        /*const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();*/
    };
    
    
    const periodEarningPieData=[{name:'Bank',y:0,color:"#972424"},
                            {name:'Crypto',y:0,color:"#f6931a"},
                            {name:'Stocks',y:0,color:"#00701e"},
                            {name:'Real Estate',y:0,color:"#287ed5"},
                            {name:'Other',y:0,color:"#33aa82"}];
    const investedPieData=[{name:'Bank',y:0,color:"#972424"},
                            {name:'Crypto',y:0,color:"#f6931a"},
                            {name:'Stocks',y:0,color:"#00701e"},
                            {name:'Real Estate',y:0,color:"#287ed5"},
                            {name:'Other',y:0,color:"#33aa82"}];
    const valuePieData=[{name:'Bank',y:0,color:"#972424"},
                            {name:'Crypto',y:0,color:"#f6931a"},
                            {name:'Stocks',y:0,color:"#00701e"},
                            {name:'Real Estate',y:0,color:"#287ed5"},
                            {name:'Other',y:0,color:"#33aa82"}];
    const emptyLiquidPieData=[{name:'Bank',y:0,color:"#972424"},
                            {name:'Crypto',y:0,color:"#f6931a"},
                            {name:'Stocks',y:0,color:"#00701e"},
                            {name:'Real Estate',y:0,color:"#287ed5"},
                            {name:'Other',y:0,color:"#33aa82"}];

    const [liquidPieData, setLiquidPieData] = useState(emptyLiquidPieData);
    
    const getPortfolioById = (portfolioId) => {
        var returnObj = null;
        if(portfolios != null){
            portfolios.forEach(pfObj => {
                if(portfolioId===pfObj.id){
                    returnObj = pfObj;
                }
            });
        }
        //console.log('Should return portfolio: ' + returnObj.name);
        return returnObj;
        
        
    }
    useEffect(()=>{
        var iterFunds = 0;
        var currentValue=0;
        const chartData = JSON.parse(JSON.stringify(emptyLiquidPieData));
        if(assets != null){
            assets.forEach(aObj => {
                currentValue=0;
                if(aObj.code === 'sek' || aObj.code === 'eur'){
                    currentValue = sunvestyGetDisplayValueFromInternal(aObj.value,up.displayCurrency,up.baseCurrency,prices); 
                    iterFunds += currentValue;
                    //iterFunds += aObj.amount;
                }else if(aObj.code === 'usdc' && aObj.subType === 'coldstorage'){
                    currentValue = sunvestyGetDisplayValueFromInternal(aObj.value,up.displayCurrency,up.baseCurrency,prices); 
                    iterFunds +=currentValue;
                }
                const portfObj = getPortfolioById(aObj.portfolioId);
                if(portfObj != null && portfObj.type ==='bank'){
                    chartData[0].y += currentValue;
                }
                else if(portfObj != null && portfObj.type ==='crypto'){
                    chartData[1].y += currentValue;
                }
                else if(portfObj != null && portfObj.type ==='stock'){
                    chartData[2].y += currentValue;
                }
            });
        }
        
        setLiquidFunds(iterFunds);
        setLiquidPieData(chartData);
        
    }, [assets]);

    if(portfolios != null){
        portfolios.forEach(pfObj => {
            totalInvested +=pfObj.invested;
            totalValue +=pfObj.value;
            totalEarning += pfObj.taxableValue;
            if(pfObj.type==='bank'){
                investedPieData[0].y+=pfObj.invested;
                valuePieData[0].y+=pfObj.value;
            }
            else if(pfObj.type==='crypto'){
                investedPieData[1].y+=pfObj.invested;
                valuePieData[1].y+=pfObj.value;
            }
            else if(pfObj.type==='stock'){
                investedPieData[2].y+=pfObj.invested;
                valuePieData[2].y+=pfObj.value;
            }
            else if(pfObj.type==='realestate'){
                investedPieData[3].y+=pfObj.invested;
                valuePieData[3].y+=pfObj.value;
            }
            else if(pfObj.type==='other'){
                investedPieData[4].y+=pfObj.invested;
                valuePieData[4].y+=pfObj.value;
            }
        });
        
    }
    else{
        totalInvested=0.0;
        totalValue=0.0;
        totalEarning=0.0;
        perdiodEarning=0.0;
    }
    if(earnings != null){
        var earningPortfolio=null;
        earnings.forEach(eObj => {
            perdiodEarning += eObj.taxableValue;
            earningPortfolio = getPortfolioById(eObj.portfolioId);
            
            var displayValue = eObj.taxableValue;
            if(up != null && prices != null){
                displayValue = sunvestyGetDisplayValueFromInternal(eObj.taxableValue,up.displayCurrency,up.baseCurrency,prices);
            }
            if(earningPortfolio==null){
                periodEarningPieData[4].y+=displayValue;
            }
            else if(earningPortfolio.type==='bank'){
                periodEarningPieData[0].y+=displayValue;
            }
            else if(earningPortfolio.type==='crypto'){
                periodEarningPieData[1].y+=displayValue;
            }
            else if(earningPortfolio.type==='stock'){
                periodEarningPieData[2].y+=displayValue;
            }
            else if(earningPortfolio.type==='realestate'){
                periodEarningPieData[3].y+=displayValue;
            }
            else if(earningPortfolio.type==='other'){
                periodEarningPieData[4].y+=displayValue;
            }
        });

    }
    var downloadButton = <div></div>
    if(up != null){
        downloadButton = <span className='button' onClick={downloadFile}>Download file</span>;
    }
    //var loadingData=(<div></div>);
    
    return (
        <div className='main beta' >
            <div className="display-selector-container">
                <div className="left">
                    <SelectDisplayCurrency/>
                    <SelectDisplayPeriod/>
                    {downloadButton}
                    
                </div>

            </div>
            
            <div className='content'>
                <div className='top-chart-container'>
                    <div className='main-chart thin'>
                        <BarChart/>
                    </div>
                    <div className='main-chart thin'>
                        <Chart/>
                    </div>
                    <div className='clear'></div>
                </div>
                <div className="card-layout">
                    <Card title={earningPeriodTitle} value={perdiodEarning} chartData={periodEarningPieData} loading={props.loading}/>
                    
                    <Card title="Value" value={totalValue} chartData={valuePieData} loading={props.loading}/>
                    <Card title="Liquid" value={liquidFunds} chartData={liquidPieData} loading={props.loading}/>
                    <Card title="Initital Investment" value={totalInvested} chartData={investedPieData} loading={props.loading}/>
                </div>
                <PortfolioCardGrid portfolios={portfolios} clickAction={portoflioClick}/>
                
                </div>
            
            
        </div>
    )

    }

export default Dashboard;