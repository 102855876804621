import React,{useEffect} from 'react';
import { useState } from 'react';
import { useSelector,useDispatch } from 'react-redux'
import failureImage from './images/failure.svg'; 
import successImage from './images/success.svg'; 
import { login } from '../features/login/loginSlice'

 

/*
class LoginForm extends React.Component{

    
    constructor(props) {
        super(props);
        this.state={username:'',password:''};
        this.state.loginStatus=0;
        this.submitLogin = this.submitLogin.bind(this);
        this.cancelLogin = this.cancelLogin.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        //this.loginUrl = 'http://localhost:8080/login';
        this.loginUrl = global.config.baseUrl + '/login';
    }
    componentDidMount() {
      console.log('Menu mounted');
    }
    componentWillUnmount() {
       
    }
    submitLogin(){
        console.log('Submit login called with username: ' + this.state.username);
        //sunvestyLogin(this.state.username,this.state.password);
        //this.props.onLogin('jangab@gmail.com');
        fetch(this.loginUrl, {
        method: 'POST',
        body: JSON.stringify({
           username: this.state.username,
           pwd: this.state.password
        }),
        headers: {
           'Content-type': 'application/json; charset=UTF-8',
        },
        })
        .then((response) => {
            if(response.status == 200){
                return response.json()
            }
            else{
                console.log('Code: ' + response.status)
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            
        })
        .then((data) => {
          //setPosts((posts) => [data, ...posts]);
           //setTitle('');
           //setBody('');
            this.setState({loginStatus:200})

            console.log(data);
            sessionStorage.setItem('apikey',data.key);
            sessionStorage.setItem('username',this.state.username);
            setTimeout(this.handleLogin,500);
        })
        .catch((err) => {
           console.log(err.message);
           this.setState({loginStatus:403})
        });
        this.setState({password:''})
    }
    handleLogin(){
        let username = this.state.username;
        
        this.props.onLogin(username);
    }
    cancelLogin(){
        console.log('Cancel login');
        this.props.onCancel();
    }
    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
      }
      
    sunvestyLogin(username, password) {
        
    }
    render() {
        var statusSection=<div></div>
        if(this.state.loginStatus==403){
            statusSection=(
                <div className="form-status">
                    <div>
                        <img src={failureImage}></img>
                    </div>
                    <div>Incorrect credentials</div>
                </div>
                
                );
        }
        else if(this.state.loginStatus==200){
            statusSection=(
                <div className="form-status">
                    <div>
                        <img src={successImage}></img>
                    </div>
                </div>
                
                );
        }
        return (
            <div className="modal-dialog login">
                <div className="dialog-header">Login<span className="close" onClick={this.cancelLogin}></span></div>
                <div className="content">
                    {statusSection}
                    <form className="normal-form">
                        <div className="input-row">
                            <label>Username</label>
                            <input type="text" name="username" value={this.state.username} onChange={this.handleChange}></input>
                        </div>
                        <div className="input-row">
                            <label>Password</label>
                            <input type="password" name="password" value={this.state.password} onChange={this.handleChange}></input>
                        </div>
                        <div>
                            <button className="button" type="button" onClick={this.submitLogin}>Log in</button>
                        </div>

                    </form>
                </div>

                
            </div>

            
            
            
            )
    }

}*/
function LoginForm(){
    const dispatch = useDispatch();
    
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginState, setLoginState] = useState();
    const [addRequestStatus, setAddRequestStatus] = useState('idle')
    const loginStatus = useSelector(state => state.login.status);
    const error = useSelector(state => state.login.error)
    const handleSubmit= async(e) => {
        e.preventDefault();
        if(loginStatus == 'idle' || loginStatus == 'failed'){
            console.log('Clicked login: username: ' + username);
            try {
                setAddRequestStatus('pending')
                await dispatch(login({ username, password})).unwrap()
                //setUsername('')
                setPassword('')
                setLoginState('ok');
            } catch (err) {
                if(error=='403'){
                    setLoginState('invalid');
                }
                //console.error('Failed to login: ', err)
            } finally {
                setAddRequestStatus('idle')
            }
        }
        
        // ???
    }
    
    /*const handleChange = (e) =>{
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setState({
            [name]: value
        });
    }*/
    
    var statusSection = <div/>
    var loginForm= (<form className="normal-form">
        <div className="input-row">
            <label>Username</label>
            <input type="text" name="username" value={username} onChange={e => setUsername(e.target.value)}></input>
        </div>
        <div className="input-row">
            <label>Password</label>
            <input type="password" name="password" value={password} onChange={e => setPassword(e.target.value)}></input>
        </div>
        <div>
            <button className="button" type="button" onClick={handleSubmit}>Log in</button>
        </div>

    </form>);
    if(error==='403'){
        statusSection = (
            <div className="form-status">
                <div>
                    <img src={failureImage}></img>
                </div>
                <div>Incorrect credentials</div>
            </div>
            
            );
    }
    else if(loginStatus=='loading'){
        statusSection = (
            <div className="form-status">
                <div>
                   Logging in
                </div>
            </div>
            
            );
        loginForm = (
            <div/>
        );
    }
    else if(loginStatus=='succeeded'){
        statusSection = (
            <div className="form-status">
                <div>
                    <img src={successImage}></img>
                </div>
            </div>
            
            );
    }
    
    

    return (
        <div className="modal-dialog login">
                <div className="dialog-header">Login</div>
                <div className="content">
                    {statusSection}
                    {loginForm}
                </div>

                
            </div>
    )
}
export default LoginForm;