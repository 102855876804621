
import React,{useState,useEffect} from 'react';
import SelectDisplayCurrency from '../module/ui/SelectDisplayCurrency';
import SelectAsset from '../module/ui/SelectAsset';
import SelectPortfolioAsset from '../module/ui/SelectPortfolioAsset';
import SelectBlockchain from '../module/ui/SelectBlockchain';
import { useSelector,useDispatch } from 'react-redux'
import PortfolioAssetCardGrid from '../module/PortfolioAssetCardGrid';
import SetPortfolioValueForm from '../module/SetPortfolioValueForm';
import {sunvestyRenderFromInternal,sunvestyRenderDouble} from "../util/SunvestyRender";
import CreatePortfolioAssetForm from '../module/CreatePortfolioAssetForm ';
import PortfolioAssetActionForm from '../module/PortfolioAssetActionForm';
import Card from '../module/Card';
import Table from '../module/ui/Table';
import EarningBarChart from '../module/EarningBarChart';
import ValueAmountChart from '../module/ui/ValueAmountChart';
import {setShowActionFormAsset,setShowCreatePortfolioAssetForm} from "../features/context/contextSlice";
import {setSetValueFormPortfolioAsset} from  "../features/context/contextSlice";

function PortfolioAsset(props) {
    const dispatch = useDispatch();
    const portfolios = useSelector(state => state.data.portfolios);
    const assets = useSelector(state => state.data.assets);
    const ctx = useSelector(state => state.ctx);
    const up = useSelector(state => state.data.userprofile);
    const prices = useSelector(state => state.data.prices);
    const period = useSelector(state => state.transactions.period);
    const [selectedAsset, setSelectedAsset] = useState(ctx.objectId);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalValue, setTotalValue] = useState(0);
    const [totalInvested, setTotalInvested] = useState(0);
    const [totalEarned, setTotalEarned] = useState(0);
    const [aprInPeriod, setAprInPeriod] = useState(0);
    const transactions = useSelector(state => state.transactions.transactions);
    const [tableData, setTableData] = useState([]);
    const [filteredEarnTrx,setFilteredEarnTrx]=useState([]);
    
    var setValueForm = <div></div>;
    var displayModule=<div>Assets</div>;
    var actionForm=<div></div>;
    const getAsset= (id)=>{
        var returnObj = null;
        assets.forEach(aObj => {
                
            if( aObj.id === id){
                returnObj=aObj;
                return returnObj;
            }
        });
        return returnObj;
    }

    if(ctx.showActionFormPortfolioAsset != null){
        actionForm = <PortfolioAssetActionForm/>
    }
    
    var createPortfolioAssetForm = <div></div>;
    var selectedPortfolio=null;
    if(ctx.showCreateFormPortfolioAsset != null){
        portfolios.forEach(pf => {
            if(pf.id===ctx.showCreateFormPortfolioAsset.portfolioId){
                selectedPortfolio=pf;
            }
        });
        createPortfolioAssetForm = <CreatePortfolioAssetForm portfolio={selectedPortfolio} pAsset={ctx.showCreateFormPortfolioAsset}/>
    }
    else if(ctx.showSetValueFormPortfolioAsset != null){
        portfolios.forEach(pf => {
            if(pf.id===ctx.showSetValueFormPortfolioAsset.portfolioId){
                selectedPortfolio=pf;
            }
        });
        setValueForm = <SetPortfolioValueForm asset={ctx.showSetValueFormPortfolioAsset} portfolio={selectedPortfolio}/>;
    }
    
    const includeTransactionInFilter = (pAObj) =>{
        
        let pAssetFilter = (props.portfolioAsset != null && pAObj.id === props.portfolioAsset.id) 
        return pAssetFilter;
    }
    const actionClicked = () =>{
        dispatch(setShowActionFormAsset(props.portfolioAsset));
    }
    const editClicked = () =>{
        dispatch(setShowCreatePortfolioAssetForm(props.portfolioAsset));
    }
    const setValueClicked = () =>{
        dispatch(setSetValueFormPortfolioAsset(props.portfolioAsset));
    }


    useEffect(()=>{
        let filteredArray=[];
        let filteredEarnArray=[];
        let newPieData=[];
        let iterAmount=0;
        let iterValue=0;
        let iterInvested=0;
        let trxAsset;
        let filteredtableData = [];
        let earningInFilter=0;
        if(props.portfolioAsset != null){
            iterAmount += props.portfolioAsset.amount;
            iterValue += props.portfolioAsset.value;
            iterInvested += props.portfolioAsset.invested;
            if(transactions != null){
                transactions.forEach(tObj => {
                    trxAsset = getAsset(tObj.assetId);
                    if(includeTransactionInFilter(trxAsset)){
                        filteredtableData.push(JSON.parse(JSON.stringify(tObj)));
                        if(tObj.type==='earning'){
                            filteredEarnArray.push(JSON.parse(JSON.stringify(tObj)));
                            earningInFilter+=tObj.value;
                        }
                    }
                    /*if((selectedAsset !== null && trxAsset !==null) &&
                        ((selectedPortfolioAsset === null && selectedAsset === trxAsset.code)
                        ||   (selectedPortfolioAsset !== null && selectedPortfolioAsset.id === tObj.assetId) 
                    )){
                        filteredtableData.push(JSON.parse(JSON.stringify(tObj)));
                        if(tObj.type==='earning'){
                            filteredEarnArray.push(JSON.parse(JSON.stringify(tObj)));
                        }
                    }*/
                });
            }
            

            if(assets != null){
                assets.forEach(aObj => {
                    
                    if(props.portfolioAsset != null && aObj.code === selectedAsset){
                        filteredArray.push(JSON.parse(JSON.stringify(aObj)));
                        
                        
                        /*
                        if(selectedPortfolioAsset == null && aObj.code === selectedAsset){
                            iterAmount += aObj.amount;
                            iterValue += aObj.value;
                            iterInvested += aObj.invested;
                            newPieData.push({name:aObj.name,y:aObj.value});
                        }
                        else if(selectedPortfolioAsset !== null && aObj.id === selectedPortfolioAsset.id){
                            iterAmount += aObj.amount;
                            iterValue += aObj.value;
                            iterInvested += aObj.invested;
                            newPieData.push({name:aObj.name,y:aObj.value});
                        }*/
                    }
                    
                });
            
            }
        }
        setTotalAmount(iterAmount);
        setTotalValue(iterValue);
        setTotalInvested(iterInvested);
        setTableData(filteredtableData);
        setFilteredEarnTrx(filteredEarnArray);
        setTotalEarned(earningInFilter);
        let aprMultiplier=1;
        if(period ==='1month'){
            aprMultiplier=12.0;
        }
        else if(period ==='3months'){
            aprMultiplier=4.0;
        }
        else if(period ==='6months'){
            aprMultiplier=2.0;
        }
        setAprInPeriod(earningInFilter/iterValue*aprMultiplier*100.0);
       
    }, [assets, selectedAsset, props.portfolioAsset,transactions])
    if(ctx != null && ctx.objectId != null && up != null && totalValue != null){
        
        let tabContent = (
            <Table tableData={tableData}/>
        )
        
        displayModule = (
        <div>
            
             <div className='obj-header'>
                    
                    <div className='label'>Asset</div>
                    <div className='value'>
                        {sunvestyRenderDouble(totalAmount)} <span className='explainer'>{selectedAsset.toUpperCase()}</span>&nbsp;≈&nbsp;
                        <span>{sunvestyRenderFromInternal(totalValue,up.displayCurrency,up.baseCurrency,prices)}</span> <span className='explainer'>{up.displayCurrency.toUpperCase()}</span>
                        <div className='separator'></div>
                        <div className='label'>Profit</div>
                        <div className='value'>
                            {sunvestyRenderFromInternal(totalValue-totalInvested,up.displayCurrency,up.baseCurrency,prices)} <span className='explainer'>{up.displayCurrency}</span>
                    
                        </div>
                    </div>
            </div>
            <div className='card-layout'>
                <div className='card'>
                    <div className='content'>
                        <div>
                            <div className='card-label'>APR ({period})</div>
                            <div className='total'>{sunvestyRenderDouble(aprInPeriod)} <span className='explainer'>%</span></div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <span className='button' onClick={() => actionClicked()}>Actions</span>
                <span className='button' onClick={() => editClicked()}>Edit</span>
                <span className='button' onClick={() => setValueClicked()}>Set Value</span>
            </div>
            <div className='top-chart-container'>
                    <div className='main-chart thin'>
                        <EarningBarChart transactions={filteredEarnTrx}/>
                    </div>
                    <div className='main-chart thin'>
                        <ValueAmountChart asset={selectedAsset} portfolioAsset={props.portfolioAsset}></ValueAmountChart>
                    </div>
                    <div className='clear'></div>

            </div>
            
                
                <div>
                {tabContent}
                    
                </div>
            
        </div>
        )
        
    }
    return (
        
        <div>
            {actionForm}
            {createPortfolioAssetForm}
            {setValueForm}
            
            
            {displayModule}
           
        </div>
    ) 
    
    
}

export default PortfolioAsset;