import React,{useEffect} from 'react';
import { useState } from 'react';
import { useSelector,useDispatch } from 'react-redux'
import {setShowCreatePortfolioAssetForm} from "../features/context/contextSlice";
import { executeAssetActionCall,clear } from '../features/api/sunvestyApiSlice'
import { dataUpdatePortfolioAssset } from '../features/data/dataSlice'
import config from '../app/config';

function CreatePortfolioAssetForm(props){
    //setShowCreatePortfolioForm
    const dispatch = useDispatch();
    
    const apiStatus = useSelector(state => state.api.status);
    const apiRespObj = useSelector(state => state.api.respObj);
    const [assetName, setAssetName] = useState(props.pAsset != null?props.pAsset.name:'');
    const [assetCode, setAssetCode] = useState(props.pAsset != null?props.pAsset.code:'');
    const [assetTokenAmount, setAssetTokenAmount] = useState(props.pAsset != null?props.pAsset.amount:'');
    const [assetInvested, setAssetInvested] = useState(props.pAsset != null?props.pAsset.invested:'');
    const [assetValue, setAssetValue] = useState(props.pAsset != null?props.pAsset.value:'');
    const [assetBlockchain, setAssetBlockchain] = useState(props.pAsset != null?props.pAsset.blockchain:'');
    const [assetPublicKey, setAssetPublicKey] = useState(props.pAsset != null?props.pAsset.accountId:'');
    const [assetSubType, setAssetSubType] = useState(props.pAsset != null?props.pAsset.subType:'');
    const [assetNote, setAssetNote] = useState(props.pAsset != null?props.pAsset.note:'');
    const cryptoTokens = config.supportedTokens;//['usdc','btc','eth','sol','atom','cro','kuji','osmo','evmos','matic','avax','eurx','nft'];
    const fiatTokens = ['usd','sek','eur','fonder'];
    const blockchains = config.supportedBlockchains;
    const cryptoSubTypes = ['coldstorage','staking','exchange','defi_lp','defi_lending'];
    const floatLeft = {
        float: 'left',
        width: '45%',
      };
    
    var assetMainType='other'; 
    
    const handleSelectAssetCode = (event) => {
        setAssetCode(event.target.value);
    }
    const handleSelectAssetBlockchain = (event) => {
        setAssetBlockchain(event.target.value);
    }
    const handleSelectAssetSubType = (event) => {
        setAssetSubType(event.target.value);
    }
    
    var tokenInput = <div></div>
    var cryptoInput=<div></div>
    if(props.portfolio.type==='crypto'){
        assetMainType='crypto';
        const cryptoItems = cryptoTokens.map((aObj) =>
            <option value={aObj} key={aObj}>{aObj}</option>
        );
        const blockchainItems = blockchains.map((aObj) =>
            <option value={aObj} key={aObj}>{aObj}</option>
        );
        const cryptoSubTypeItems = cryptoSubTypes.map((aObj) =>
            <option value={aObj} key={aObj}>{aObj}</option>
        );
        tokenInput = (
            <div className='input-row' style={floatLeft}>
                <label>Token</label>
                <select value={assetCode} onChange={e => handleSelectAssetCode(e)}>
                {cryptoItems}

                </select>
                
            </div>
            
        )
        cryptoInput = (
            <div>
                <div className='input-row' >
                    <label>AssetType</label>
                    <select value={assetSubType} onChange={e => handleSelectAssetSubType(e)}>
                        {cryptoSubTypeItems}

                    </select>
                </div>
                <div className='input-row' >
                    <label>Blockchain</label>
                    <select value={assetBlockchain} onChange={e => handleSelectAssetBlockchain(e)}>
                        <option value='none' key='none'>None</option>
                        {blockchainItems}

                    </select>
                </div>
                <div className='input-row'>
                    <label>Public Key</label>
                    <input type="text" value={assetPublicKey} onChange={e => setAssetPublicKey(e.target.value)}></input>
                </div>
            </div>

        )
    }
    else if(props.portfolio.type==='stock'){
        assetMainType='stock';
        tokenInput = (
            <div className='input-row' style={floatLeft}>
                <label>Stock Ticker</label>
                <input type="text" value={assetCode} onChange={e => setAssetCode(e.target.value)}></input>
                
            </div>
        )
    }
    else if(props.portfolio.type==='bank'){
        assetMainType='fiat';
        const fiatItems = fiatTokens.map((aObj) =>
            <option value={aObj} key={aObj}>{aObj}</option>
        );
        tokenInput = (
            <div className='input-row' style={floatLeft}>
                <label>Token</label>
                <select value={assetCode} onChange={e => handleSelectAssetCode(e)}>
                {fiatItems}

                </select>
                
            </div>
            
        )
    }
    else{
        assetMainType='other';
        tokenInput = (
            <div className='input-row' style={floatLeft}>
                <label>Token</label>
                <input type="text" value={assetCode} onChange={e => setAssetCode(e.target.value)}></input>;
                
            </div>
        )
    }
    const hideForm=() => {
        dispatch(setShowCreatePortfolioAssetForm(null));
        dispatch(clear());
    }
    
    var savePortfolioButton = <div></div>
    
    const handleSavePortfolio = () => {
        var formOk = true;
        var investedAmount = 0;
        var tokenAmount = 0;
        var valueAmount = 0;
        if(assetName.trim().length ===0){
            formOk=false;
        }
        if(isNaN(assetTokenAmount)){
            
            formOk=false;
        }
        else if((typeof assetTokenAmount === 'string' || assetTokenAmount instanceof String) && assetTokenAmount.trim().length ===0){
            //console.log('Empty String');
            tokenAmount=0;
        }
        else{
            tokenAmount=assetTokenAmount;
        }
        if(isNaN(assetInvested)){
            
            formOk=false;
        }
        else if((typeof assetInvested === 'string' || assetInvested instanceof String) && assetInvested.trim().length ===0){
            //console.log('Empty String');
            investedAmount=0;
        }
        else{
            investedAmount=assetInvested;
        }
        if(isNaN(assetValue)){
            formOk=false;
        }
        else if((typeof assetValue === 'string' || assetValue instanceof String) && assetValue.trim().length ===0){
            //console.log('Empty String');
            valueAmount=0;
        }
        else{
            valueAmount=assetValue;
        }
        
        if(formOk){
            var assetId = 0;
            if(props.pAsset != null){
                assetId=props.pAsset.id;
            }
            console.log('Save portfolio Asset: ' + assetName + ' tokenAmount: ' +tokenAmount+ ' code: ' +assetCode + ' invested: ' +investedAmount + ' value: ' +valueAmount);
            var taxableValue=0;
            if(props.pAsset != null){
                taxableValue = props.pAsset.taxableValue;
            }
            const objToSend={
                command:'save_portfolio_asset',
                id:assetId,
                portfolioId:props.portfolio.id,
                name:assetName,
                amount:tokenAmount,
                code:assetCode,
                type:assetMainType,
                subType:assetSubType,
                blockchain:assetBlockchain,
                accountId:assetPublicKey,
                invested:investedAmount,
                value:valueAmount,
                taxableValue:taxableValue,
                note:assetNote,

            }
            dispatch(executeAssetActionCall(objToSend)).unwrap();
        }
        else{
            console.log('Form not ok');
        }
        
    }
    //Conditional
    if(apiStatus == 'idle' || apiStatus == 'failed'){
        savePortfolioButton = <div className='center'><button className="button" type="button" onClick={handleSavePortfolio}>Save</button></div>
    }
    else if(apiStatus == 'succeeded'){
        dispatch(dataUpdatePortfolioAssset(apiRespObj))
        hideForm();
    }
    

    

    return (
        <div className="modal-dialog">
        <div className="dialog-header">PortfolioAsset Form - {props.portfolio.name}<span className='close' onClick={() => {hideForm()}}></span></div>
        <div className="content">
            
            <div>
                <form className='normal-form'>
                    <div className='input-row'>
                        <label>Name</label>
                        <input type="text" value={assetName} onChange={e => setAssetName(e.target.value)}></input>
                    </div>
                    <div className='input-row' style={floatLeft}>
                        <label>Token amount</label>
                        <input type="text" value={assetTokenAmount} onChange={e => setAssetTokenAmount(e.target.value)}></input>
                    </div>
                    {tokenInput}
                    
                    <div className='clear'></div>
                    {cryptoInput}
                    <div className='input-row' style={floatLeft}>
                        <label>Invested</label>
                        <input type="text" value={assetInvested} onChange={e => setAssetInvested(e.target.value)}></input>
                    </div>
                    <div className='input-row' style={floatLeft}>
                        <label>Value</label>
                        <input type="text" value={assetValue} onChange={e => setAssetValue(e.target.value)}></input>
                    </div>
                    <div className='clear'></div>
                    
                    <div className='input-row'>
                        <label>note</label>
                        <textarea value={assetNote} onChange={e => setAssetNote(e.target.value) } rows={3}/>
                    </div>
                    {savePortfolioButton}

                </form>
                
            </div>
            
            
            
        </div>

        
    </div>
    )
}

export default CreatePortfolioAssetForm;