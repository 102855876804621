import React,{useState} from 'react';
import { useSelector,useDispatch } from 'react-redux'
import {setDisplayCurrency} from '../../features/data/dataSlice'

function SelectDisplayCurrency(props) {
    const dispatch = useDispatch();
    const up = useSelector(state => state.data.userprofile);
    var displayCurrency='usd';
    const [isOpen, setOpen] = useState(false);
    
    /*
    const toggleOpen = () => {
        console.log('Toggle open: ' + isOpen);
        setOpen(!isOpen);
        
    }*/

    const handleDisplayCurrency = (newDisplay) => {
        if(up != null){
            dispatch(setDisplayCurrency(newDisplay));
        }
        //console.log('Clicked: ' + newDisplay);
    }
    if(up != null){
        displayCurrency=up.displayCurrency;
    }

    if(up != null){
        return (
            <span className={`select-one ${isOpen?'open':''}`} onClick={() => setOpen(!isOpen)}>
                <span className="selected">{displayCurrency}</span>
                    <div className="select-items">
                        <div className="item" onClick={() => handleDisplayCurrency('usd')}>USD</div>
                        <div className="item" onClick={() => handleDisplayCurrency('btc')}>BTC</div>
                        <div className="item" onClick={() => handleDisplayCurrency('sek')}>SEK</div>
                        <div className="item" onClick={() => handleDisplayCurrency('eur')}>EUR</div>
                    </div>
            </span>
        )
    }
    else{
        return(<div></div>)
    }
    
}
export default SelectDisplayCurrency;