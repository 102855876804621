import React from 'react';
import { useState } from 'react';
import { useSelector,useDispatch } from 'react-redux'

import {setShowActionFormAsset} from "../features/context/contextSlice";
import { clear } from '../features/api/sunvestyApiSlice'
import { dataDeposit,dataWithdraw } from '../features/data/dataSlice'
import { transactionAdd } from '../features/transactionHistory/transactionHistorySlice';
import DepositTransactionForm from './ui/DepositTransactionForm';
import WithdrawalTransactionForm from './ui/WithdrawalTransactionForm';
import SwapTransactionForm from './ui/SwapTransactionForm';
import TransferTransactionForm from './ui/TransferTransactionForm';
import EarningTransactionForm from './ui/EarningTransactionForm';
import { fetchData } from '../features/data/dataSlice'

function PortfolioAssetActionForm(){
    const dispatch = useDispatch();
    const ctx = useSelector(state => state.ctx);

    const [selectedTab, setSelectedTab] = useState(0);
    const apiStatus = useSelector(state => state.api.status);
    
    const apiRespObj = useSelector(state => state.api.respObj);

    const selectTab = (newId) =>{
        setSelectedTab(newId);
    }
    const hideActionForm = () => {
        dispatch(dispatch(setShowActionFormAsset(null)));
        //dispatch(clear());
        //console.log('Should hide form');
    }
    if(apiStatus==='succeeded'){
        //console.log(apiRespObj.id);
        dispatch(dispatch(setShowActionFormAsset(null)));
        dispatch(clear());
        if(selectedTab === 0){
            //Transfer
            dispatch(transactionAdd(apiRespObj[0]));
            dispatch(transactionAdd(apiRespObj[1]));
            dispatch(fetchData());
        }
        else if(selectedTab === 1){
            //Swap
            dispatch(transactionAdd(apiRespObj[0]));
            dispatch(transactionAdd(apiRespObj[1]));
            dispatch(fetchData());
        }
        else if(selectedTab === 2){
            dispatch(dataDeposit({amount:apiRespObj.amount, invested:apiRespObj.invested, assetId:apiRespObj.assetId}));
            dispatch(transactionAdd(apiRespObj));
        }
        else if(selectedTab === 3){
            dispatch(dataWithdraw({amount:apiRespObj.amount, assetId:apiRespObj.assetId}));
            dispatch(transactionAdd(apiRespObj));
        }
        else if(selectedTab === 4){
            //Earning
            /*if(apiRespObj.token === ctx.showActionFormPortfolioAsset.code){
                dispatch(dataDeposit({amount:apiRespObj.amount, invested:apiRespObj.invested, assetId:apiRespObj.assetId}));
            }*/
            dispatch(transactionAdd(apiRespObj[0]));
            dispatch(transactionAdd(apiRespObj[1]));
            dispatch(fetchData());
            
        }
        

    }
    
    
    
    
    
    var displayForm = <div></div>
    if(ctx.showActionFormPortfolioAsset != null){
        if (selectedTab === 0){
            displayForm = <TransferTransactionForm/>;
        }
        else if(selectedTab === 1){
            displayForm=<SwapTransactionForm/>;
        }
        else if(selectedTab === 2){
            displayForm=<DepositTransactionForm/>;
        }
        else if(selectedTab===3){
            displayForm=<WithdrawalTransactionForm/>;
        }
        else if(selectedTab===4){
            displayForm=<EarningTransactionForm/>;
        }
    }
   
    return (
        <div className="modal-dialog">
            <div className="dialog-header">Portfolio Action Form {ctx.showActionFormPortfolioAsset != null?ctx.showActionFormPortfolioAsset.name:''} ({ctx.showActionFormPortfolioAsset != null?ctx.showActionFormPortfolioAsset.id:''}) <span className='close' onClick={() => {hideActionForm()}}></span></div>
            <div className="content">
                <div className='tabs'>
                    <div className={`tab ${selectedTab===0 ? "selected" : ""}`} onClick={()=>selectTab(0)}>Transfer</div>
                    <div className={`tab ${selectedTab===1 ? "selected" : ""}`} onClick={()=>selectTab(1)}>Swap</div>
                    <div className={`tab ${selectedTab===2 ? "selected" : ""}`} onClick={()=>selectTab(2)}>Deposit</div>
                    <div className={`tab ${selectedTab===3 ? "selected" : ""}`}onClick={()=>selectTab(3)}>Withdraw</div>
                    <div className={`tab ${selectedTab===4 ? "selected" : ""}`}onClick={()=>selectTab(4)}>Earning</div>
                </div>
                <div className='tab-content'>
                    
                    {displayForm}
                </div>
                
                
                
            </div>

            
        </div>
    )
}

export default PortfolioAssetActionForm;