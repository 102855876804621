import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
    transsactions:null ,
    earnings:null ,
    period:'6months',
    status: 'idle',
    error: null
  };

  export const fetchTransactionData = createAsyncThunk('history/fetchtransactions', async (arg,{ getState }) => {
    //console.log('Period: ' + this.state.period);
    const state = getState();
    console.log('Period: ' + state.transactions.period);
    const mainFetchUrl = global.config.baseUrl+'/portfolio-transactions?fetch-period='+state.transactions.period;//https://api.sunvesty.com/v1/account-portfolios';
    const apiKey = sessionStorage.getItem('apikey');

    
    console.log('fetch transactions url: ' + mainFetchUrl);
    const response = await fetch(mainFetchUrl, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' +apiKey
        },
        });
        console.log('fetch prices finished: ' + response.status);  
        
        return response.json();

    });
    export const transactionSlice = createSlice({
        name: 'transactions',
        initialState,
        reducers: {
            clearTransactionData: (state) => {
                // Redux Toolkit allows us to write "mutating" logic in reducers. It
                // doesn't actually mutate the state because it uses the Immer library,
                // which detects changes to a "draft state" and produces a brand new
                // immutable state based off those changes
                state.transsactions = null;
                state.error=null;
                state.status='idle';
              }, 
              transactionAdd:(state,trxObj) =>{
                state.transactions.push(trxObj.payload);
                if(trxObj.payload.type==='earning'){
                  state.earnings.push(trxObj.payload);
                }
                //console.log('dataSlice:Deposit : ' + trxObj.payload.amount);
              },
              setTrxPeriod: (state,newPeriod) => {
                state.period = newPeriod.payload;
                state.transactions=null;
                //console.log('state:' + JSON.stringify(state));
              }

        },
        extraReducers(builder) {
          builder
            .addCase(fetchTransactionData.pending, (state, action) => {
              state.status = 'loading'
              //console.log('-------------------loading')
            })
            .addCase(fetchTransactionData.fulfilled, (state, action) => {
              state.status = 'succeeded'
              //console.log('--------------------succeeded')
              //console.log('action.payload: ' + JSON.stringify(action.payload));
              // Add any fetched posts to the array
              state.transactions = action.payload;
              var earnings = [];
              action.payload.forEach(trxObj => {
                  if(trxObj.type==='earning'){
                    earnings.push(trxObj);
                  }
              });
              state.earnings=earnings;
              //state.portfolios = action.payload.portfolios;
              
            })
            .addCase(fetchTransactionData.rejected, (state, action) => {
              state.status = 'failed'
              //console.log('--------------------failed');
              state.error = action.error.message
            })
        }
      })
    
      export default transactionSlice.reducer
      export const transactions = state => state.data.transactions
      export const { clearTransactionData,transactionAdd,setTrxPeriod } = transactionSlice.actions