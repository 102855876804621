import React,{useEffect} from 'react';
import { useState } from 'react';
import { useSelector,useDispatch } from 'react-redux'
import {setShowCreatePortfolioForm} from "../features/context/contextSlice";
import { executeAssetActionCall,clear } from '../features/api/sunvestyApiSlice'
import { dataUpdatePortfolio } from '../features/data/dataSlice'

function CreatePortfolioForm(props){
    //setShowCreatePortfolioForm
    const dispatch = useDispatch();
    const portfolioTypes = ['bank','crypto', 'stock','realestate','other'];
    const apiStatus = useSelector(state => state.api.status);
    const apiRespObj = useSelector(state => state.api.respObj);
    const [portfolioName, setPortfolioName] = useState(props.portfolio != null?props.portfolio.name:'');
    const [portfolioType, setPortfolioType] = useState(props.portfolio != null?props.portfolio.type:portfolioTypes[0]);
    const [portfolioInvested, setPortfolioInvested] = useState(props.portfolio != null?props.portfolio.invested:'');
    const [portfolioValue, setPortfolioValue] = useState(props.portfolio != null?props.portfolio.value:'');
    
    const hideForm=() => {
        dispatch(setShowCreatePortfolioForm(null));
        dispatch(clear());
    }
    
    var savePortfolioButton = <div></div>
    
    
    const portfolioItems = portfolioTypes.map((aObj) =>
        <option value={aObj} key={aObj}>{aObj}</option>
    );
    const handleSelectPortfolioType = (event) => {
        setPortfolioType(event.target.value);
        //console.log('Selectd token: '+event.target.value);
    }
    const handleSavePortfolio = () => {
        var formOk = true;
        var investedAmount = 0;
        var valueAmount = 0;
        if(portfolioName.trim().length ===0){
            formOk=false;
        }
        if(isNaN(portfolioInvested)){
            
            formOk=false;
        }
        else if((typeof portfolioInvested === 'string' || portfolioInvested instanceof String) && portfolioInvested.trim().length ===0){
            //console.log('Empty String');
            investedAmount=0;
        }
        else{
            investedAmount=portfolioInvested;
        }
        if(isNaN(portfolioValue)){
            formOk=false;
        }
        else if((typeof portfolioValue === 'string' || portfolioValue instanceof String) && portfolioValue.trim().length ===0){
            //console.log('Empty String');
            valueAmount=0;
        }
        else{
            valueAmount=portfolioValue;
        }
        
        if(formOk){
            var portfolioId = 0;
            if(props.portfolio != null){
                portfolioId=props.portfolio.id;
            }
            console.log('Save portfolio: ' + portfolioName + ' type: ' +portfolioType + ' invested: ' +investedAmount + ' value: ' +valueAmount);
            const objToSend={
                command:'save_portfolio',
                id:portfolioId,
                name:portfolioName,
                type:portfolioType,
                invested:investedAmount,
                value:valueAmount

            }
            dispatch(executeAssetActionCall(objToSend)).unwrap();
        }
        else{
            console.log('Form not ok');
        }
        
    }
    //Conditional
    if(apiStatus == 'idle' || apiStatus == 'failed'){
        savePortfolioButton = <div className='center'><button className="button" type="button" onClick={handleSavePortfolio}>Save</button></div>
    }
    else if(apiStatus == 'succeeded'){
        dispatch(dataUpdatePortfolio(apiRespObj))
        hideForm();
    }
    

    const floatLeft = {
        float: 'left',
        width: '45%',
      };

    return (
        <div className="modal-dialog">
        <div className="dialog-header">Portfolio Form <span className='close' onClick={() => {hideForm()}}></span></div>
        <div className="content">
            
            <div>
                <div>Create Portfolio</div>
                <form className='normal-form'>
                    <div className='input-row'>
                        <label>Name</label>
                        <input type="text" value={portfolioName} onChange={e => setPortfolioName(e.target.value)}></input>
                    </div>
                    <div className='input-row'>
                        <label>Type</label>
                        <select value={portfolioType} onChange={e => handleSelectPortfolioType(e)}>
                            {portfolioItems}
                        </select>
                    </div>
                    <div className='input-row' style={floatLeft}>
                        <label>Invested</label>
                        <input type="text" value={portfolioInvested} onChange={e => setPortfolioInvested(e.target.value)}></input>
                    </div>
                    <div className='input-row' style={floatLeft}>
                        <label>Value</label>
                        <input type="text" value={portfolioValue} onChange={e => setPortfolioValue(e.target.value)}></input>
                    </div>
                    <div className='clear'></div>
                    {savePortfolioButton}

                </form>
                
            </div>
            
            
            
        </div>

        
    </div>
    )
}

export default CreatePortfolioForm;