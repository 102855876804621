import {sunvestyGetDisplayValueFromInternal} from "../util/SunvestyRender";
import {sunvestyRenderFromInternal} from "../util/SunvestyRender";
import { useSelector,useDispatch } from 'react-redux';
import {setSetValueFormPortfolio} from "../features/context/contextSlice";

function PortfolioCard(props) {
    const dispatch = useDispatch();
    const portfolio = props.portfolio;
    const up = useSelector(state => state.data.userprofile);
    const prices = useSelector(state => state.data.prices);
    const ctx = useSelector(state => state.ctx);

    const showActionForm = () => {
      dispatch(setSetValueFormPortfolio(portfolio));
      console.log('Should show form');
    }
    var portfolioValue=portfolio.value;
    if(up != null){
      portfolioValue = sunvestyGetDisplayValueFromInternal(portfolio.value,up.displayCurrency,up.baseCurrency,prices);
    }

    var valueUpdated = (<div className="status">
                            <span className="dot green" onClick={()=>showActionForm()}></span>
                            <div className="content">
                              <div className='label'>Value updated:</div>
                              <div>{portfolio.valueUpdated}</div>
                            </div>
                        </div>
    );
    if(portfolio.valueUpdated == null){
      valueUpdated = (<div className="status">
                        <span className="dot red" onClick={()=>showActionForm()}></span>
                        <div className="content">
                          <div className='label'>Needs update</div>
                          <div>{portfolio.valueUpdated}</div>
                        </div>
      </div>);
    }

    
    return (
      <div className="card clickable" onClick={()=>props.clickFunction(portfolio)}>
        <div className="content">
          <div>name: {portfolio.name}</div>
          <div>value: {sunvestyRenderFromInternal(portfolio.value,up.displayCurrency,up.baseCurrency,prices)}</div>
          {valueUpdated}
        </div>
        

      </div>
    );
  }
  export default PortfolioCard;