import { createSlice,  createAsyncThunk } from '@reduxjs/toolkit'

export const executeAssetActionCall = createAsyncThunk('portfolioAsset/deposit', async (obj) => {
    

    var mainUrl = global.config.baseUrl+'/portfolio/'+obj.portfolio+'/asset/'+obj.asset+'/action/'+obj.command;
    var apiMethod='POST';
    var bodyObj=null;
    
    const apiKey = sessionStorage.getItem('apikey');
    
    
    
    
    console.log('Deposit thunk amount: ' + obj.amount);

    
    if(obj.command==='save_portfolio' ){
      bodyObj={
        name:obj.name,
        type:obj.type,
        invested:obj.invested,
        value:obj.value,
      };
      if(obj.id===0){
        mainUrl = global.config.baseUrl+'/portfolio';
      }
      else if(obj.id>0){
        apiMethod='PUT';
        mainUrl = global.config.baseUrl+'/portfolio/'+obj.id;
      }
      
    }
    if(obj.command==='save_portfolio_asset' ){
      bodyObj={
        name:obj.name,
        amount:obj.amount,
        code:obj.code,
        type:obj.type,
        subType:obj.subType,
        blockchain:obj.blockchain,
        accountId:obj.accountId,
        invested:obj.invested,
        value:obj.value,
        taxableValue:obj.taxableValue,
        note:obj.note,
      };
      if(obj.id===0){
        mainUrl = global.config.baseUrl+'/portfolio/'+obj.portfolioId+'/asset';
      }
      else if(obj.id>0){
        apiMethod='PUT';
        mainUrl = global.config.baseUrl+'/portfolio/'+obj.portfolioId+'/asset/'+obj.id;
      }
      
    }
    else if(obj.command==='deposit'){
      bodyObj={
          amount: obj.amount,
          invested: obj.invested,
          transactionDate:obj.transactionDate,
          note:obj.note
      };
    }
    else if(obj.command==='withdraw'){
      bodyObj={
        amount: obj.amount,
        transactionDate:obj.transactionDate,
        note:obj.note
      };
    
    }
    else if(obj.command==='transfer'){
      bodyObj={
        amount: obj.amount,
        transactionDate: obj.transactionDate,
        toAsset:obj.toId,
        note:obj.note
      };
    
    }
    else if(obj.command==='earning'){
      bodyObj={
        amount: obj.amount,
        token:obj.token,
        type:obj.type,
        taxableValue:obj.taxableValue,
        transactionDate:obj.transactionDate,
        toAsset:obj.toAssetId,
        note:obj.note
      };
    }
    else if(obj.command==='swap'){
      bodyObj={
        swapOutAmount: obj.swapOutAmount,
        code:obj.code,
        swapInAmount: obj.swapInAmount,
        transactionDate:obj.transactionDate,
        toAsset:obj.toId,
        note:obj.note
      };
    }
    else if(obj.command === 'set_value_portfolio'){
      mainUrl = global.config.baseUrl+'/portfolio/'+obj.portfolio+'/action/set-value';
      bodyObj={
        value:obj.value,
      };
    }
    else if(obj.command === 'set_value_portfolio_asset'){
      mainUrl = global.config.baseUrl+'/portfolio/'+obj.portfolio+'/asset/'+obj.asset+'/action/set-value';
      bodyObj={
        value:obj.value,
      };
    }
    
    const response = await fetch(mainUrl, {
        method: apiMethod,
        body: JSON.stringify(bodyObj),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' +apiKey
        }
    });
    console.log('portfolioAsset API finished: ' + response.status);  
    if(response.status === 200){
        return response.json();
    }
    else if(response.status === 403){
        //throw new Error("Unauthorized");
        throw 403;
    }
    
});

export const sunvestyApiSlice = createSlice({
    name: 'api',
    initialState: {
        apiCall:null ,
        apiKey:null,
        status: 'idle',
        error: null,
        respObj:null,
      },
    reducers: {
        clear: (state) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            
            state.apiCall = null;
            state.error=null;
            state.status='idle';
            state.respObj=null;
            //console.log('Logout reducer called');
          }
    },
    extraReducers(builder) {
      builder
        .addCase(executeAssetActionCall.pending, (state, action) => {
          state.status = 'loading'
          console.log('-------------------loading')
        })
        .addCase(executeAssetActionCall.fulfilled, (state, action) => {
          state.status = 'succeeded'
          console.log('--------------------succeeded')
          const data = JSON.stringify(action.payload)
          console.log('action.payload: ' + data);
          state.respObj=action.payload;
          // Add any fetched posts to the array
          //sessionStorage.setItem('apikey',action.payload.key);
          //state.apiKey=action.payload.key;
          //state.userProfile='jangab@gmail.com';
          //
        })
        .addCase(executeAssetActionCall.rejected, (state, action) => {
          state.status = 'failed'
          console.log('--------------------failed');
          state.error = action.error.message
        })
    }
  })

//export const { setDisplayCurrency,setDisplayModule,setObjectId,setShowActionFormAsset} = contextSlice.actions
export default sunvestyApiSlice.reducer
export const { clear } = sunvestyApiSlice.actions