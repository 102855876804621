import React,{useEffect} from 'react';
import { useState } from 'react';
import { useSelector,useDispatch } from 'react-redux'
import {setShowCreatePortfolioForm,setSetValueFormPortfolioAsset} from "../features/context/contextSlice";
import { executeAssetActionCall,clear } from '../features/api/sunvestyApiSlice'
import { dataUpdatePortfolio, dataUpdatePortfolioAssset } from '../features/data/dataSlice'
import {setSetValueFormPortfolio} from "../features/context/contextSlice";
import PortfolioAssetCardGrid from './PortfolioAssetCardGrid';

function SetPortfolioValueForm(props){
    //setShowCreatePortfolioForm
    const dispatch = useDispatch();
    const up = useSelector(state => state.data.userprofile);
    const prices = useSelector(state => state.data.prices);
    const apiStatus = useSelector(state => state.api.status);
    const apiRespObj = useSelector(state => state.api.respObj);

    let defaultValue = 0;
    let displayName='';
    let calculate
    if(props.asset != null){
        defaultValue = props.asset.value;
        displayName = props.portfolio.name + ' - ' +props.asset.name;
    }
    else if(props.portfolio != null){
        defaultValue = props.portfolio.value;
        displayName = props.portfolio.name;
    }
    const [newValue, setNewValue] = useState(defaultValue);
    const [newCalcAmountValue, setNewCalcAmount] = useState(0);
    const earningTokens = global.config.supportedTokens;
    
    const earningTokenItems = earningTokens.map((aObj) =>
        <option value={aObj} key={aObj}>{aObj}</option>
    );
    let defaultToken = 'usd';
    const getDefaultCalcToken = () =>{
        if(props.asset.blockchain != null && props.asset.blockchain != '' && props.asset.blockchain != 'none'){
            if(props.asset.blockchain === 'cronos'){
                return 'cro';
            }
            else if(props.asset.blockchain === 'solana'){
                return 'sol';
            }
        }
        else{
            return 'usdc';
        }
    }
    const [newCalcToken, setNewCalcToken] = useState(getDefaultCalcToken());
    
    const hideForm=() => {
        dispatch(setSetValueFormPortfolio(null));
        dispatch(setSetValueFormPortfolioAsset(null));
        dispatch(clear());
    }
    
    var savePortfolioButton = <div></div>
        
    const handleSelectCalcToken = (e) =>{
        setNewCalcToken(e.target.value)
    }
    const calculateValueClicked = (multipler) =>{
        console.log('Should calculate ' + newCalcAmountValue + ' ' + newCalcToken);
        const baseCurrency = up.baseCurrency;
        if(!isNaN(newCalcAmountValue) && newCalcToken.length > 2){
            if(baseCurrency === 'usd'){
                if(prices['usd-'+newCalcToken]!== null){
                    //setEarningTaxableValue(eAmount*prices['usd-'+newCalcToken]);
                    setNewValue(multipler*newCalcAmountValue*prices['usd-'+newCalcToken]);
                }
                
            }
            else {
                if(prices['usd-'+newCalcToken]!= null){
                    var roundingDecimals = 2;
                    const price = prices['usd-'+newCalcToken]/prices['usd-'+up.baseCurrency];
                    if(newCalcToken === 'btc'){
                        roundingDecimals = 6;
                    }
                    //setEarningTaxableValue((eAmount*price).toFixed(roundingDecimals));
                    setNewValue((multipler*newCalcAmountValue*price).toFixed(roundingDecimals));
                    //console.log('Selectd token'+eAmount+': '+earningToken + price);
                }
                
            }
        }
    }
    
    
    const handleSavePortfolio = () => {
        var formOk = true;
        var investedAmount = 0;
        var valueAmount = 0;
        
        
        if(isNaN(newValue)){
            formOk=false;
        }
        else if((typeof newValue === 'string' || newValue instanceof String) && newValue.trim().length ===0){
            //console.log('Empty String');
            valueAmount=0;
        }
        else{
            valueAmount=newValue;
        }
        
        if(formOk){
            var portfolioId = 0;
            var assetId = 0;
            var objToSend={};
            if(props.asset != null){
                assetId=props.asset.id;
                portfolioId=props.portfolio.id;
                objToSend={
                    command:'set_value_portfolio_asset',
                    portfolio:portfolioId,
                    asset:assetId,
                    value:valueAmount
    
                }
                console.log('Save portfolio asset value: ' +valueAmount);
            }
            else if(props.portfolio != null){
                portfolioId=props.portfolio.id;
                objToSend={
                    command:'set_value_portfolio',
                    portfolio:props.portfolio.id,
                    value:valueAmount
    
                }
                console.log('Save portfolio value: ' +valueAmount);
            }
            dispatch(executeAssetActionCall(objToSend)).unwrap();
        }
        else{
            console.log('Form not ok');
        }
        
    }
    //Conditional
    if(apiStatus == 'idle' || apiStatus == 'failed'){
        savePortfolioButton = <div className='center'><button className="button" type="button" onClick={handleSavePortfolio}>Save</button></div>
    }
    else if(apiStatus == 'succeeded'){
        if(props.asset != null){
            dispatch(dataUpdatePortfolioAssset(apiRespObj));
        }
        else if(props.portfolio != null){
            dispatch(dataUpdatePortfolio(apiRespObj));
        }
        
        hideForm();
    }
    

    const floatLeft = {
        float: 'left',
        width: '45%',
      };
    let helpCalculate = null;
    if(props.asset.code ==='nft'){
        helpCalculate =(
            <div>
                <div className='input-row' style={floatLeft}>
                        <label>Floor price</label>
                        <input type="text" value={newCalcAmountValue} onChange={e => setNewCalcAmount(e.target.value)}></input>
                    </div>
                    <div className='input-row' style={floatLeft}>
                        <label>Token</label>
                        <select value={newCalcToken} onChange={e => handleSelectCalcToken(e)}>
                            {earningTokenItems}
                        </select>
                        
                    </div>
                    <div className='input-row  clear' >
                        <span className='button' onClick={() => calculateValueClicked(props.asset.amount)}>Calc value ({props.asset.amount}x)</span>
                        <span className='button' onClick={() => calculateValueClicked(1)}>Calc value (1x)</span>
                        
                    </div>


            </div>

        );
    }

    
    return (
        <div className="modal-dialog">
        <div className="dialog-header">Set Value <span className='close' onClick={() => {hideForm()}}></span></div>
        <div className="content">
            
            <div>
                <div>{displayName}</div>
                <form className='normal-form'>
                    {helpCalculate}
                    <div className='input-row'>&nbsp;</div>
                    <div className='input-row' >
                        <label>Value</label>
                        <input type="text" value={newValue} onChange={e => setNewValue(e.target.value)}></input>
                    </div>
                    <div className='clear'></div>
                    {savePortfolioButton}

                </form>
                
            </div>
            
            
            
        </div>

        
    </div>
    )
}

export default SetPortfolioValueForm;