import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
    prices:{} ,
    portfolios:[],
    status: 'idle',
    error: null
  };
export const fetchPrices = createAsyncThunk('prices/fetchPrices', async () => {
const mainFetchUrl = global.config.baseUrl+'/v1/account-portfolios';//https://api.sunvesty.com/v1/account-portfolios';
const apiKey = sessionStorage.getItem('apikey');
console.log('api in priceSlice: ' + apiKey)
console.log('fetch prices url: ' + mainFetchUrl);
try{
    const response = await fetch(mainFetchUrl, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' +apiKey
        },
        });
        console.log('fetch prices finished: ' + response.status);  
        
        return response.json();
}
catch (error) {
    console.log(error);
}

});


export const priceSlice = createSlice({
    name: 'prices',
    initialState,
    reducers: {
        priceAdded: (state, newPrices) => {
        console.log('New price')
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        state.prices = newPrices
      }
    },
    extraReducers(builder) {
      builder
        .addCase(fetchPrices.pending, (state, action) => {
          state.status = 'loading'
          console.log('-------------------loading')
        })
        .addCase(fetchPrices.fulfilled, (state, action) => {
          state.status = 'succeeded'
          console.log('--------------------succeeded')
          console.log('action.payload: ' + JSON.stringify(action.payload));
          // Add any fetched posts to the array
          state.prices = action.payload.priceMap;
          state.portfolios = action.payload.portfolios;
        })
        .addCase(fetchPrices.rejected, (state, action) => {
          state.status = 'failed'
          console.log('--------------------failed');
          state.error = action.error.message
        })
    }
  })
  
  

  export default priceSlice.reducer
  export const selectAllPrices = state => state.price.prices