import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useSelector } from 'react-redux'
import {sunvestyGetDisplayValueFromInternal} from "../util/SunvestyRender";
import moment from 'moment';

function DonutChart(props){
    //select * from s_portfolio_asset where portfolio_id=5672004862607360
    //const earnings = useSelector(state => state.earningHistory.earnings);
    const earnings = useSelector(state => state.transactions.earnings);
    const prices = useSelector(state => state.data.prices)
    const up = useSelector(state => state.data.userprofile)
    if(earnings == null || up == null){
        return (<div className='skeleton bar-chart'></div>)
    }
    else{
       
        var seriesObj;
        var indexOfDate;
        var earningMoment;
        const displayCurrency = up.displayCurrency.toUpperCase();
        let pieData=[];
        for (const key in props.data){
            pieData.push({name:props.data[key].name,y:sunvestyGetDisplayValueFromInternal(props.data[key].y,up.displayCurrency,up.baseCurrency,prices)});
        }
        

        const pieChartInvestedData=[{name:'Bank',y:12,color:"#009ae2"},{name:'Crypto',y:23,color:"#005002"},{name:'Stocks',y:45,color:"#aa5002"},{name:'Other',y:2,color:"#33aa82"}];
        //const pieChartInvestedData=[12,23,45,2];
        //const pieChartInvestedData=[45,2];
        const pieChartConfig={
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: 0,
                plotShadow: false,
                backgroundColor:'rgba(255,255,255,0.0)'
            },
            legend: {
                enabled: false
            },
            title: {
                text: null,
                style:{color:'white'}
            },
            tooltip: {
                pointFormat: '<b>{point.y:.2f} '+displayCurrency+'</b> (<b>{point.percentage:.1f}%</b>)'
            },
            
            credits: {
                enabled: false
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: false,
                        style: {
                            fontWeight: 'bold',
                            color: 'white'
                            
                        }
                    },
                    center: ['50%', '50%']
                    
                }
            },
            series: [{
                type: 'pie',
                name: '',
                innerSize: '50%',
                data: pieData
            }]
        };
        
          return (
            <div className='chart'>

    
                <HighchartsReact highcharts={Highcharts} options={pieChartConfig} containerProps ={{ style: { height: "100%" } }} />
    
            </div>
        );
    }
    

    
}

export default DonutChart;