import { configureStore } from '@reduxjs/toolkit'
import priceReducer from '../features/price/priceSlice'
import loginReducer from '../features/login/loginSlice'
import dataReducer from '../features/data/dataSlice'
import earningHistoryReducer from '../features/earningHistory/earningHistorySlice'
import transactionReducer from '../features/transactionHistory/transactionHistorySlice'
import valueHistoryReducer from '../features/valueHistory/valueHistorySlice'
import contextReducer from '../features/context/contextSlice'
import sunvestyApiReducer from '../features/api/sunvestyApiSlice'
export default configureStore({
  reducer: {
    price:priceReducer,
    login:loginReducer,
    data:dataReducer,
    api:sunvestyApiReducer,
    earningHistory:earningHistoryReducer,
    transactions:transactionReducer,
    valueHistory:valueHistoryReducer,
    ctx:contextReducer
  }
})