import React, { useState,useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useSelector } from 'react-redux'
import {sunvestyGetDisplayValueFromInternal,sunvestyRenderFromInternal} from "../util/SunvestyRender";
import moment from 'moment';
import config from '../app/config';

function EarningBarChart(props){
    //select * from s_portfolio_asset where portfolio_id=5672004862607360
    
    const prices = useSelector(state => state.data.prices)
    const up = useSelector(state => state.data.userprofile)
    const period = useSelector(state => state.transactions.period);
    const emptySeriesObj = {name:'Taxable Earning',color:'#2a8fff',data:[]};
    //const [seriesObj,setSeriesObj]=useState(emptySeriesObj);
    const [barCategoryData,setBarCategoryData]=useState([]);
    const [timeBarCategorySeries,setTimeBarCategorySeries]=useState([]);
    const [isSettingsOpen, setSettingsOpen] = useState(false);

    const [chartType, setChartType] = useState('total');
    //const [periodToShow, setPeriodToShow] = useState('1month');
    const [totalEarning, setTotalEarning] = useState(0);
    const chartTypeColor = config.chartEarningTypeColor;
    
    const includeTransaction = (trx) => {
        if(chartType === 'total'){
            return true;
        }
        else if(chartType === 'staking' && trx.subType==='staking'){
            return true;
        }
        else if(chartType === 'lp' && trx.subType==='lp'){
            return true;
        }
        else if(chartType === 'interest' && trx.subType==='interest'){
            return true;
        }
        else if(chartType === 'farming' && trx.subType==='farming'){
            return true;
        }
        else if(chartType === 'card-cashback' && trx.subType==='card-cashback'){
            return true;
        }

        return false;
    }

    useEffect(() => {
        let numberOfMonths=3;
        let newBarCategoryData=[];
        let iterMoment;
        let data = [];
        let dateCatFormat = 'YYYY-MM';
        let indexOfDate;
        let tEarning=0;
        if(period === '1year'){
            numberOfMonths=11;
        }
        else if(period === '6months'){
            numberOfMonths=5;
        }
        else if(period === '3months'){
            numberOfMonths=2;
        }
        else if(period === '1month'){
            numberOfMonths=1;
        }
        let i;
        if(period === '1month'){
            dateCatFormat = 'YYYY-MM-DD';
            for(i=30;i>=0;i--){
                iterMoment = moment().subtract(i,'days');
                newBarCategoryData.push(iterMoment.format(dateCatFormat));
                data.push(0);
            }
        }
        else{
            for(i=numberOfMonths;i>=0;i--){
                iterMoment = moment().subtract(i,'months');
                newBarCategoryData.push(iterMoment.format(dateCatFormat));
                data.push(0);
            }
        }
        setBarCategoryData(newBarCategoryData);
        var newSeriesObj = JSON.parse(JSON.stringify(emptySeriesObj));
        newSeriesObj.color=chartTypeColor[chartType];
        newSeriesObj.data=data;
        if(props.transactions != null){
            
            props.transactions.forEach(eObj => {
                
                indexOfDate = barCategoryData.indexOf(moment(eObj.transactionDate).format(dateCatFormat));
                if(includeTransaction(eObj)){
                    tEarning+=eObj.value;
                    if(prices !== null){
                        newSeriesObj.data[indexOfDate] += sunvestyGetDisplayValueFromInternal(eObj.value,up.displayCurrency,up.baseCurrency,prices);
                    }
                    else{
                        newSeriesObj.data[indexOfDate] += eObj.value;
                        
                    }
                }
                
                
                
            });
            
            setTimeBarCategorySeries([newSeriesObj]);
            setTotalEarning(tEarning);
        }
        
    }, [props.transactions,chartType,period,up.displayCurrency])
     
    const handleChartSettings = (newDisplay) => {
        setChartType(newDisplay);
        //console.log('Clicked: ' + newDisplay);
    }  
    if(props.transactions === null || up === null){
        return (<div className='skeleton bar-chart'></div>)
    }
    else{
        
        //var today = moment();
        //var oneMonthBack = moment().subtract(1, 'months');
        //var twoMonthBack = moment().subtract(2, 'months');
        //var barCategoryData = [twoMonthBack.format('YYYY-MM'),oneMonthBack.format('YYYY-MM'),today.format('YYYY-MM')]; 
        //var timeBarCategorySeries = [];
        
        //var indexOfDate;
        //var earningMoment;
        const displayCurrency = up.displayCurrency.toUpperCase();
        
        //timeBarCategorySeries.push(seriesObj);
        
        
        
        const options = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: 0,
                plotShadow: false,
                backgroundColor:'rgba(255,255,255,0.0)',
                type: 'column',
            },
            legend: {
                enabled: false
            },
            title: {
                text: 'Earnings',
                style:{ "color": "#FFF", "fontSize": "18px" }
            },
            xAxis: {
                categories: barCategoryData,
                labels:{
                    style:{"color":'rgba(255,255,255,0.25)'}
                }
            },
            yAxis:{
                labels:{
                    style:{"color":"#FFF"}
                },
                title:{
                    style:{"color":"#FFF"},
                    text:displayCurrency
                }
            },
            tooltip: {
                pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:.4f}</b> '+displayCurrency+'<br/>',
                shared: true
            },
            plotOptions: {
                
            },     
            credits: {
                enabled: false
            },
            series: timeBarCategorySeries
            
          };
          return (
            <div>
                
                <div className='float two'>
                    <div className='obj-value'>
                        <div className='label'>Total</div>
                        <div className='value'>{sunvestyRenderFromInternal(totalEarning,up.displayCurrency,up.baseCurrency,prices)} <span className='explainer'>{up.displayCurrency}</span></div>
                    </div>
                </div>
                <div className='float two text-right'>
                    <span className={`select-one ${isSettingsOpen?'open':''}`} onClick={() => setSettingsOpen(!isSettingsOpen)}>
                        <span className="selected">{chartType}</span>
                            <div className="select-items">
                                <div className="item" onClick={() => handleChartSettings('total')}>Total</div>
                                <div className="item" onClick={() => handleChartSettings('staking')}>Staking</div>
                                <div className="item" onClick={() => handleChartSettings('lp')}>LP</div>
                                <div className="item" onClick={() => handleChartSettings('farming')}>Farming</div>
                                <div className="item" onClick={() => handleChartSettings('interest')}>Interest</div>
                                <div className="item" onClick={() => handleChartSettings('card-cashback')}>Card cashback</div>
                            </div>
                    </span>
                </div>
                <div className='clear'></div>
                <HighchartsReact highcharts={Highcharts} options={options} />
    
            </div>
        );
    }
    

    
}

export default EarningBarChart;