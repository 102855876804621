

function Footer() {
    
     return (
       <div >
         <div className="h1">Footer {global.config.version}</div>
       </div>
     );
   }
   
 export default Footer;