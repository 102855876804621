
import moment from 'moment';

function sunvestyRenderDate(orgDateString){
	let momentDate = moment(orgDateString);
	
	return momentDate.format('YYYY-MM-DD')
    
}
function sunvestyRenderDouble(amount,numberOfDecimals, decimalSeparator,thousandSeparator){
	var numberOfDec = 2;
	var decimalSep='.';
	var thousandSep=',';
	if(decimalSeparator != null){
		decimalSep=decimalSeparator;
	}
	if(thousandSeparator!=null){
		thousandSep=thousandSeparator;
	}
	
	
	if(numberOfDecimals != null){
		numberOfDec=numberOfDecimals;
	}
	return amount.numberFormat(numberOfDec,decimalSep, thousandSep);
    
}
/**/
function sunvestyRenderFromInternal(amount,displayCurrency,baseCurrency,prices){
	//console.log('Display called')
	
	var displayDecimals=2;
	if(displayCurrency==='btc'){
		displayDecimals=4;
	}
	return sunvestyRenderDouble(sunvestyGetDisplayValueFromInternal(amount,displayCurrency, baseCurrency,prices),displayDecimals,',',' ');	
}
function sunvestyGetDisplayValueFromInternal(amount,displayCurrency,baseCurrency,prices){
	
	if(prices != null){
		if(Math.abs(amount,0) < 0.000000000000001){
			return amount;
		}
		if(baseCurrency === 'usd'){
			if(displayCurrency === 'usd'){
				return amount;	
			}
			else{
				return amount/prices['usd-'+displayCurrency];	
			}
			
		}
		else{
			if(displayCurrency === baseCurrency){
				return amount;
			}
			else{
				if(displayCurrency === 'usd'){
					return amount*prices['usd-'+baseCurrency]
				}
				else{
					return amount*prices['usd-'+baseCurrency]/prices['usd-'+displayCurrency];
				}	
			}
			
		}
	}
	return amount;
	
	
}
Number.prototype.numberFormat = function(decimals, dec_point, thousands_sep) {
    dec_point = typeof dec_point !== 'undefined' ? dec_point : '.';
    thousands_sep = typeof thousands_sep !== 'undefined' ? thousands_sep : ',';

    var parts = this.toFixed(decimals).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

    return parts.join(dec_point);
}
export default sunvestyRenderDouble;
export {sunvestyRenderFromInternal,sunvestyGetDisplayValueFromInternal,sunvestyRenderDouble,sunvestyRenderDate};