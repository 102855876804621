import React,{useEffect, useState} from 'react';
import { useSelector,useDispatch } from 'react-redux'
import {sunvestyRenderFromInternal,sunvestyRenderDate} from "../../util/SunvestyRender";
import PortfolioAssetTransactionPopup from '../PortfolioAssetTransactionPopup';
import {setShowSetPortfolioAssetTransactionPopup} from '../../features/context/contextSlice'

function Table(props) {
    const recordsPerPage=25;
    const dispatch = useDispatch();
    const assets = useSelector(state => state.data.assets);
    
    const header = [{sortName:'transactionDate',displayName:'Date'},
                    {sortName:'assetId',displayName:'Asset'},
                    {sortName:'type',displayName:'Type'},
                    {sortName:'token',displayName:'Token'},
                    {sortName:'amount',displayName:'Amount'},
                    {sortName:'value',displayName:'Value'}];
    const [sort, setSort] = useState({column:header[0].sortName,order:'desc'});
    
    const ctx = useSelector(state => state.ctx);
    const up = useSelector(state => state.data.userprofile);
    const prices = useSelector(state => state.data.prices);
    var dataToShow=[];

    let trxPopup=<div></div>;
    if(ctx.showSetPortfolioAssetTransactionPopup != null){
        trxPopup = <PortfolioAssetTransactionPopup/>
    }
    
    
    const [data, setData] = useState([]);
    const [paging, setPaging] = useState({current:0,max:Math.ceil(props.tableData.length/recordsPerPage)}
    );
    

    
    const sortData = () => {
        var sorted = props.tableData.slice();
        if(sort.column==='transactionDate' || sort.column==='type' || sort.column==='token' ){
            if(sort.order === 'asc'){
                sorted = sorted.sort((a,b) => { return a[sort.column].localeCompare(b[sort.column])})
            }
            else{
                sorted = sorted.sort((a,b) => { return b[sort.column].localeCompare(a[sort.column])})
            }
        }
        else if(sort.column==='value' || sort.column==='amount' || sort.column==='assetId'){
            if(sort.order === 'asc'){
                sorted = sorted.sort((a,b) => { return a[sort.column]-b[sort.column]})
            }
            else{
                sorted = sorted.sort((a,b) => { return b[sort.column]-a[sort.column]})
            }
        }
        setData(sorted.slice());
    }
    const getAssetObj = (assetId) =>{
        let returnObj=null;
        assets.forEach(pfa => {
            if(pfa.id===assetId){
                returnObj=pfa;
            }
        });
        return returnObj;
    }


    useEffect(()=>{
        //setData(props.tableData.slice());
        
        setPaging({current:0,max:Math.ceil(props.tableData.length/recordsPerPage)})
        //setTotalPages(Math.ceil(props.tableData.length/recordsPerPage));
        //setCurrentPage(0);
        sortData();
        //console.log('useEffect propsData updated');
        //console.log('Table:useEffect data count' + data.length);
        //console.log('Table:useEffect props.data count' + props.tableData.length);
        //sliceData();
       
    }, [props.tableData,sort])
    
    
    const incrementPage = () => {
        const newCurrent=paging.current+1;
        setPaging({current:newCurrent,max:Math.ceil(props.tableData.length/recordsPerPage)});
        
    }
    const decrementPage = () => {
        
        if(paging.current > 0){
            const newCurrent=paging.current-1;
            setPaging({current:newCurrent,max:Math.ceil(props.tableData.length/recordsPerPage)});
        }
        
    }
    
    const handleSortClick = (key) => {
        if(key === sort.column){
            if(sort.order === 'desc'){
                setSort({column:key,order:'asc'});
            }
            else{
                setSort({column:key,order:'desc'});
            }
        }
        else{
            setSort({column:key,order:'desc'});
        }
        //sortData();
        console.log('Sort Clicked: ' + key);
    }
    const handleTableRowClick = (key) => {
        let trxToShow = dataToShow[key];
        dispatch(setShowSetPortfolioAssetTransactionPopup(trxToShow));;
        console.log('Clicked: ' + key);
    }
    var previousNav=<div/>;
    if(paging.current>0){
        previousNav = (<span onClick={() => decrementPage()}>previous</span>);
    }
    var nextNav=<div/>;
    if(paging.current<(paging.max-1)){
        nextNav = (<span onClick={() => incrementPage()}>next</span>);
    }
    dataToShow=[];
    const startId = paging.current*recordsPerPage;
    let displayTrxObj;
    for(let i=startId;i < Math.min(startId+recordsPerPage,data.length);i++){
        displayTrxObj = JSON.parse(JSON.stringify(data[i]))
        displayTrxObj.asset = getAssetObj(displayTrxObj.assetId);
        displayTrxObj.toAsset = getAssetObj(displayTrxObj.toAssetId);
        dataToShow.push(displayTrxObj);
    }
    
    if(ctx != null){
        return (
            <div>
                {trxPopup}
                <div>
                    {previousNav}
                    <span> {paging.current+1} of {paging.max} </span>
                    {nextNav}
                </div>
                <table className='data'>
                    <thead>
                        <tr>
                            {header.map((h, i) => 
                                <th key={i} 
                                    className={`sortable ${h.sortName===sort.column?sort.order:''}`} 
                                    onClick={() =>handleSortClick(h.sortName)}
                                >{h.displayName}</th>
                                )
                            }
                            <th key={'note'}>
                                Note
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataToShow.map((h,i) => {
                            if(h.note ===''){
                                return (
                                    <tr key={h.id} onClick={() =>handleTableRowClick(i)}>
                                        <td>{sunvestyRenderDate(h.transactionDate)}</td>
                                        <td>{h.asset.name}</td>
                                        <td>{h.type} - {h.subType}</td>
                                        <td>{h.token}</td>
                                        <td>{h.amount}</td>
                                        <td>{sunvestyRenderFromInternal(h.value,up.displayCurrency,up.baseCurrency,prices) + ' '+up.displayCurrency.toUpperCase()}</td>
                                        <td></td>
                                    </tr>
                                )
                            }
                            else{
                                return (
                                    <tr key={h.id} onClick={() =>handleTableRowClick(i)}>
                                        <td>{sunvestyRenderDate(h.transactionDate)}</td>
                                        <td>{h.asset.name}</td>
                                        <td>{h.type} - {h.subType}</td>
                                        <td>{h.token}</td>
                                        <td>{h.amount}</td>
                                        <td>{sunvestyRenderFromInternal(h.value,up.displayCurrency,up.baseCurrency,prices) + ' '+up.displayCurrency.toUpperCase()}</td>
                                        <td className='note'><div><span className='dot red'></span></div><div className='n-content'><div><strong>Note</strong></div>{h.note}</div></td>
                                    </tr>
                                )
                            }
                        }
                            
                        
                            
                        )}
                    </tbody>
                </table>
            </div>
            
        )
    }
    else{
        return(<div>No ctx</div>)
    }
    
}
export default Table;