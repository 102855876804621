import React from 'react';
import { useEffect,useState } from 'react';
import { useSelector,useDispatch } from 'react-redux'
import { executeAssetActionCall,clear } from '../../features/api/sunvestyApiSlice'
import { dataDeposit } from '../../features/data/dataSlice'
import moment from 'moment';
function SwapTransactionForm(){
    const dispatch = useDispatch();
    const ctx = useSelector(state => state.ctx);
    const assets = useSelector(state => state.data.assets);
    const toTokens = global.config.supportedTokens;
    const [swapOutAmount, setSwapOutAmount] = useState('');
    const [swapToToken, setSwapToToken] = useState('');
    const [swapToAmount, setSwapToAmount] = useState('');
    const [swapToAsset, setSwapToAsset] = useState('');
    const [swapNote, setSwapNote] = useState('');
    const [swapTransactionDate, setSwapTransactionDate] = useState(moment().format('YYYY-MM-DD HH:mm:ss'));
    
    const apiStatus = useSelector(state => state.api.status);
    const apiRespObj = useSelector(state => state.api.respObj);

    const swapToTokenItems = toTokens.map((aObj) => 
        <option value={aObj} key={aObj}>{aObj}</option>
    );
    
    let possibleAssetArray = [];
    assets.forEach(aObj => {
        if(aObj.code === swapToToken && swapToToken !== ''){
            possibleAssetArray.push(aObj);
        }
    });
    
    const swapToTokenAssets = possibleAssetArray.map((aObj) => 
        <option value={aObj.id} key={aObj.id}>{aObj.name}</option>
    );
    if(swapToAsset === '' && possibleAssetArray.length > 0){
        setSwapToAsset(possibleAssetArray[0].id);
    }

    
    var swapButton = <div></div>
    const swapAction = async(e) => {
        e.preventDefault();

        if(swapOutAmount > 0 && swapToAmount > 0){
            const objToSend = {
                swapOutAmount:swapOutAmount,
                code:swapToToken,
                swapInAmount:swapToAmount,
                note:swapNote,
                portfolio:ctx.showActionFormPortfolioAsset.portfolioId,
                asset:ctx.showActionFormPortfolioAsset.id,
                toId:parseInt(swapToAsset),
                transactionDate:swapTransactionDate,
                command:'swap'

            };
            await dispatch(executeAssetActionCall(objToSend)).unwrap();
            
            //dispatch(dataDeposit({amount:depositAmount, invested:depositInvested, assetID:ctx.showActionFormPortfolioAsset.id}));
        }
        console.log('Swap action called amount: ' + swapOutAmount);
    }
    const handleSelectSwapToToken = (event) => {
        setSwapToToken(event.target.value);
        setSwapToAsset('');
        //calculateEarning(earningAmount,event.target.value);
        
    }
    const handleSelectSwapToAsset = (event) => {
        setSwapToAsset(event.target.value);
        //calculateEarning(earningAmount,event.target.value);
        
    }

    if(apiStatus === 'idle' || apiStatus === 'failed'){
        swapButton = <div className='center'><button className="button" type="button" onClick={swapAction}>Swap</button></div>
    }

    const floatLeft = {
        float: 'left',
        width: '45%',
      };
    return (
        <div>
                <form className='normal-form'>
                <div className='input-row'>
                    <label>Amount {ctx.showActionFormPortfolioAsset != null?parseFloat(ctx.showActionFormPortfolioAsset.amount).toFixed(2):'0'}</label>
                    <input type="text" value={swapOutAmount} onChange={e => setSwapOutAmount(e.target.value)}></input>
                </div>
                <div className='input-row' style={floatLeft}>
                    <label>To token</label>
                    <select value={swapToToken} onChange={e => handleSelectSwapToToken(e)}>
                        {swapToTokenItems}
                    </select>
                    
                </div>
                <div className='input-row' style={floatLeft}>
                    <label>Amount</label>
                    <input type="text" value={swapToAmount} onChange={e => setSwapToAmount(e.target.value)}></input>
                </div>
                <div className='input-row' style={floatLeft}>
                    <label>To Asset</label>
                    <select value={swapToAsset} onChange={e => handleSelectSwapToAsset(e)}>
                        {swapToTokenAssets}
                    </select>
                   
                </div>
                <div className='input-row' style={floatLeft}>
                    <label>Transaction Date</label>
                    <input type="text" value={swapTransactionDate} onChange={e => setSwapTransactionDate(e.target.value)}></input>
                </div>
                <div className='input-row'>
                    <label>note</label>
                    <textarea value={swapNote} onChange={e => setSwapNote(e.target.value) } rows={3}/>
                </div>
                {swapButton}
            </form>

        </div>
        
        
    )



}

export default SwapTransactionForm;