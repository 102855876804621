import { createSlice } from '@reduxjs/toolkit'

export const contextSlice = createSlice({
    name: 'ctx',
    initialState: {
      displayCurrency: 'usd',
      displayModule:'dashboard',
      objectId:null,
      showActionFormPortfolioAsset:null,
      showCreateFormPortfolio:null,
      showCreateFormPortfolioAsset:null,
      showSetValueFormPortfolio:null,
      showSetValueFormPortfolioAsset:null,
      showSetPortfolioAssetTransactionPopup:null,
    },
    reducers: {
      setDisplayCurrency: (state, action) => {
        state.displayCurrency = action.payload
      },
      setDisplayModule: (state, action) => {
        state.displayModule = action.payload
        state.objectId=null;
      },
      setObjectId: (state, action) => {
        state.objectId = action.payload
      },
      setShowActionFormAsset: (state, action) => {
        state.showActionFormPortfolioAsset = action.payload
      },
      setShowCreatePortfolioForm: (state, action) => {
        state.showCreateFormPortfolio = action.payload
      },
      setShowCreatePortfolioAssetForm: (state, action) => {
        state.showCreateFormPortfolioAsset = action.payload
      },
      setSetValueFormPortfolio: (state, action) => {
        state.showSetValueFormPortfolio = action.payload
      },
      setSetValueFormPortfolioAsset: (state, action) => {
        state.showSetValueFormPortfolioAsset = action.payload
      },
      setShowSetPortfolioAssetTransactionPopup: (state, action) => {
        state.showSetPortfolioAssetTransactionPopup = action.payload
      },
    },
  })

  export const { setDisplayCurrency,setDisplayModule,setObjectId,setShowActionFormAsset,setShowCreatePortfolioAssetForm,setShowCreatePortfolioForm,setSetValueFormPortfolio,setSetValueFormPortfolioAsset,setShowSetPortfolioAssetTransactionPopup} = contextSlice.actions
  export default contextSlice.reducer