import React,{useState,useEffect} from 'react';
import { useSelector,useDispatch } from 'react-redux'
import {setObjectId} from '../../features/context/contextSlice'

function SelectPortfolioAsset(props) {
    const dispatch = useDispatch();
    const ctx = useSelector(state => state.ctx);
    var selectedAsset=null;
    const [isOpen, setOpen] = useState(false);
    const [availableAssetCodes, setAvailableAssetCodes] = useState([]);
    //const initialDisplayName = props.displayName;
    //const [displayName, setDisplayName] = useState(initialDisplayName);
    
    /*
    useEffect(()=>{
        var filteredArray=[];
        assets.forEach(aObj => {
            
            if(!filteredArray.includes(aObj.code)){
                filteredArray.push(aObj.code);
                //console.log('DID FIND: ' + aObj.code);
            }
            else{
                //console.log('DID NOT FIND: ' + aObj.code);
            }
        });
        setAvailableAssetCodes(filteredArray);
       
    }, [assets])*/

    /*
    const toggleOpen = () => {
        console.log('Toggle open: ' + isOpen);
        setOpen(!isOpen);
        
    }*/

    const handleSelectAsset = (newObject) => {
        /*if(newObject != null){
            setDisplayName(newObject.name);
        }
        else{
            setDisplayName('All');
        }*/
        
        if(ctx != null){
            //dispatch(setObjectId(newObject.id));
        }
        //props.callback((newObject)=>{});
        props.callback(newObject);
        //console.log('Clicked: ' + newDisplay);
    }
    var selectItems = null;
    if(props.assets != null){
        selectItems = props.assets.map((aObj) =>
            <div className="item" onClick={() => handleSelectAsset(aObj)} key={aObj.id}>{aObj.name}</div>
        );
    }
    
    

    if(ctx != null){
        return (
            <span className={`select-one ${isOpen?'open':''}`} onClick={() => setOpen(!isOpen)}>
                <span className="selected">{props.displayName}</span>
                    <div className="select-items">
                        <div className="item" onClick={() => handleSelectAsset(null)}>All</div>
                        {selectItems}
                    </div>
            </span>
        )
    }
    else{
        return(<div>No ctx</div>)
    }
    
}
export default SelectPortfolioAsset;