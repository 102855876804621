import React from 'react';
import {useState } from 'react';
import { useSelector,useDispatch } from 'react-redux'
import { executeAssetActionCall } from '../../features/api/sunvestyApiSlice'
import moment from 'moment';
function EarningTransactionForm(){
    const dispatch = useDispatch();
    const ctx = useSelector(state => state.ctx);
    const assets = useSelector(state => state.data.assets);
    const prices = useSelector(state => state.data.prices);
    const up = useSelector(state => state.data.userprofile);
    const period = useSelector(state => state.transactions.period);
    const [earningAmount, setEarningAmount] = useState('');
    const [depositAsset, setDepositAsset] = useState('');
    const [onlySameBlochain, setOnlySameBlochain] = useState(false);

    var defaultSelectedType='';
    var defaultSelectedToken='';
    if(ctx.showActionFormPortfolioAsset.type === 'stock'){
        defaultSelectedType='dividend';
        defaultSelectedToken = up.baseCurrency;
    }
    else if(ctx.showActionFormPortfolioAsset.type === 'crypto'){
        defaultSelectedToken = ctx.showActionFormPortfolioAsset.code;
        if(ctx.showActionFormPortfolioAsset.subType==='defi_lp'){
            defaultSelectedType='farming';
        }
        else if(ctx.showActionFormPortfolioAsset.subType==='staking'){
            defaultSelectedType='staking';
        }
        else if(ctx.showActionFormPortfolioAsset.subType==='defi_lending'){
            defaultSelectedType='interest';
        }
    }
    else if(ctx.showActionFormPortfolioAsset.type === 'fiat'){
        defaultSelectedToken = up.baseCurrency;
    }
    const [earningToken, setEarningToken] = useState(defaultSelectedToken);
    const [earningType, setEarningType] = useState(defaultSelectedType);
    const [earningTaxableValue, setEarningTaxableValue] = useState('');

    const [earningTransactionDate, setEarningTransactionDate] = useState(moment().format('YYYY-MM-DD HH:mm:ss'));
    const [earningNote, setEarningNote] = useState('');
    const apiStatus = useSelector(state => state.api.status);
    const apiRespObj = useSelector(state => state.api.respObj);
    var earningButton = <div></div>
    const earningTypes = ['dividend','staking', 'interest','lp','farming','card-cashback'];
    const earningTokens = global.config.supportedTokens;
    const earningItems = earningTypes.map((aObj) =>
        <option value={aObj} key={aObj}>{aObj}</option>
    );
    const earningTokenItems = earningTokens.map((aObj) =>
        <option value={aObj} key={aObj}>{aObj} </option>
    );

    const isSelectedInPossible = () => {
        let returnVal = false;
        
        possibleAssetArray.forEach(aObj => {
            
            if(aObj.id === parseInt(depositAsset)){
                returnVal = true;
            }
        });
        return returnVal;
    }


    let possibleAssetArray = [];
    assets.forEach(aObj => {
        if(aObj.code === earningToken && earningToken !== '' 
        && (!onlySameBlochain || aObj.blockchain === ctx.showActionFormPortfolioAsset.blockchain)){
            possibleAssetArray.push(aObj);
        }
    });
    
    const depositAssets = possibleAssetArray.map((aObj) => 
        <option value={aObj.id} key={aObj.id}>{aObj.name} ({parseFloat(aObj.amount).toFixed(2)} {aObj.code.toUpperCase()})</option>
    );
    if((depositAsset === '' || !isSelectedInPossible() )&& possibleAssetArray.length > 0 ){
        setDepositAsset(possibleAssetArray[0].id);
    }

    const earningAction = async(e) => {
        e.preventDefault();
        if(earningAmount > 0){
            //console.log('Type: ' + earningType);
            //console.log('Token: ' + earningToken);
            var trxDate=null
            if(earningTransactionDate !== null && earningTransactionDate.trim()!==''){
                trxDate=earningTransactionDate;
            }
            const objToSend = {
                amount:earningAmount,
                token:earningToken,
                type:earningType,
                taxableValue:earningTaxableValue,
                transactionDate:trxDate,
                note:earningNote,
                portfolio:ctx.showActionFormPortfolioAsset.portfolioId,
                asset:ctx.showActionFormPortfolioAsset.id,
                toAssetId:parseInt(depositAsset),
                command:'earning'

            };
            await dispatch(executeAssetActionCall(objToSend)).unwrap();
            
        }
        //console.log('Deposit action called amount: ' + depositAmount);
    }
    const handleSelectEarningToken = (event) => {
        setEarningToken(event.target.value);
        setDepositAsset('');
        calculateEarning(earningAmount,event.target.value);
        
    }
    const handleSelectDepositAsset = (event) => {
        setDepositAsset(event.target.value);
        //calculateEarning(earningAmount,event.target.value);
        
    }
    const calculateEarning = (eAmount,eToken) =>{
        const baseCurrency = up.baseCurrency;
        if(eToken === baseCurrency){
            setEarningTaxableValue(eAmount);
        }
        else if(baseCurrency === 'usd'){
            if(prices['usd-'+eToken]!== null){
                setEarningTaxableValue(eAmount*prices['usd-'+eToken]);
            }
            
        }
        else {
            let pToken = eToken;
            if(eToken==='eurx'){
                pToken = 'eur';
            }
            if(prices['usd-'+pToken]!= null){
                var roundingDecimals = 2;
                const price = prices['usd-'+pToken]/prices['usd-'+up.baseCurrency];
                if(eToken === 'btc'){
                    roundingDecimals = 6;
                }
                setEarningTaxableValue((eAmount*price).toFixed(roundingDecimals));
                //console.log('Selectd token'+eAmount+': '+earningToken + price);
            }
            
        }
        
        
    }
    const handleSelectEarningType = (event) => {
        setEarningType(event.target.value);
        console.log('Selectd type: '+event.target.value);
    }
    const handleSetEarningAmount = (newValue) =>{
        const nValue = newValue.replace(',','.');
        setEarningAmount(nValue);
        calculateEarning(nValue,earningToken);
    } 
    const handleUseSameBlockhainChange = () =>{
        setOnlySameBlochain(!onlySameBlochain);
    }

    if(apiStatus == 'idle' || apiStatus == 'failed'){
        earningButton = <div className='center'><button className="button" type="button" onClick={earningAction}>Create Earning</button></div>
    }

    const floatLeft = {
        float: 'left',
        width: '45%',
      };

    return (
        <div>
            
            <form className='normal-form'>
                <div className='input-row'>
                    <label>Earning Amount</label>
                    <input type="text" value={earningAmount} onChange={e => handleSetEarningAmount(e.target.value)}></input>
                </div>
                <div className='input-row' style={floatLeft}>
                    <label>Token</label>
                    <select value={earningToken} onChange={e => handleSelectEarningToken(e)}>
                        {earningTokenItems}
                    </select>
                </div>
                <div className='input-row' style={floatLeft}>
                    <label>Type</label>
                    <select value={earningType} onChange={e => handleSelectEarningType(e)}>
                        {earningItems}
                    </select>
                </div>
                <div className='input-row' style={floatLeft}>
                    <label>Same blockchain</label>
                    <input type="checkbox" checked={onlySameBlochain}
          onChange={handleUseSameBlockhainChange} style={{width:"auto"}}/>
                    
                </div>
                <div className='input-row' style={floatLeft}>
                    <label>Deposit asset</label>
                    <select value={depositAsset} onChange={e => handleSelectDepositAsset(e)}>
                        {depositAssets}
                    </select>
                </div>
                <div className='input-row' style={floatLeft}>
                    <label>Taxable Earning</label>
                    <input type="text" value={earningTaxableValue} onChange={e => setEarningTaxableValue(e.target.value)}></input>
                </div>
                <div className='input-row' style={floatLeft}>
                    <label>Transaction Date</label>
                    <input type="text" value={earningTransactionDate} onChange={e => setEarningTransactionDate(e.target.value)}></input>
                </div>
                <div className='clear'></div>

                <div className='input-row'>
                    <label>note</label>
                    <textarea value={earningNote} onChange={e => setEarningNote(e.target.value) } rows={3}/>
                </div>

                {earningButton}
            </form>

        </div>
        
    )



}

export default EarningTransactionForm;