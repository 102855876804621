import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
    earnings:null ,
    status: 'idle',
    error: null
  };

  export const fetchEarningData = createAsyncThunk('history/fetchEarnings', async () => {
    const mainFetchUrl = global.config.baseUrl+'/portfolio-earnings';//https://api.sunvesty.com/v1/account-portfolios';
    const apiKey = sessionStorage.getItem('apikey');

    console.log('api in priceSlice: ' + apiKey)
    console.log('fetch prices url: ' + mainFetchUrl);
    const response = await fetch(mainFetchUrl, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' +apiKey
        },
        });
        console.log('fetch prices finished: ' + response.status);  
        
        return response.json();

    });
    export const earningHistorySlice = createSlice({
        name: 'earning',
        initialState,
        reducers: {
            clearEarningHistoryData: (state) => {
                // Redux Toolkit allows us to write "mutating" logic in reducers. It
                // doesn't actually mutate the state because it uses the Immer library,
                // which detects changes to a "draft state" and produces a brand new
                // immutable state based off those changes
                state.earnings = null;
                state.error=null;
                state.status='idle';
              }
        },
        extraReducers(builder) {
          builder
            .addCase(fetchEarningData.pending, (state, action) => {
              state.status = 'loading'
              //console.log('-------------------loading')
            })
            .addCase(fetchEarningData.fulfilled, (state, action) => {
              state.status = 'succeeded'
              //console.log('--------------------succeeded')
              //console.log('action.payload: ' + JSON.stringify(action.payload));
              // Add any fetched posts to the array
              state.earnings = action.payload;
              //state.portfolios = action.payload.portfolios;
              
            })
            .addCase(fetchEarningData.rejected, (state, action) => {
              state.status = 'failed'
              //console.log('--------------------failed');
              state.error = action.error.message
            })
        }
      })
    
      export default earningHistorySlice.reducer
      export const earnings = state => state.data.earnings
      export const { clearEarningHistoryData } = earningHistorySlice.actions