
import React,{useState,useEffect} from 'react';
import SelectDisplayCurrency from '../module/ui/SelectDisplayCurrency';
import SelectAsset from '../module/ui/SelectAsset';
import SelectPortfolioAsset from '../module/ui/SelectPortfolioAsset';
import SelectBlockchain from '../module/ui/SelectBlockchain';
import SelectPortfolioAssetType from '../module/ui/SelectPortfolioAssetType';
import { useSelector,useDispatch } from 'react-redux'
import PortfolioAssetCardGrid from '../module/PortfolioAssetCardGrid';
import SetPortfolioValueForm from '../module/SetPortfolioValueForm';
import {sunvestyRenderFromInternal,sunvestyRenderDouble,sunvestyGetDisplayValueFromInternal} from "../util/SunvestyRender";
import CreatePortfolioAssetForm from '../module/CreatePortfolioAssetForm ';
import PortfolioAssetActionForm from '../module/PortfolioAssetActionForm';
import Card from '../module/Card';
import Table from '../module/ui/Table';
import PortfolioAsset from './PortfolioAsset';
import EarningBarChart from '../module/EarningBarChart';
import ValueAmountChart from '../module/ui/ValueAmountChart';
import AssetCardGrid from '../module/AssetCardGrid';
import {setObjectId} from '../features/context/contextSlice'
import SelectDisplayPeriod from '../module/ui/SelectDisplayPeriod';
import config from '../app/config';
function Asset(props) {
    const dispatch = useDispatch();
    const portfolios = useSelector(state => state.data.portfolios);
    const period = useSelector(state => state.transactions.period);
    const assets = useSelector(state => state.data.assets);
    const ctx = useSelector(state => state.ctx);
    const up = useSelector(state => state.data.userprofile);
    const prices = useSelector(state => state.data.prices);
    const [filteredAssets, setFilteredAssets] = useState([]);
    const [selectedAsset, setSelectedAsset] = useState(ctx.objectId);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalValue, setTotalValue] = useState(0);
    const [totalInvested, setTotalInvested] = useState(0);
    const [selectedTab,setSelectedTab]=useState('assets');
    const transactions = useSelector(state => state.transactions.transactions);
    const [tableData, setTableData] = useState([]);
    const [valuePieData, setValuePieData] = useState([]);
    const [earningTypePieData, setEarningTypePieData] = useState([]);
    const [filteredEarnTrx,setFilteredEarnTrx]=useState([]);
    const [selectedPortfolioAsset,setSelectedPortfolioAsset]=useState(null);
    const [filteredBlockchain,setFilteredBlockchain]=useState(null);
    const [selectedBlockchain,setSelectedBlockchain]=useState(null);
    const [selectedPortfolioAssetTypeFilter,setSelectedPortfolioAssetTypeFilter]=useState('all');
    const [totalEarned, setTotalEarned] = useState(0);
    const [aprInPeriod, setAprInPeriod] = useState(0);
    const chartTypeColor = config.chartAssetTypeColor;
    
    var setValueForm = <div></div>;
    const handleSelectAsset = (selectedAsset)=>{
        console.log('Selected asset: ' + selectedAsset);
        setSelectedAsset(selectedAsset);
        setSelectedPortfolioAsset(null);
        setSelectedBlockchain(null);
    }
    const handleSelectAssetFilter = (filter)=>{
        console.log('Selected asset type: ' + filter);
        setSelectedPortfolioAssetTypeFilter(filter);
        
    }
    const backClicked = () => {
        if(ctx != null){
            dispatch(setObjectId(null));
        }
        handleSelectAsset(null);
    }
    let displayModule=<AssetCardGrid onSelect={handleSelectAsset} typeFilter={selectedPortfolioAssetTypeFilter}/>
    var actionForm=<div></div>;
    const getAsset= (id)=>{
        var returnObj = null;
        assets.forEach(aObj => {
                
            if( aObj.id === id){
                returnObj=aObj;
                return returnObj;
            }
        });
        return returnObj;
    }

    const handleSelectedTab = (newSelected) =>{
        setSelectedTab(newSelected);
        //console.log('New selected: ' + newSelected);
    }
    /*const valuePieData=[{name:'Bank',y:0,color:"#972424"},
                            {name:'Crypto',y:0,color:"#f6931a"},
                            {name:'Stocks',y:0,color:"#00701e"},
                            {name:'Real Estate',y:0,color:"#287ed5"},
                            {name:'Other',y:0,color:"#33aa82"}];*/
    if(ctx.showActionFormPortfolioAsset != null){
        actionForm = <PortfolioAssetActionForm/>
    }
    
    var createPortfolioAssetForm = <div></div>;
    var selectedPortfolio=null;
    if(ctx.showCreateFormPortfolioAsset != null){
        portfolios.forEach(pf => {
            if(pf.id===ctx.showCreateFormPortfolioAsset.portfolioId){
                selectedPortfolio=pf;
            }
        });
        createPortfolioAssetForm = <CreatePortfolioAssetForm portfolio={selectedPortfolio} pAsset={ctx.showCreateFormPortfolioAsset}/>
    }
    else if(ctx.showSetValueFormPortfolioAsset != null){
        portfolios.forEach(pf => {
            if(pf.id===ctx.showSetValueFormPortfolioAsset.portfolioId){
                selectedPortfolio=pf;
            }
        });
        setValueForm = <SetPortfolioValueForm asset={ctx.showSetValueFormPortfolioAsset} portfolio={selectedPortfolio}/>;
    }
    
    const handleSelectPortfolioAsset = (selectedAsset)=>{
        if(selectedAsset != null){
            console.log('Selected portfolio asset: ' + selectedAsset.name);
        }
        else{
            console.log('Selected portfolio asset: nullified');
        }
        setSelectedPortfolioAsset(selectedAsset);
        //setSelectedAsset(selectedAsset);
    }
    const handleSelectBlockchain = (selected)=>{
        if(selected != null){
            console.log('Selected blockchain: ' + selected);
        }
        else{
            console.log('Selected portfolio asset: nullified');
        }
        setSelectedBlockchain(selected);
    }


    var customSelects = null;
    if(selectedAsset != null){
        let displayAssetFilter = 'All';
        let displayBlockchainFilter = 'All';
        if(selectedPortfolioAsset !== null){
            displayAssetFilter = selectedPortfolioAsset.name;
            customSelects = <SelectPortfolioAsset assets={filteredAssets} displayName={displayAssetFilter} callback={handleSelectPortfolioAsset}/>;
        }
        else{
            if(global.config.cryptoAssets.indexOf(selectedAsset) >=0){
                if(selectedBlockchain !== null){
                    displayBlockchainFilter = selectedBlockchain;
                }
                customSelects = (
                    <span>
                        <SelectBlockchain blockchains={filteredBlockchain} displayName={displayBlockchainFilter} callback={handleSelectBlockchain}/>
                        <SelectPortfolioAsset assets={filteredAssets} displayName={displayAssetFilter} callback={handleSelectPortfolioAsset}/>
                    
                </span>
                );
            }
            else{
                customSelects = <SelectPortfolioAsset assets={filteredAssets} displayName={displayAssetFilter} callback={handleSelectPortfolioAsset}/>
            }
            
        }
        
        
    }
    const includeAssetInFilter = (pAObj) =>{
        let blockchainFilter = selectedBlockchain === null || selectedBlockchain == pAObj.blockchain;
        let pAssetFilter = (selectedPortfolioAsset === null && pAObj.code === selectedAsset) 
                            || (selectedPortfolioAsset !== null && selectedPortfolioAsset.id ===pAObj.id);
        let pAssetTypeFilter = selectedPortfolioAssetTypeFilter ==='all' ||  selectedPortfolioAssetTypeFilter ===pAObj.type;
        return blockchainFilter && pAssetFilter && pAssetTypeFilter;
    }
    const includeTransactionInFilter = (pAObj) =>{
        let blockchainFilter = selectedBlockchain === null || selectedBlockchain == pAObj.blockchain;
        let pAssetFilter = (selectedAsset != null && pAObj.code === selectedAsset) 
        return blockchainFilter && pAssetFilter;
    }


    useEffect(()=>{
        let filteredArray=[];
        let filteredEarnArray=[];
        let newPieData=[];
        let newTypePieData=[];
        let newTypeObj={};
        let iterAmount=0;
        let iterValue=0;
        let iterInvested=0;
        let trxAsset;
        let filteredtableData = [];
        let myBlockchainSet = new Set();
        let filteredBs = [];
        let earningInFilter=0;
        let displayValue=0;
        if(selectedAsset != null){
            if(assets != null){
                assets.forEach(aObj => {
                    
                    if(selectedAsset != null && aObj.code === selectedAsset){
                        
                        if(aObj.blockchain !== ''){
                            myBlockchainSet.add(aObj.blockchain);
                        }
                        if(includeAssetInFilter(aObj)){
                            filteredArray.push(JSON.parse(JSON.stringify(aObj)));
                            iterAmount += aObj.amount;
                            iterValue += aObj.value;
                            //displayValue = sunvestyGetDisplayValueFromInternal(aObj.value,up.displayCurrency,up.baseCurrency,prices);
                            iterInvested += aObj.invested;
                            newPieData.push({name:aObj.name,y:aObj.value});
                            //newPieData.push({name:aObj.name,y:displayValue});
                            if(newTypeObj[aObj.subType] == null){
                                newTypeObj[aObj.subType]=aObj.value;
                            }
                            else{
                                newTypeObj[aObj.subType]+=aObj.value;
                            }
                            
                            //newTypePieData.push({name:aObj.subType,y:aObj.value});
                        }
                        /*
                        if(selectedPortfolioAsset == null && aObj.code === selectedAsset){
                            iterAmount += aObj.amount;
                            iterValue += aObj.value;
                            iterInvested += aObj.invested;
                            newPieData.push({name:aObj.name,y:aObj.value});
                        }
                        else if(selectedPortfolioAsset !== null && aObj.id === selectedPortfolioAsset.id){
                            iterAmount += aObj.amount;
                            iterValue += aObj.value;
                            iterInvested += aObj.invested;
                            newPieData.push({name:aObj.name,y:aObj.value});
                        }*/
                    }
                    
                });
            filteredBs = Array.from(myBlockchainSet);
            if(transactions != null){
                transactions.forEach(tObj => {
                    trxAsset = getAsset(tObj.assetId);
                    if(includeTransactionInFilter(trxAsset)){
                        filteredtableData.push(JSON.parse(JSON.stringify(tObj)));
                        if(tObj.type==='earning'){
                            filteredEarnArray.push(JSON.parse(JSON.stringify(tObj)));
                            earningInFilter+=tObj.value;
                        }
                    }
                    /*if((selectedAsset !== null && trxAsset !==null) &&
                        ((selectedPortfolioAsset === null && selectedAsset === trxAsset.code)
                        ||   (selectedPortfolioAsset !== null && selectedPortfolioAsset.id === tObj.assetId) 
                    )){
                        filteredtableData.push(JSON.parse(JSON.stringify(tObj)));
                        if(tObj.type==='earning'){
                            filteredEarnArray.push(JSON.parse(JSON.stringify(tObj)));
                        }
                    }*/
                });
            }
            
            }
            for (const key in newTypeObj){
                if(newTypeObj.hasOwnProperty(key)){
                  //console.log(`${key} : ${res[key]}`)
                  newTypePieData.push({name:key,y:newTypeObj[key],color:chartTypeColor[key]});
                }
            }
            newTypePieData = newTypePieData.sort((a, b) => {
                if (a.y > b.y) {
                  return -1;
                }
              });
        }

        setTotalAmount(iterAmount);
        setTotalValue(iterValue);
        setTotalInvested(iterInvested);

        filteredArray.sort(function(a, b){return b.value - a.value});
        newPieData.sort(function(a, b){return b.y - a.y});

        setFilteredAssets(filteredArray);
        setTableData(filteredtableData);
        setValuePieData(newPieData);
        setEarningTypePieData(newTypePieData);
        setFilteredEarnTrx(filteredEarnArray);
        setFilteredBlockchain(filteredBs);
        setTotalEarned(earningInFilter);
        let aprMultiplier=1;
        if(period ==='1month'){
            aprMultiplier=12.0;
        }
        else if(period ==='3months'){
            aprMultiplier=4.0;
        }
        else if(period ==='6months'){
            aprMultiplier=2.0;
        }
        setAprInPeriod(earningInFilter/iterValue*aprMultiplier*100.0);
       
    }, [assets, selectedAsset, selectedPortfolioAsset,selectedPortfolioAssetTypeFilter,selectedBlockchain,transactions])
    if(ctx != null && ctx.objectId != null && up != null && totalValue != null){
        if(selectedPortfolioAsset == null){
            let tabContent = (
                <div></div>
            )
            if(selectedTab === 'assets'){
                tabContent = (
                    <PortfolioAssetCardGrid code={ctx.objectId} assets={filteredAssets}/>
                )
            }
            else if(selectedTab ==='transactions'){
                tabContent = (
                    <Table tableData={tableData}/>
                )
            }
            displayModule = (
            <div>
                <div className='back' onClick={backClicked}></div>
                {actionForm}
                {createPortfolioAssetForm}
                {setValueForm}
                 <div className='obj-header'>
                        <div className='label'>Asset</div>
                        <div className='value'>
                            {sunvestyRenderDouble(totalAmount)} <span className='explainer'>{selectedAsset.toUpperCase()}</span>&nbsp;≈&nbsp;
                            <span>{sunvestyRenderFromInternal(totalValue,up.displayCurrency,up.baseCurrency,prices)}</span> <span className='explainer'>{up.displayCurrency.toUpperCase()}</span>
                            <div className='separator'></div>
                            <div className='label'>Profit</div>
                            <div className='value'>
                                {sunvestyRenderFromInternal(totalValue-totalInvested,up.displayCurrency,up.baseCurrency,prices)} <span className='explainer'>{up.displayCurrency}</span>
                        
                            </div>
                        </div>
                </div>
                <div className='card-layout'>
                <div className='card'>
                    <div className='content'>
                        <div>
                            <div className='card-label'>APR ({period})</div>
                            <div className='total'>{sunvestyRenderDouble(aprInPeriod)} <span className='explainer'>%</span></div>
                        </div>
                    </div>
                </div>
            </div>
                <div className='top-chart-container'>
                        <div className='main-chart thin'>
                            <EarningBarChart transactions={filteredEarnTrx}/>
                        </div>
                        <div className='main-chart thin'>
                            <ValueAmountChart asset={selectedAsset} portfolioAsset={selectedPortfolioAsset} portfolioAssets={filteredAssets}></ValueAmountChart>
                        </div>
                        <div className='clear'></div>
                    </div>
                <div className="card-layout">
                        
                        
                        <Card title="Value" value={totalValue} chartData={valuePieData} loading={props.loading}/>
                        <Card title="Value" value={0} chartData={earningTypePieData} loading={props.loading}/>
                        
                    </div>  
                    <div>
                    <div className='tabs'>
                            <div className={`tab ${selectedTab==="assets" ? "selected" : ""}`} onClick={() => handleSelectedTab('assets')}>
                                Assets
                            </div>
                            <div className={`tab ${selectedTab==="transactions" ? "selected" : ""}`}  onClick={() => handleSelectedTab('transactions')}>
                                Transactions
                            </div>
    
                        </div>
                        <div className='tab-content'>
                            {tabContent}
                        </div>
                    </div>
                
            </div>
            )
        }
        else{
            
            displayModule = <PortfolioAsset portfolioAsset={selectedPortfolioAsset}></PortfolioAsset>
        }
        
        
    }
    return (
        
        <div className='main beta'>
            
            <div className="display-selector-container">
                <div className="left">
                    <SelectDisplayCurrency/>
                    <SelectDisplayPeriod/>
                    <SelectPortfolioAssetType active={selectedPortfolioAssetTypeFilter} callback={handleSelectAssetFilter}/>
                    <SelectAsset typeFilter={selectedPortfolioAssetTypeFilter} callback={handleSelectAsset}/>
                    {customSelects}

                </div>

            </div>
            
            {displayModule}
           
        </div>
    ) 
    
    
}

export default Asset;