import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
    values:null,
    status: 'idle',
    period:'3months',
    error: null
  };
  export const fetchHistoryValueData = createAsyncThunk('history/fetchValue', async (arg,{ getState }) => {
    const state = getState();
    console.log('Value Period: ' + state.valueHistory.period);
    const mainFetchUrl = global.config.baseUrl+'/v1/api/history-value?fetch-period='+state.valueHistory.period;//https://api.sunvesty.com/v1/account-portfolios';
    const apiKey = sessionStorage.getItem('apikey');

    console.log('api in priceSlice: ' + apiKey)
    console.log('fetch prices url: ' + mainFetchUrl);
    const response = await fetch(mainFetchUrl, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' +apiKey
        },
        });
        console.log('fetch prices finished: ' + response.status);  
        
        return response.json();

    });
    export const historyValueSlice = createSlice({
        name: 'historyValue',
        initialState,
        reducers: {
            clearValueHistoryData: (state) => {
                state.values = null;
                state.error=null;
                state.status='idle';
              },
              setValuePeriod: (state,newPeriod) => {
                state.period = newPeriod.payload;
              }
        },
        extraReducers(builder) {
          builder
            .addCase(fetchHistoryValueData.pending, (state, action) => {
                state.status = 'loading'
                //console.log('-------------------loading')
              })
              .addCase(fetchHistoryValueData.fulfilled, (state, action) => {
                state.status = 'succeeded'
                //console.log('--------------------succeeded')
                //console.log('action.payload: ' + JSON.stringify(action.payload));
                // Add any fetched posts to the array
                state.values = action.payload;
                //state.portfolios = action.payload.portfolios;
                
              })
              .addCase(fetchHistoryValueData.rejected, (state, action) => {
                state.status = 'failed'
                //console.log('--------------------failed');
                state.error = action.error.message
              })
        }
      })
    
      export default historyValueSlice.reducer
      export const historyValues = state => state.data.values
      export const { clearValueHistoryData,setValuePeriod } = historyValueSlice.actions