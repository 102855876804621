module.exports = global.config = {
    //baseUrl:'http://localhost:8080',
    baseUrl:'https://api.sunvesty.com',
    version:'0.0.1b13',
    supportedTokens:['sek','eur', 'usdc','btc','eth','sol','atom','osmo','cro','kuji','evmos','luna','nft','rune','dot','matic','avax','inj','eurx','strd','rndr','astro','akt','usk','paxg','mnta','somm','pyth','tia'],
    supportedBlockchains:['bitcoin','ethereum','solana','osmosis','kujira','cosmos','cronos','thorchain','polygon','avalanche','arbitrum','evmos','injective','akash','secret','sommelier','celestia'],
    cryptoAssets:['usdc','btc','eth','sol','atom','osmo','cro','kuji','evmos','luna','nft','rune','dot','matic','avax','usk','paxg'],
    chartAssetTypeColor:{'defi_lp':'#f7a35b','defi_lending':'#f68d32','staking':'#8742f5','exchange':'#FF0000','coldstorage':'#90ed7d'},
    chartEarningTypeColor:{'lp':'#f7a35b','farming':'#f75ef0','interest':'#f68d32','staking':'#8742f5','card-cashback':'#4db368','coldstorage':'#90ed7d'}

}
