import React,{useState,useEffect} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useSelector } from 'react-redux'
import moment from 'moment';
import {sunvestyGetDisplayValueFromInternal} from "../../util/SunvestyRender";
import settingsImage from '../images/table_sort_asc.svg'
import finalPropsSelectorFactory from 'react-redux/es/connect/selectorFactory';

function ValueAmountChart(props){

    const dateFormat= 'YYYY-MM-DD';
    const [isSettingsOpen, setSettingsOpen] = useState(false);

    const [chartType, setChartType] = useState('value');
    const [categoryData, setCategoryData] = useState([]);
    const [categorySeries, setCategorySeries] = useState([]);
    
    const [periodToShow, setPeriodToShow] = useState('1month');
    const values = useSelector(state => state.valueHistory.values);
    const prices = useSelector(state => state.data.prices);
    const up = useSelector(state => state.data.userprofile);
    const valuePeriod =  useSelector(state => state.valueHistory.period);
    useEffect(()=>{
        var iterMoment = moment();
        const todayMoment = moment();
        var categoryDays=1;
        var cData = [];
        var sData = [];
        if(periodToShow === '1month'){
            iterMoment = moment().subtract(1,'months');
        }
        else if(periodToShow === '2months'){
            iterMoment = moment().subtract(2,'months');
        }
        else if(periodToShow === '3months'){
            iterMoment = moment().subtract(3,'months');
            categoryDays=7;
        }
        else if(periodToShow === '6months'){
            iterMoment = moment().subtract(6,'months');
            categoryDays=7;
        }
        else if(periodToShow === '1year'){
            iterMoment = moment().subtract(11,'months');
            categoryDays=7;
        }
        while(iterMoment.isBefore(todayMoment)){
            cData.push(iterMoment.format(dateFormat));
            iterMoment = iterMoment.add(categoryDays,'days');
        }
        var seriesObj;
        seriesObj={};
        seriesObj.name='Total';
        seriesObj.color='#ffa317'; 
        seriesObj.data=[];
        
        sData.push(seriesObj);
        cData.forEach((element,i) => {
            sData.forEach((element,j) => {
                sData[j].data[i]=0;
            });
        });
        var chartValue;
        values.forEach(eObj => {
            valueMoment=moment(eObj.timeStamp);
            //categoryData.push(valueMoment.format(dateFormat));
		    indexOfDate = cData.indexOf(valueMoment.format(dateFormat));
            if(prices != null){
                chartValue=0;
                if(props.portfolioAsset != null){
                    if(chartType === 'value' && eObj.portfolioAssetValues[props.portfolioAsset.id] != null){
                        chartValue= sunvestyGetDisplayValueFromInternal(eObj.portfolioAssetValues[props.portfolioAsset.id],up.displayCurrency,up.baseCurrency,prices);
                    }
                    else if(chartType === 'amount' && eObj.portfolioAssetAmounts[props.portfolioAsset.id] != null){
                        chartValue= sunvestyGetDisplayValueFromInternal(eObj.portfolioAssetAmounts[props.portfolioAsset.id],up.displayCurrency,up.baseCurrency,prices);
                    }
                }
                else if(props.portfolioAssets != null){
                    props.portfolioAssets.forEach((paObj,i) => {
                        if(chartType === 'value' && eObj.portfolioAssetValues[paObj.id] != null){
                            chartValue += sunvestyGetDisplayValueFromInternal(eObj.portfolioAssetValues[paObj.id],up.displayCurrency,up.baseCurrency,prices);
                        }
                        else if(chartType === 'amount' && eObj.portfolioAssetAmounts[paObj.id] != null){
                            chartValue += sunvestyGetDisplayValueFromInternal(eObj.portfolioAssetAmounts[paObj.id],up.displayCurrency,up.baseCurrency,prices);
                        }
                    });
                }
                else if(props.portfolioAsset == null){
                    if(chartType === 'value' && eObj.assetValues[props.asset] != null){
                        chartValue= sunvestyGetDisplayValueFromInternal(eObj.assetValues[props.asset],up.displayCurrency,up.baseCurrency,prices);
                    }
                    else if(chartType === 'amount' && eObj.assetAmounts[props.asset] != null){
                        chartValue= sunvestyGetDisplayValueFromInternal(eObj.assetAmounts[props.asset],up.displayCurrency,up.baseCurrency,prices);
                    }
                }
                
                sData[0].data[indexOfDate] += chartValue;
            }
            
            
            //categorySeries[0].data[indexOfDate] = eObj.cryptoValue;
        });
        setCategoryData(cData);
        setCategorySeries(sData);
       
    }, [values, periodToShow,props.asset,chartType,up.displayCurrency,props.portfolioAsset,props.portfolioAssets])
    useEffect(()=>{
        setPeriodToShow(valuePeriod);
       
    }, [valuePeriod]);
    const handleChartSettings = (newDisplay) => {
        setChartType(newDisplay);
        console.log('Clicked: ' + newDisplay);
    }

    if(values == null || up ==null){
        return (<div className='skeleton bar-chart'></div>)
    }
    else{
        
        
        var indexOfDate;
        var valueMoment;
        var valueDecimals=0;
        if(up.displayCurrency==='btc'){
            valueDecimals=4;
        }
        var displayCurrency = up.displayCurrency.toUpperCase();
        if(chartType === 'amount'){
            displayCurrency = props.asset.toUpperCase();
            if(props.asset==='btc'){
                valueDecimals=4;
            }
            else{
                valueDecimals=2;
            }
        }
        
        var areaSettingsObj={};
        areaSettingsObj = {
            fillColor: {
                linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1
                },
                stops: [
                    [0, '#ffa317'],
                    [1, Highcharts.color('#ffa317').setOpacity(0).get('rgba')]
                ]
            },
            marker: {
                radius: 2
            },
            lineWidth: 4,
            states: {
                hover: {
                    lineWidth: 2
                }
            },
            threshold: null
        };
        
        //categorySeries.push(seriesObj);
        
        var chartTitle = 'Value';
        if(chartType === 'amount'){
            chartTitle = 'Amount';
        }
        const options = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: 0,
                plotShadow: false,
                backgroundColor:'rgba(255,255,255,0.0)',
                type:'area'
            },
            legend: {
                enabled: false
            },
            title: {
                text: chartTitle,
                style:{color:'white'}
            },
            xAxis: {
                categories: categoryData,
                labels:{
                    style:{"color":'rgba(255,255,255,0.25)'}
                }
            },
            yAxis: {
                labels:{
                    style:{"color":'rgba(255,255,255,0.25)'}
                },
                title:{
                    style:{"color":"#FFF"},
                    text:displayCurrency
                },
                min:0
            },
            tooltip: {
                shared: true,
                valueDecimals: valueDecimals,
                valuePrefix:'',
                valueSuffix: ' '+displayCurrency,
                headerFormat: '<span style="font-size:12px"><b>{point.key}</b></span><br>'
            },
            plotOptions: {
                area: areaSettingsObj
            },     
            credits: {
                enabled: false
            },
            series: categorySeries
          };
          
        return (
            <div>
                
                <div className='text-right'>
                    <span className={`select-one ${isSettingsOpen?'open':''}`} onClick={() => setSettingsOpen(!isSettingsOpen)}>
                        <span className="selected">{chartType}</span>
                            <div className="select-items">
                                <div className="item" onClick={() => handleChartSettings('value')}>Value</div>
                                <div className="item" onClick={() => handleChartSettings('amount')}>Amount</div>
                            </div>
                    </span>
                </div>
    
                <HighchartsReact highcharts={Highcharts} options={options} />
    
            </div>
        );
    }
    
}

export default ValueAmountChart;