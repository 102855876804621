import React,{useState,useEffect} from 'react';
import {sunvestyGetDisplayValueFromInternal} from "../util/SunvestyRender";
import {sunvestyRenderFromInternal,sunvestyRenderDouble} from "../util/SunvestyRender";
import { useSelector,useDispatch } from 'react-redux';
import more_circles from './images/more_circles.svg'
import {setSetValueFormPortfolioAsset} from  "../features/context/contextSlice";
import {setObjectId} from '../features/context/contextSlice'

import {setShowActionFormAsset,setShowCreatePortfolioAssetForm} from "../features/context/contextSlice";
function AssetCard(props) {
    const dispatch = useDispatch();
    const ctx = useSelector(state => state.ctx);
    //const portfolioAsset = props.portfolioAsset;
    const up = useSelector(state => state.data.userprofile);
    const prices = useSelector(state => state.data.prices);
    //const [moreOpen, setMoreOpen] = useState(false);
    var displayDecimals = 2;
    if(props.asset.code === 'btc'){
      displayDecimals=4;
    }
    var pAssetValue=props.asset.value;
    if(up != null){
      pAssetValue = sunvestyGetDisplayValueFromInternal(pAssetValue,up.displayCurrency,up.baseCurrency,prices);
    }
    const handleClick=() =>{
      if(ctx != null){
        dispatch(setObjectId(props.asset.code));
      }
      props.onClick(props.asset.code)
    }

    
    //<div onClick={e => editAssetForm(e)}>Edit</div>
    return (
      <div className="card clickable" onClick={handleClick}>
        <div className="content">
          <div className="card-label">{props.asset.code} ({props.asset.portfolioAssets.length} investments)</div>
          
          <div className="total">{sunvestyRenderDouble(props.asset.amount,displayDecimals)} <span className="explainer">{props.asset.code}</span></div>
          <div className="separator"></div>
          <div className="grid2col">
            <div>
              <div className="value-label">Value</div>
              <div className="value">{sunvestyRenderFromInternal(props.asset.value,up.displayCurrency,up.baseCurrency,prices)} <span className="explainer">{up.displayCurrency}</span></div>
            </div>
            <div>
                <div className="value-label">Profit</div>
                <div>{sunvestyRenderFromInternal(props.asset.value - props.asset.invested,up.displayCurrency,up.baseCurrency,prices)} <span className="explainer">{up.displayCurrency}</span></div>
            </div>
            
          </div>
          
        </div>
        

      </div>
    );
  }
  export default AssetCard;