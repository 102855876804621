import React,{useState,useEffect} from 'react';
import {sunvestyGetDisplayValueFromInternal} from "../util/SunvestyRender";
import {sunvestyRenderFromInternal,sunvestyRenderDouble} from "../util/SunvestyRender";
import { useSelector,useDispatch } from 'react-redux';
import more_circles from './images/more_circles.svg'
import {setSetValueFormPortfolioAsset} from  "../features/context/contextSlice";

import {setShowActionFormAsset,setShowCreatePortfolioAssetForm} from "../features/context/contextSlice";
function PortfolioAssetCard(props) {
    const dispatch = useDispatch();
    const portfolioAsset = props.portfolioAsset;
    const up = useSelector(state => state.data.userprofile);
    const prices = useSelector(state => state.data.prices);
    const [moreOpen, setMoreOpen] = useState(false);
    var displayDecimals = 2;
    if(portfolioAsset.code === 'btc'){
      displayDecimals=4;
    }
    var pAssetValue=portfolioAsset.value;
    if(up != null){
      pAssetValue = sunvestyGetDisplayValueFromInternal(portfolioAsset.value,up.displayCurrency,up.baseCurrency,prices);
    }

    const showActionForm = () => {
      dispatch(setShowActionFormAsset(portfolioAsset));
      setMoreOpen(false);
      //console.log('Should show Action form');
    }
    
    const editAssetForm = (e) => {
      e.stopPropagation();
      dispatch(setShowCreatePortfolioAssetForm(portfolioAsset));
      setMoreOpen(false);
      //console.log('Should show Asset form');
    }
    const showSetValueForm = () => {
      dispatch(setSetValueFormPortfolioAsset(portfolioAsset));
      setMoreOpen(false);
      //console.log('Should show Action form');
    }
    const toggleMore = () => {
      setMoreOpen(!moreOpen);
      console.log("Toggle more");
    }
    var moreForm=<div></div>;
    if(moreOpen){
      moreForm = (<div className='more-form'>
        
        <div>
          <div className='item' onClick={e => editAssetForm(e)}>Edit</div>
          <div className='item' onClick={() => showActionForm()}>Actions</div>
          <div className='item' onClick={() => showSetValueForm()}>Set Value</div>
        
        </div>
        
      </div>)
    }
    var valueUpdated = (<div className="status">
                            <span className="dot green" onClick={showSetValueForm}></span>
                            <div className="content">
                              <div className='label'>Value updated:</div>
                              <div>{portfolioAsset.valueUpdated}</div>
                            </div>
                        </div>
    );
    if(portfolioAsset.valueUpdated == null){
      valueUpdated = (<div className="status">
                        <span className="dot red" onClick={showSetValueForm}></span>
                        <div className="content">
                          <div className='label'>Needs update</div>
                          <div>{portfolioAsset.valueUpdated}</div>
                        </div>
      </div>);
    }
    //<div onClick={e => editAssetForm(e)}>Edit</div>
    return (
      <div className="card">
        <div className="content">
          <div className="card-label">{portfolioAsset.code} - {portfolioAsset.name}</div>
          
          <div className="total">{sunvestyRenderDouble(portfolioAsset.amount,displayDecimals)} <span className="explainer">{portfolioAsset.code}</span></div>
          <div className="separator"></div>
          <div className="grid2col">
            <div>
              <div className="value-label">Value</div>
              <div className="value">{sunvestyRenderFromInternal(portfolioAsset.value,up.displayCurrency,up.baseCurrency,prices)} <span className="explainer">{up.displayCurrency}</span></div>
            </div>
            <div>
                <div className="value-label">Profit</div>
                <div>{sunvestyRenderFromInternal(portfolioAsset.value - portfolioAsset.invested,up.displayCurrency,up.baseCurrency,prices)} <span className="explainer">{up.displayCurrency}</span></div>
            </div>
            
          </div>
          <div className='more-circles' onClick={toggleMore}><img src={more_circles}/></div>
          {moreForm}
          {valueUpdated}
        </div>
        

      </div>
    );
  }
  export default PortfolioAssetCard;