import React from 'react';
import { useState } from 'react';
import { useSelector,useDispatch } from 'react-redux'
import { executeAssetActionCall } from '../../features/api/sunvestyApiSlice'

import moment from 'moment';
function WithdrawalTransactionForm(){
    const dispatch = useDispatch();
    const ctx = useSelector(state => state.ctx);
    const [withdrawAmount, setWithdrawAmount] = useState('');
    const [withdrawNote, setWithdrawNote] = useState('');
    const [withdrawTransactionDate, setWithdrawTransactionDate] = useState(moment().format('YYYY-MM-DD HH:mm:ss'));
    
    const apiStatus = useSelector(state => state.api.status);
    const handleSetWithdrawAmount = (newValue) =>{
        const nValue = newValue.replace(',','.');
        setWithdrawAmount(nValue);
        //calculateEarning(nValue,earningToken);
    } 
    var withdrawButton = <div></div>
    const withdrawAction = async(e) => {
        e.preventDefault();
        if(withdrawAmount > 0 && withdrawAmount <= ctx.showActionFormPortfolioAsset.amount){
            var trxDate=null;
            if(withdrawTransactionDate !== null && withdrawTransactionDate.trim()!==''){
                trxDate=withdrawTransactionDate;
            }
            const objToSend = {
                amount:withdrawAmount,
                note:withdrawNote,
                transactionDate:trxDate,
                portfolio:ctx.showActionFormPortfolioAsset.portfolioId,
                asset:ctx.showActionFormPortfolioAsset.id,
                command:'withdraw'

            };
            await dispatch(executeAssetActionCall(objToSend)).unwrap();
            //dispatch(dataWithdraw({amount:withdrawAmount, assetID:ctx.showActionFormPortfolioAsset.id}));
        }
        //console.log('Withdraw action called amount: ' + withdrawAmount);
    }
    

    if(apiStatus === 'idle' || apiStatus === 'failed'){
        withdrawButton = <div className='center'><button className="button" type="button" onClick={withdrawAction}>Withdraw</button></div>
    }
    return (
        <div>
            
                <form className='normal-form'>
                <div className='input-row'>
                    <label>Withdraw Amount</label>
                    <input type="text" value={withdrawAmount} onChange={e => handleSetWithdrawAmount(e.target.value)}></input>
                </div>
                <div className='input-row'>
                    <label>Transaction Date</label>
                    <input type="text" value={withdrawTransactionDate} onChange={e => setWithdrawTransactionDate(e.target.value)}></input>
                </div>
                <div className='input-row'>
                    <label>note</label>
                    <textarea value={withdrawNote} onChange={e => setWithdrawNote(e.target.value) } rows={3}/>
                </div>
                
                {withdrawButton}
            </form>

        </div>
       
        
    )



}

export default WithdrawalTransactionForm;