import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
const initialState = {
    prices:null ,
    portfolios:null,
    assets:null,
    userprofile:null,
    status: 'idle',
    error: null
  };

  export const fetchData = createAsyncThunk('data/fetchData', async () => {
    const mainFetchUrl = global.config.baseUrl+'/v1/account-portfolios';//https://api.sunvesty.com/v1/account-portfolios';
    const apiKey = sessionStorage.getItem('apikey');

    //console.log('api in priceSlice: ' + apiKey)
    //console.log('fetch prices url: ' + mainFetchUrl);
    const response = await fetch(mainFetchUrl, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' +apiKey
        },
        });
        console.log('fetch prices finished: ' + response.status);  
        
        return response.json();
    
    });
    const getAsset = (state,id) =>{
      var assetObj = null;
      state.assets.forEach((aObj,i) => {
        //console.log('Asset i: ' + i + ' Name: ' + aObj.name);
        if(aObj.id === id){
          assetObj=aObj;
        }
        
      });
      return assetObj;
    }
    const getPortfolio = (state,id) =>{
      var pObj = null;
      state.portfolios.forEach((aObj,i) => {
        //console.log('Asset i: ' + i + ' Name: ' + aObj.name);
        if(aObj.id === id){
          pObj=aObj;
        }
        
      });
      return pObj;
    }

    export const dataSlice = createSlice({
        name: 'data',
        initialState,
        reducers: {
            clearData: (state) => {
                // Redux Toolkit allows us to write "mutating" logic in reducers. It
                // doesn't actually mutate the state because it uses the Immer library,
                // which detects changes to a "draft state" and produces a brand new
                // immutable state based off those changes
                state.prices = null;
                state.portfolios = null;
                state.assets = null;
                state.userprofile = null;
                state.error=null;
                state.status='idle';
              },
              dataTransferAmount:(state,transferObj) => {
                console.log('Should transfer : ' + transferObj.payload.amount);
                var fromAsset = null;
                var toAsset = null;
                state.assets.forEach((aObj,i) => {
                  //console.log('Asset i: ' + i + ' Name: ' + aObj.name);
                  if(aObj.id === transferObj.payload.fromId){
                    fromAsset=aObj;
                  }
                  else if(aObj.id === transferObj.payload.toId){
                    toAsset=aObj;
                  }
                 
                });
                if(fromAsset != null && toAsset != null){
                  const fraction = transferObj.payload.amount/fromAsset.amount;
                  const invested = fraction*fromAsset.invested;
                  fromAsset.amount -= transferObj.payload.amount;
                  fromAsset.invested -= invested;
                  toAsset.amount += transferObj.payload.amount;
                  toAsset.invested +=invested;
                  //console.log('From asset amount ' + fromAsset.amount)
                  //console.log('To asset amount ' + toAsset.amount);
                }
                /*if(fromAsset != -1 && toAsset != -1){
                  state.assets[fromAsset].amount = state.assets[fromAsset].amount-transferObj.payload.amount;
                  state.assets[toAsset].amount = state.assets[toAsset].amount+transferObj.payload.amount;
                }*/
              }
              ,
              dataDeposit:(state,depositObj) =>{
                const assetObj = getAsset(state,depositObj.payload.assetId);
                /*var assetObj = null;
                state.assets.forEach((aObj,i) => {
                  //console.log('Asset i: ' + i + ' Name: ' + aObj.name);
                  if(aObj.id === depositObj.payload.assetId){
                    assetObj=aObj;
                  }
                 
                });*/
                if(assetObj!= null){
                  assetObj.amount += depositObj.payload.amount;
                  assetObj.invested += depositObj.payload.invested;
                }
                console.log('dataSlice:Deposit : ' + depositObj.payload.amount);
              },
              dataWithdraw:(state,withdrawObj) =>{
                const assetObj = getAsset(state,withdrawObj.payload.assetId);
                if(assetObj!= null){
                  const fraction = withdrawObj.payload.amount/assetObj.amount;
                  assetObj.amount -= withdrawObj.payload.amount;
                  assetObj.invested -= fraction*withdrawObj.payload.invested;
                }
                console.log('dataSlice:WithDraw : ' + withdrawObj.payload.amount);
              },
              setDisplayCurrency:(state,newDisplay) =>{
                console.log('Should update display to : ' + newDisplay.payload);
                state.userprofile.displayCurrency=newDisplay.payload;
              },
              dataUpdatePortfolio:(state,pObj) =>{
                var portfolioObj = getPortfolio(state,pObj.payload.id);
                if(portfolioObj == null){
                  state.portfolios.push(pObj.payload);
                }
                else{
                  portfolioObj.name = pObj.payload.name;
                  portfolioObj.type = pObj.payload.type;
                  portfolioObj.invested= pObj.payload.invested;
                  portfolioObj.value= pObj.payload.value;
                  portfolioObj.valueUpdated= pObj.payload.valueUpdated;
                }
              },
              dataUpdatePortfolioAssset:(state,pObj) =>{
                var portfolioAssetObj = getAsset(state,pObj.payload.id);
                if(portfolioAssetObj == null){
                  state.assets.push(pObj.payload);
                }
                else{
                  portfolioAssetObj.name = pObj.payload.name;
                  portfolioAssetObj.amount = pObj.payload.amount;
                  portfolioAssetObj.code = pObj.payload.code;

                  portfolioAssetObj.type = pObj.payload.type;
                  portfolioAssetObj.subType = pObj.payload.subType;
                  portfolioAssetObj.accountId = pObj.payload.accountId;
                  portfolioAssetObj.blockchain = pObj.payload.blockchain;
                  portfolioAssetObj.invested= pObj.payload.invested;
                  portfolioAssetObj.value= pObj.payload.value;
                  portfolioAssetObj.valueUpdated= pObj.payload.valueUpdated;
                  portfolioAssetObj.note= pObj.payload.note;
                }
              }
        },
        extraReducers(builder) {
          builder
            .addCase(fetchData.pending, (state, action) => {
              state.status = 'loading'
              console.log('-------------------loading')
            })
            .addCase(fetchData.fulfilled, (state, action) => {
              state.status = 'succeeded'
              console.log('--------------------succeeded')
              console.log('action.payload: ' + JSON.stringify(action.payload));
              // Add any fetched posts to the array
              state.prices = action.payload.priceMap;
              state.portfolios = action.payload.portfolios;
              state.assets = action.payload.assets;
              state.userprofile=action.payload.profile;
            })
            .addCase(fetchData.rejected, (state, action) => {
              state.status = 'failed'
              console.log('--------------------failed');
              state.error = action.error.message
            })
        }
      })
      
      
    
      export default dataSlice.reducer
      export const prices = state => state.data.prices
      export const userprofile = state => state.data.userprofile
      export const portfolios = state => state.data.portfolios
      export const { clearData,setDisplayCurrency,dataTransferAmount,dataDeposit,dataWithdraw,dataUpdatePortfolio,dataUpdatePortfolioAssset} = dataSlice.actions