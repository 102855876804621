import React,{useState} from 'react';
import { useSelector,useDispatch } from 'react-redux'
import {setObjectId} from '../../features/context/contextSlice'

function SelectPortfolio(props) {
    const dispatch = useDispatch();
    const ctx = useSelector(state => state.ctx);
    const portfolios = useSelector(state => state.data.portfolios);
    var selectedPortfolio=null;
    var displayName='All';
    const [isOpen, setOpen] = useState(false);
    
    
    /*
    const toggleOpen = () => {
        console.log('Toggle open: ' + isOpen);
        setOpen(!isOpen);
        
    }*/

    const handleSelectPortfolio = (newObject) => {
        if(ctx != null){
            dispatch(setObjectId(newObject));
        }
        //console.log('Clicked: ' + newDisplay);
    }
    const selectItems = portfolios.map((pfObj) =>
        <div className="item" onClick={() => handleSelectPortfolio(pfObj.id)} key={pfObj.id}>{pfObj.name}</div>
    );
    if(ctx != null){
        selectedPortfolio=ctx.objectId;
        if(ctx.objectId==null){
            displayName='all';
        }
        else{
            portfolios.forEach(pf => {
                if(pf.id===ctx.objectId){
                    displayName=pf.name;
                }
            });
            
        }
    }

    if(ctx != null){
        return (
            <span className={`select-one ${isOpen?'open':''}`} onClick={() => setOpen(!isOpen)}>
                <span className="selected">{displayName}</span>
                    <div className="select-items">
                        <div className="item" onClick={() => handleSelectPortfolio(null)}>All</div>
                        {selectItems}
                    </div>
            </span>
        )
    }
    else{
        return(<div>No ctx</div>)
    }
    
}
export default SelectPortfolio;