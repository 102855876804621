import React,{useState,useEffect} from 'react';
import { useSelector,useDispatch } from 'react-redux'
import {setObjectId} from '../../features/context/contextSlice'

function SelectAsset(props) {
    const dispatch = useDispatch();
    const ctx = useSelector(state => state.ctx);
    const assets = useSelector(state => state.data.assets);
    var selectedAsset=null;
    const [isOpen, setOpen] = useState(false);
    const [availableAssetCodes, setAvailableAssetCodes] = useState([]);
    
    let displayName = ctx.objectId;

    if(displayName === null){
        displayName='All';
    }
    
    useEffect(()=>{
        var filteredArray=[];
        assets.forEach(aObj => {
            
            if((props.typeFilter === 'all' || aObj.type ===props.typeFilter)&&  !filteredArray.includes(aObj.code)){
                filteredArray.push(aObj.code);
                //console.log('DID FIND: ' + aObj.code);
            }
            else{
                //console.log('DID NOT FIND: ' + aObj.code);
            }
        });
        setAvailableAssetCodes(filteredArray);
       
    }, [assets,props.typeFilter])

    /*
    const toggleOpen = () => {
        console.log('Toggle open: ' + isOpen);
        setOpen(!isOpen);
        
    }*/

    const handleSelectAsset = (newObject) => {
        /*if(newObject != null){
            setDisplayName(newObject);
        }
        else{
            setDisplayName('All');
        }*/
        
        if(ctx != null){
            dispatch(setObjectId(newObject));
        }
        //props.callback((newObject)=>{});
        props.callback(newObject);
        //console.log('Clicked: ' + newDisplay);
    }
    const selectItems = availableAssetCodes.map((aObj) =>
        <div className="item" onClick={() => handleSelectAsset(aObj)} key={aObj}>{aObj}</div>
    );
    

    if(ctx != null){
        return (
            <span className={`select-one ${isOpen?'open':''}`} onClick={() => setOpen(!isOpen)}>
                <span className="selected">{displayName}</span>
                    <div className="select-items">
                        <div className="item" onClick={() => handleSelectAsset(null)}>All</div>
                        {selectItems}
                    </div>
            </span>
        )
    }
    else{
        return(<div>No ctx</div>)
    }
    
}
export default SelectAsset;