import React from 'react';
import { useEffect,useState } from 'react';
import { useSelector,useDispatch } from 'react-redux'
import { executeAssetActionCall,clear } from '../../features/api/sunvestyApiSlice'
import { dataDeposit } from '../../features/data/dataSlice'
import moment from 'moment';
function DepositTransactionForm(){
    const dispatch = useDispatch();
    const ctx = useSelector(state => state.ctx);
    const up = useSelector(state => state.data.userprofile);
    const prices = useSelector(state => state.data.prices);
    const [depositAmount, setDepositAmount] = useState('');
    const [depositInvested, setDepositInvested] = useState('');
    const [depositTransactionDate, setDepositTransactionDate] = useState(moment().format('YYYY-MM-DD HH:mm:ss'));
    const [depositNote, setDepositNote] = useState('');
    const apiStatus = useSelector(state => state.api.status);
    const apiRespObj = useSelector(state => state.api.respObj);
    var depositButton = <div></div>
    const calculateInvested = (eAmount,eToken) =>{
        const baseCurrency = up.baseCurrency;
        if(eToken === baseCurrency){
            setDepositInvested(eAmount);
        }
        else if(baseCurrency === 'usd'){
            if(prices['usd-'+eToken]!= null){
                setDepositInvested(eAmount*prices['usd-'+eToken]);
            }
            
        }
        else {
            let pToken = eToken;
            if(eToken==='eurx'){
                pToken='eur';
            }
            if(prices['usd-'+pToken]!= null){
                var roundingDecimals = 2;
                const price = prices['usd-'+pToken]/prices['usd-'+up.baseCurrency];
                if(up.baseCurrency === 'btc'){
                    roundingDecimals = 6;
                }
                setDepositInvested((eAmount*price).toFixed(roundingDecimals));
                //console.log('Selectd token'+eAmount+': '+earningToken + price);
            }
            
        }
        
        
    }
    const depositAction = async(e) => {
        e.preventDefault();
        if(depositAmount > 0){
            var trxDate=null
            if(depositTransactionDate != null && depositTransactionDate.trim()!=''){
                trxDate=depositTransactionDate;
            }
            const objToSend = {
                amount:depositAmount,
                invested:depositInvested,
                transactionDate:trxDate,
                note:depositNote,
                portfolio:ctx.showActionFormPortfolioAsset.portfolioId,
                asset:ctx.showActionFormPortfolioAsset.id,
                command:'deposit'

            };
            await dispatch(executeAssetActionCall(objToSend)).unwrap();
            
        }
        //console.log('Deposit action called amount: ' + depositAmount);
    }
    

    if(apiStatus == 'idle' || apiStatus == 'failed'){
        depositButton = <div className='center'><button className="button" type="button" onClick={depositAction}>Deposit</button></div>
    }
    const handleSetDepositAmount = (newValue) =>{
        const nValue = newValue.replace(',','.');
        setDepositAmount(nValue);
        if( up.baseCurrency == ctx.showActionFormPortfolioAsset.code){
            setDepositInvested(nValue);
        }
        else{
            calculateInvested(nValue,ctx.showActionFormPortfolioAsset.code);
        }
    } 
    return (
        <div>
            
            <form className='normal-form'>
                <div className='input-row'>
                    <label>Deposit Amount</label>
                    <input type="text" value={depositAmount} onChange={e => handleSetDepositAmount(e.target.value)}></input>
                </div>
                <div className='input-row'>
                    <label>Invested</label>
                    <input type="text" value={depositInvested} onChange={e => setDepositInvested(e.target.value)}></input>
                </div>
                <div className='input-row'>
                    <label>Transaction Date</label>
                    <input type="text" value={depositTransactionDate} onChange={e => setDepositTransactionDate(e.target.value)}></input>
                </div>
                <div className='input-row'>
                    <label>note</label>
                    <textarea value={depositNote} onChange={e => setDepositNote(e.target.value) } rows={3}/>
                </div>

                {depositButton}
            </form>

        </div>
        
    )



}

export default DepositTransactionForm;