import React from 'react';
import { useEffect,useState } from 'react';
import { useSelector,useDispatch } from 'react-redux'
import { executeAssetActionCall } from '../../features/api/sunvestyApiSlice'
import moment from 'moment';
function TransferTransactionForm(){
    const dispatch = useDispatch();
    const ctx = useSelector(state => state.ctx);
    const assets = useSelector(state => state.data.assets);
    const portfolios = useSelector(state => state.data.portfolios);
    const [selectableAssets, setSelectableAssets] = useState([]);
    const [transferAmount, setTransferAmount] = useState('');
    const [transferNote, setTransferNote] = useState('');
    const [toAssetId, setToAssetId] = useState('');
    const [transferTransactionDate, setTransferTransactionDate] = useState(moment().format('YYYY-MM-DD HH:mm:ss'));
    
    const apiStatus = useSelector(state => state.api.status);
    var transferButton = <div></div>
    const toAssetItems = selectableAssets.map((aObj) =>
        <option value={aObj.asset.id} key={aObj.asset.id}>{aObj.portfolio.name} - {aObj.asset.name} ({parseFloat(aObj.asset.amount).toFixed(2)} {aObj.asset.code.toUpperCase()})</option>
    );
    const getPortfolioById =(id)=>{
        var returnP=null;
        portfolios.forEach(pObj => {
            if(pObj.id ===id){
                returnP=pObj;
            }
        }
        )
        return returnP;
    }
    
    
    useEffect(() => {
        var filteredAssets=[]
        if(assets != null && ctx.showActionFormPortfolioAsset != null){
            assets.forEach(aObj => {
                if(aObj.id !== ctx.showActionFormPortfolioAsset.id
                    && aObj.code === ctx.showActionFormPortfolioAsset.code){
                        filteredAssets.push({asset:aObj,portfolio:getPortfolioById(aObj.portfolioId)});
                }
            });
            
        }
        setSelectableAssets(filteredAssets);
      }, [assets,ctx.showActionFormPortfolioAsset]);
    const handleSelectToAsset = (event)=>{
        setToAssetId(event.target.value);
        console.log('To asset id: ' + event.target.value);
    }
    const fillAllTransferAmountAction = () => {
        //dispatch(dispatch(setShowActionFormAsset(null)));
        console.log('Fillall action called');
    }
    const transferAction = async(e) => {
        e.preventDefault();
        

        const amountToTransfer = parseFloat(transferAmount);
        //dispatch(dispatch(setShowActionFormAsset(null)));
        if(amountToTransfer> 0 && toAssetId!==''){
            try{
                const transferToAsset = parseInt(toAssetId);
                const objToSend = {
                    amount:amountToTransfer,
                    fromId:ctx.showActionFormPortfolioAsset.id,
                    toId:transferToAsset,
                    note:transferNote,
                    transactionDate:transferTransactionDate,
                    portfolio:ctx.showActionFormPortfolioAsset.portfolioId,
                    asset:ctx.showActionFormPortfolioAsset.id,
                    command:'transfer'
    
                };
                //await dispatch(executeAssetActionCall({amount:amountToTransfer})).unwrap();
                //dispatch(dataTransferAmount({amount:amountToTransfer,fromId:ctx.showActionFormPortfolioAsset.id,toId:transferToAsset}));
                await dispatch(executeAssetActionCall(objToSend)).unwrap();
                //dispatch(setShowActionFormAsset(null));
                //dispatch(clear());
    ;        }
            catch(err){
                console.log(err);
            }
        }
        else{

        }
        console.log('Transfer action called amount: ' + transferAmount);
    }
    
    if(toAssetId==='' && selectableAssets.length > 0){
        setToAssetId(selectableAssets[0].asset.id);
    }
    if(apiStatus === 'idle' || apiStatus === 'failed'){
        transferButton = <div className='center'><button className="button" type="button" onClick={transferAction}>Transfer</button></div>
    }
    return (
        <div>
            
            <form className='normal-form'>
                <div className='input-row'>
                    <label>Amount {ctx.showActionFormPortfolioAsset != null?parseFloat(ctx.showActionFormPortfolioAsset.amount).toFixed(2):'0'}</label>
                    <input type="text" value={transferAmount} onChange={e => setTransferAmount(e.target.value)}></input>
                </div>
                <div className='input-row'>
                    <label>To:</label>
                    <select value={toAssetId} onChange={e => handleSelectToAsset(e)}>
                        {toAssetItems}
                    </select>
                </div>
                <div className='input-row'>
                    <label>Transaction Date</label>
                    <input type="text" value={transferTransactionDate} onChange={e => setTransferTransactionDate(e.target.value)}></input>
                </div>
                <div className='input-row'>
                        <label>note</label>
                        <textarea value={transferNote} onChange={e => setTransferNote(e.target.value) } rows={3}/>
                    </div>
                {transferButton}
            </form>
                

        </div>
        
        
    )



}

export default TransferTransactionForm;