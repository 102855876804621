import AssetCard from "./AssetCard";
import { useSelector } from 'react-redux'
import React,{useState,useEffect} from 'react';

function AssetCardGrid(props) {
  const assets = useSelector(state => state.data.assets);
  const [filteredAssets, setFilteredAssets] = useState([]);
  const templateAsset = {name:'',code:'',amount:0,invested:0,value:0,portfolioAssets:[]}

  useEffect(()=>{
    let filteredArray=[];
    let assetObj;
    assets.forEach(pAssetObj => {
      if(props.typeFilter==='all' || props.typeFilter ===pAssetObj.type){
        assetObj=null;
        filteredArray.forEach(aObj => {
          if(aObj.code === pAssetObj.code){
            assetObj = aObj;
          }
        });
        if(assetObj === null){
          assetObj = JSON.parse(JSON.stringify(templateAsset));
          assetObj.code = pAssetObj.code;
          assetObj.name = pAssetObj.code;
          filteredArray.push(assetObj);  
        }
        assetObj.amount += pAssetObj.amount;
        assetObj.value +=pAssetObj.value;
        assetObj.invested += pAssetObj.invested;
  
        assetObj.portfolioAssets.push(JSON.parse(JSON.stringify(pAssetObj)));
      }
      
      
    });
    filteredArray.sort(function(a, b){return b.value - a.value});
    setFilteredAssets(filteredArray);
   
}, [assets,props])
    
    
    const pAssetCards = filteredAssets.map((aObj) =>
      <AssetCard asset={aObj} key={aObj.code} onClick={() => props.onSelect(aObj.code)}/>
    );
    

    return (
        <div>
          <div>Total: {filteredAssets.length}</div>
          <div className="card-layout">
            {pAssetCards}
          </div>
           
        </div>
      );
}
export default AssetCardGrid;