import React, { useState,useEffect } from 'react';
import SelectDisplayCurrency from '../module/ui/SelectDisplayCurrency';
import SelectPortfolio from '../module/ui/SelectPortfolio'
import PortfolioCardGrid from '../module/PortfolioCardGrid';
//import PortfolioCard from '../module/PortfolioCard';
import PortfolioAssetCardGrid from '../module/PortfolioAssetCardGrid';
import PortfolioAssetActionForm from '../module/PortfolioAssetActionForm';
import SetPortfolioValueForm from '../module/SetPortfolioValueForm';
import Table from '../module/ui/Table';
import {sunvestyRenderFromInternal} from "../util/SunvestyRender";
import { useSelector,useDispatch } from 'react-redux'
import PortfolioValueChart from '../module/PortfolioValueChart';
import EarningBarChart from '../module/EarningBarChart';
import {setShowCreatePortfolioForm,setShowCreatePortfolioAssetForm} from "../features/context/contextSlice";
import CreatePortfolioForm from '../module/CreatePortfolioForm';
import CreatePortfolioAssetForm from '../module/CreatePortfolioAssetForm ';
import {setObjectId} from '../features/context/contextSlice'
import SelectDisplayPeriod from '../module/ui/SelectDisplayPeriod';
import SelectBlockchain from '../module/ui/SelectBlockchain';

/*class Portfolio extends React.Component{

    constructor(props) {
        super(props);
        this.state={}
        this.portfolios = props.portfolios;
        if(props.portfolios != null){
            const listItems = this.portfolio.map((pfObj) =>
            <li key={pfObj.name}>
                {pfObj.name}
            </li>
        );
        }
        else{

        }
        
      }
      componentDidMount() {
        console.log('Portfolio mounted');
        
        
      }  
    render() {
        return (<div>Portfolio</div>)
    }
}*/
function Portfolio(props) {
    const dispatch = useDispatch();
    const portfolios = useSelector(state => state.data.portfolios);
    const assets = useSelector(state => state.data.assets);
    const ctx = useSelector(state => state.ctx);
    const up = useSelector(state => state.data.userprofile);
    const prices = useSelector(state => state.data.prices);
    //const earnings = useSelector(state => state.earningHistory.earnings);
    const transactions = useSelector(state => state.transactions.transactions);
    //const trxEarnings = useSelector(state => state.transactions.earnings);
    var displayModule = <PortfolioCardGrid portfolios={portfolios}/>;
    const [selectedTab,setSelectedTab]=useState('assets');
    const [filteredEarnTrx,setFilteredEarnTrx]=useState([]);
    const [filteredBlockchain,setFilteredBlockchain]=useState(null);
    const [selectedBlockchain,setSelectedBlockchain]=useState(null);
    const [filteredAssets, setFilteredAssets] = useState([]);
    const [tableData, setTableData] = useState([]);

    let backModule = null;
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    useEffect(() => {
        
        let myBlockchainSet = new Set();
        let filteredBs = [];
        let filteredArray=[];
        let filteredTableData = [];
        let filteredEarnArray=[];
        let trxAsset;
        if(ctx.objectId != null && assets != null){
            assets.forEach(aObj => {
                if(aObj.portfolioId===selectedPortfolio.id && aObj.blockchain !== ''){
                    myBlockchainSet.add(aObj.blockchain);
                }
                if(includeAssetInFilter(aObj)){
                    filteredArray.push(JSON.parse(JSON.stringify(aObj)));
                    
                }
            });
        }
        if(transactions != null){

            transactions.forEach(tObj => {
                trxAsset = getAsset(tObj.assetId);
                if(includeTransactionInFilter(trxAsset)){
                    filteredTableData.push(JSON.parse(JSON.stringify(tObj)));
                    if(tObj.type==='earning'){
                        filteredEarnArray.push(JSON.parse(JSON.stringify(tObj)));
                    }
                }
                
            });
            //console.log('Nr of earnings: ' + tableData.length);
        }
        /*trxEarnings.forEach(eObj => {
                        
            if( ctx.objectId === null  ||
                (ctx.objectId !== null && eObj.portfolioId === ctx.objectId) ){
                    fEarnTrxs.push(eObj);
                    
            }
            
            
        });*/
        filteredBs = Array.from(myBlockchainSet);
        setFilteredBlockchain(filteredBs);
        setFilteredAssets(filteredArray);
        setFilteredEarnTrx(filteredEarnArray);
        
        setTableData(filteredTableData);
      }, [ctx.objectId,transactions,prices,selectedBlockchain,selectedPortfolio])
    var headerModule=<div></div>;
    const getAsset= (id)=>{
        var returnObj = null;
        assets.forEach(aObj => {
                
            if( aObj.id === id){
                returnObj=aObj;
                return returnObj;
            }
        });
        return returnObj;
    }
    //[{id:123,date:'2022-11-23',type:'interest', token:'btc', amount:0.005, value:1500},
    //{id:125,date:'2022-11-25',type:'interest', token:'usdc', amount:15, value:150}];
    var actionForm=<div></div>;
    var setValueForm = <div></div>;
    const createNewAsset = ()=>{
        dispatch(setShowCreatePortfolioAssetForm(0));
        //console.log('New asset');
    }
    const createNewPortfolio = ()=>{
        dispatch(setShowCreatePortfolioForm(0));
        //console.log('New portfolio');
    }
    const editPortfolio = (id)=>{
        dispatch(setShowCreatePortfolioForm(id));
        //console.log('New portfolio');
    }
    /*const openSetPortfolioValueForm = (pObj)=>{
        console.log('Set portfolio value: ' + pObj.name);
    }*/
    const handleSelectedTab = (newSelected) =>{
        setSelectedTab(newSelected);
        console.log('New selected: ' + newSelected);
    }
    const handlePorfolioClicked = (pfObj)=>{
        //dispatch(setShowCreatePortfolioForm(id));
        dispatch(setObjectId(pfObj.id));
        //console.log('Clicked portfolio:' + pfObj.id);
    }
    const backClicked=()=>{
        console.log('Back clicked');
        dispatch(setObjectId(null));
    }
    const handleSelectBlockchain = (selected)=>{
        if(selected != null){
            console.log('Selected blockchain: ' + selected);
        }
        else{
            console.log('Selected portfolio asset: nullified');
        }
        setSelectedBlockchain(selected);
    }
    const includeAssetInFilter = (pAObj) =>{
        let blockchainFilter = selectedBlockchain == null || selectedBlockchain === pAObj.blockchain;
        let portfolioFilter = selectedPortfolio != null && pAObj.portfolioId === selectedPortfolio.id;
        return blockchainFilter && portfolioFilter;
    }
    const includeTransactionInFilter = (pAObj) =>{
        let blockchainFilter = selectedBlockchain == null || selectedBlockchain === pAObj.blockchain;
        let portfolioFilter = selectedPortfolio == null || pAObj.portfolioId === selectedPortfolio.id;
        return blockchainFilter && portfolioFilter;
    }
    

    var newPortfolioButton = <span></span>;
    var editPortfolioButton = <span></span>;
    var newPortfolioAssetButton = <span></span>;

    var createPortfolioForm = <div></div>;
    var createPortfolioAssetForm = <div></div>;
    if(ctx.showCreateFormPortfolio != null && ctx.showCreateFormPortfolio===0){
        createPortfolioForm = <CreatePortfolioForm/>
    }
    else if(ctx.showActionFormPortfolioAsset != null){
        actionForm = <PortfolioAssetActionForm/>
    }
    else if(ctx.showSetValueFormPortfolio != null){
        setValueForm = <SetPortfolioValueForm portfolio={ctx.showSetValueFormPortfolio}/>;
    }
    
    var customSelects = null;
    let displayBlockchainFilter = 'All';
    if(ctx.objectId != null && portfolios != null){
        var selectedPortfolio=null;
        portfolios.forEach(pf => {
            if(pf.id===ctx.objectId){
                selectedPortfolio=pf;
            }
        });
        if(selectedBlockchain !== null){
            displayBlockchainFilter = selectedBlockchain;
        }
        if(selectedPortfolio.type ==='crypto'){
            customSelects = (
                <span>
                    <SelectBlockchain blockchains={filteredBlockchain} displayName={displayBlockchainFilter} callback={handleSelectBlockchain}/>
                    
                
            </span>
            );
        }
        
        editPortfolioButton=<span className='button' onClick={() => editPortfolio(selectedPortfolio.id)}>Edit portfolio</span>
        if(ctx.showCreateFormPortfolio != null && ctx.showCreateFormPortfolio>0){
        
            createPortfolioForm = <CreatePortfolioForm portfolio={selectedPortfolio}/>
        }
        if(ctx.showCreateFormPortfolioAsset != null && ctx.showCreateFormPortfolioAsset===0){
            createPortfolioAssetForm = <CreatePortfolioAssetForm portfolio={selectedPortfolio}/>
        }
        else if(ctx.showCreateFormPortfolioAsset != null){
            createPortfolioAssetForm = <CreatePortfolioAssetForm portfolio={selectedPortfolio} pAsset={ctx.showCreateFormPortfolioAsset}/>
        }

        if(ctx.showSetValueFormPortfolioAsset != null){
            setValueForm = <SetPortfolioValueForm portfolio={selectedPortfolio} asset={ctx.showSetValueFormPortfolioAsset}/>;
        }
        backModule = <div className='back' onClick={backClicked}></div>
        
        var statusModule = ( <div className='status'>
                                    <span className="dot green"></span>
                                    <div className='content'>
                                        <div className='label'>Updated</div>
                                        <div>{selectedPortfolio.valueUpdated}</div>
                                    </div>
                                    </div>
                            );
        headerModule = (
            <div className='obj-header'>
                    <div className='label'>Portfolio - {selectedPortfolio.name}</div>
                    <div className='value'>
                    {sunvestyRenderFromInternal(selectedPortfolio.value,up.displayCurrency,up.baseCurrency,prices)} <span className='explainer'>{up.displayCurrency}</span>
                    
                    </div>
                    <div className='separator'></div>
                    <div className='label'>Profit</div>
                    <div className='value'>
                    {sunvestyRenderFromInternal(selectedPortfolio.value-selectedPortfolio.invested,up.displayCurrency,up.baseCurrency,prices)} <span className='explainer'>{up.displayCurrency}</span>
                    
                    </div>
                    {statusModule}
            </div>
        )
        var tabContent = <div></div>;
        if(selectedTab === 'assets'){
            tabContent = (
                <PortfolioAssetCardGrid portfolio={selectedPortfolio} assets={filteredAssets}/>
            )
        }
        else if(selectedTab ==='transactions'){
            tabContent = (
                <Table tableData={tableData}/>
            )
        }
        displayModule = (
            
                    <div>
                        <div className='tabs'>
                        <div className={`tab ${selectedTab==="assets" ? "selected" : ""}`} onClick={() => handleSelectedTab('assets')}>
                            Assets
                        </div>
                        <div className={`tab ${selectedTab==="transactions" ? "selected" : ""}`}  onClick={() => handleSelectedTab('transactions')}>
                            Transactions
                        </div>

                    </div>
                    <div className='tab-content'>
                        {tabContent}
                    </div>
                        
                        
                    </div>
            )
        newPortfolioAssetButton=<span className='button' onClick={createNewAsset}>New portfolio asset</span>
    }
    else{
        newPortfolioButton=<span className='button' onClick={createNewPortfolio}>New portfolio</span>
        displayModule = <PortfolioCardGrid portfolios={portfolios} clickAction={handlePorfolioClicked}/>;
    }

    return (
        <div className='main beta' >
            <div className='content'>
                <div className="display-selector-container">
                    <div className="left">
                        <SelectDisplayCurrency/>
                        <SelectDisplayPeriod/>
                        <SelectPortfolio/>
                        {customSelects}
                        {newPortfolioButton}
                        {editPortfolioButton}
                        {newPortfolioAssetButton}
                    </div>

                </div>
                {backModule}
                {headerModule}
                <div className='top-chart-container'>
                    <div className='main-chart thin'>
                        <EarningBarChart transactions={filteredEarnTrx}/>
                    </div>
                    <div className='main-chart thin'>
                        <PortfolioValueChart portfolio={ctx.objectId}/>
                    </div>
                    <div className='clear'></div>
                </div>
                {actionForm}
                {createPortfolioForm}
                {createPortfolioAssetForm}
                {setValueForm}
                {displayModule}
                
                

            </div>
            
            
        </div>
    ) 
    
    
}

export default Portfolio;