import sunvestyRenderDouble from "../util/SunvestyRender";
import {sunvestyRenderFromInternal} from "../util/SunvestyRender";
import store from '../app/store'
import { useSelector } from 'react-redux'
import DonutChart from "./DonutChart";
function Card(props) {
    
    const prices = useSelector(state => state.data.prices)
    
    const up = useSelector(state => state.data.userprofile)
    
    if(prices != null){
        //console.log('Price in card: ' + prices['usd-sek']);
        const displayCurrency=up.displayCurrency;
        const baseCurrency=up.baseCurrency;
        //console.log('Basecurrency: ' + up.baseCurrency);
        
        //<div className="total">{sunvestyRenderDouble(props.value,2,',',' ')} <span className="explainer">{baseCurrency.toUpperCase()}</span></div>
        //console.log('Displaycurrency: ' + up.displayCurrency);
        return (
            <div className="card">
                <div className="content">
                    <div className="card-label">{props.title}</div>
                    
                    <div className="total">{sunvestyRenderFromInternal(props.value,displayCurrency,baseCurrency,prices)} <span className="explainer">{displayCurrency.toUpperCase()}</span></div>
                    <DonutChart data={props.chartData}/>
                </div>

            </div>
        );
    }
    else{
        
    
    return (
        <div className="card">
            <div className="content">
                <div className="card-label">{props.title}</div>
                <div className="total"><div className="skeleton text large"></div></div>
                <div>
                   

                </div>
            </div>

        </div>
    );
    }
    
  }
  export default Card;