import logo from './images/sunvesty_logo.png';
import portfolio_personal_neutral_white from './images/portfolio_personal_neutral_white.svg';
import React, { useState, useEffect } from 'react';
//import LoginForm from './LoginForm';
//import { userprofile, clearData } from '../features/data/dataSlice'
import { logout } from '../features/login/loginSlice'
import { clearEarningHistoryData } from '../features/earningHistory/earningHistorySlice';
import { clearValueHistoryData } from '../features/valueHistory/valueHistorySlice';
import { clearTransactionData } from '../features/transactionHistory/transactionHistorySlice';
import {clearData} from '../features/data/dataSlice';
import { useSelector,useDispatch } from 'react-redux'
import { setDisplayCurrency,setDisplayModule,setObjectId} from '../features/context/contextSlice'

/*class Menu extends React.Component{

  constructor(props) {
    super(props);
    this.state={}
    
    
    if(this.props.data == null && this.props.userName == null){
      this.state.showLogin=true;
    }
    else{
      this.state.showLogin=false;
    }
    
    this.state.showAccountSubMenu=false;
    this.state.loggedIn=this.props.userName;
    console.log('Menu : ' +this.props.userName );
    this.doLogin = this.doLogin.bind(this);
    this.doLogout = this.doLogout.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleCancelLogin = this.handleCancelLogin.bind(this);
    this.toggleAccountSubMenu = this.toggleAccountSubMenu.bind(this);
  }
  componentDidMount() {
    console.log('Menu mounted');
    
    
  }
  componentWillUnmount() {
   
  }


  doLogin(){
    console.log('Log in clicked');
    //this.setState({loggedIn:'jangab@gmail.com'});
    
    this.setState({showLogin:true});
  }
  doLogout(){
    console.log('Log out clicked');
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('apikey');
    this.setState({loggedIn:null});
    this.props.onLogout();
  }

  handleLogin(login){
    console.log('Handle login : ' + login);
    this.setState({loggedIn:login});
    this.setState({showAccountSubMenu:false});
    this.setState({showLogin:false});
    this.props.onLogin(login);
  }
  handleCancelLogin(){
    this.setState({showLogin:false});
    
  }
  toggleAccountSubMenu(){
    console.log('toggleAccountSubMenu');
    this.setState(prevState => ({
      showAccountSubMenu: !prevState.showAccountSubMenu
    }));
  }

  render() {
   let displayLoggedId=true;
   //var loginButton = <button onClick={props.onClick}>Login</button>
   var loggedInText=<span></span>;
   var accountSubMenu=<div></div>
   var loginForm=<div></div>;
   //console.log('Portfolios: ' + this.props.data.portfolios);
   console.log('Props data: ' + this.props.data);
   if(this.state.loggedIn ==null){
      loggedInText=<span id="login-button" className="login-button" onClick={this.doLogin}>Login</span>;
      
   }
   else{
    //loggedInText=<span id="login-button" className="login-button" onClick={this.doLogout}>{this.state.loggedIn}</span>;
    loggedInText=<span className="name" onClick={this.toggleAccountSubMenu}>{this.state.loggedIn}</span>
    if(this.state.showAccountSubMenu){
      accountSubMenu=(
        <div className="submenu">
          <div className="logout-button-container">
            <span className="button" onClick={this.doLogout}>Logout</span>
          </div>

        </div>
      );
    }
   }
   if(this.state.showLogin){
    //loginForm=<LoginForm onLogin={this.handleLogin} onCancel={this.handleCancelLogin}/>;
   }

   return (
   <div>
    <div className="header">
      <a href="https://www.sunvesty.com"><img src={logo} className="logo" alt="logo" /></a>
      <div className="account">
        <div className="content">
          
          {loggedInText}
          

        </div>
        {accountSubMenu}
      </div>
    </div>
    {loginForm}
  </div>
  )
  }
}*/
function Menu(){
  const dispatch = useDispatch();
  const ctx = useSelector(state => state.ctx);

  
  const [displayAccountSubMenu, setDisplayAccountSubMenu] = useState(false);
  const userProfile = useSelector(state => state.data.userprofile)

  const toggleUserSubMenu= () => {
    setDisplayAccountSubMenu(!displayAccountSubMenu);
  }
  const setCurrentModule = (newModule) =>{
    dispatch(setDisplayModule(newModule));
  }

  const doLogout= () => {
    //setDisplayAccountSubMenu(!displayAccountSubMenu);
    console.log('Should logout');
    setDisplayAccountSubMenu(false);
    dispatch(logout());
    dispatch(setDisplayModule('dashboard'));
    dispatch(setObjectId(null));
    dispatch(clearEarningHistoryData());
    dispatch(clearValueHistoryData());
    dispatch(clearTransactionData());
    dispatch(clearData());

  }
  var loggedInText=<span></span>;
  if(userProfile != null){
    loggedInText=<span className="name" onClick={toggleUserSubMenu}>{userProfile.email}</span>
  }
  var accountSubMenu=<div></div>
  if(displayAccountSubMenu){
    accountSubMenu = (
      <div className="submenu">
        <div className="logout-button-container">
          <span className="button" onClick={doLogout}>Logout</span>
        </div>

      </div>
    );
  }
  return (
    <div>
     <div className="header">
       <a href="https://www.sunvesty.com"><img src={logo} className="logo" alt="logo" /></a>
       <ul className='navbar'>
          <li className={`dropdown ${ctx.displayModule==='dashboard'?'active':''}`} onClick={() => setCurrentModule('dashboard')}>
            <span className='menuitem'>Dashboard</span>
          </li>
          <li className={`dropdown ${ctx.displayModule==='portfolio'?'active':''}`} onClick={() => setCurrentModule('portfolio')}>
            <span className='menuitem' >Portfolio</span>
          </li>
          <li className={`dropdown ${ctx.displayModule==='asset'?'active':''}`} onClick={() => setCurrentModule('asset')}>
            <span className='menuitem' >Assets</span>
          </li>
       </ul>
       <div className="account">
         <div className="content">
           
           {loggedInText}
           
 
         </div>
         {accountSubMenu}
       </div>
     </div>
   </div>
   )
}

  
export default Menu;