import React,{useState} from 'react';
import { useSelector,useDispatch } from 'react-redux'

import {fetchTransactionData,setTrxPeriod } from '../../features/transactionHistory/transactionHistorySlice';
import { fetchHistoryValueData,setValuePeriod } from '../../features/valueHistory/valueHistorySlice';


function SelectPortfolioAssetType(props) {
    const dispatch = useDispatch();
    const up = useSelector(state => state.data.userprofile);
    const assetTypeSelected = props.active;
    //console.log('FetchPeriod is: ' + fetchPeriod);
    const [isOpen, setOpen] = useState(false);
    
    /*
    const toggleOpen = () => {
        console.log('Toggle open: ' + isOpen);
        setOpen(!isOpen);
        
    }*/

    const handleDisplayPeriod = (newDisplay) => {
        //dispatch(setTrxPeriod(newDisplay));
        //dispatch(fetchTransactionData());
        //dispatch(setValuePeriod(newDisplay));
        //dispatch(fetchHistoryValueData());
        console.log('Clicked: ' + newDisplay);
        props.callback(newDisplay);
    }
    

    if(up != null){
        return (
            <span className={`select-one ${isOpen?'open':''}`} onClick={() => setOpen(!isOpen)}>
                <span className="selected">{assetTypeSelected}</span>
                    <div className="select-items">
                        <div className="item" onClick={() => handleDisplayPeriod('all')}>All</div>
                        <div className="item" onClick={() => handleDisplayPeriod('crypto')}>Crypto</div>
                        <div className="item" onClick={() => handleDisplayPeriod('stock')}>Stock</div>
                        <div className="item" onClick={() => handleDisplayPeriod('fiat')}>Fiat</div>
                        <div className="item" onClick={() => handleDisplayPeriod('other')}>Other</div>
                    </div>
            </span>
        )
    }
    else{
        return(<div></div>)
    }
    
}
export default SelectPortfolioAssetType;