import React,{useState} from 'react';
import { useSelector,useDispatch } from 'react-redux'

import {fetchTransactionData,setTrxPeriod } from '../../features/transactionHistory/transactionHistorySlice';
import { fetchHistoryValueData,setValuePeriod } from '../../features/valueHistory/valueHistorySlice';


function SelectDisplayPeriod(props) {
    const dispatch = useDispatch();
    const up = useSelector(state => state.data.userprofile);
    const fetchPeriod = useSelector(state => state.transactions.period);
    console.log('FetchPeriod is: ' + fetchPeriod);
    const [isOpen, setOpen] = useState(false);
    
    /*
    const toggleOpen = () => {
        console.log('Toggle open: ' + isOpen);
        setOpen(!isOpen);
        
    }*/

    const handleDisplayPeriod = (newDisplay) => {
        dispatch(setTrxPeriod(newDisplay));
        dispatch(fetchTransactionData());
        dispatch(setValuePeriod(newDisplay));
        dispatch(fetchHistoryValueData());
        //console.log('Clicked: ' + newDisplay);
    }
    

    if(up != null){
        return (
            <span className={`select-one ${isOpen?'open':''}`} onClick={() => setOpen(!isOpen)}>
                <span className="selected">{fetchPeriod}</span>
                    <div className="select-items">
                        <div className="item" onClick={() => handleDisplayPeriod('1month')}>1 Month</div>
                        <div className="item" onClick={() => handleDisplayPeriod('3months')}>3 Months</div>
                        <div className="item" onClick={() => handleDisplayPeriod('6months')}>6 Months</div>
                        <div className="item" onClick={() => handleDisplayPeriod('1year')}>1 Year</div>
                    </div>
            </span>
        )
    }
    else{
        return(<div></div>)
    }
    
}
export default SelectDisplayPeriod;