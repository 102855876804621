import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useSelector } from 'react-redux'
import {sunvestyGetDisplayValueFromInternal} from "../util/SunvestyRender";
import moment from 'moment';

function BarChart(){
    //select * from s_portfolio_asset where portfolio_id=5672004862607360
    //const earnings = useSelector(state => state.earningHistory.earnings);
    //const transactions = useSelector(state => state.transactions.transactions);
    const earnings = useSelector(state => state.transactions.earnings);
    const period = useSelector(state => state.transactions.period);
    const prices = useSelector(state => state.data.prices)
    const up = useSelector(state => state.data.userprofile)
    
    if(earnings == null || up == null){
        return (<div className='skeleton bar-chart'></div>)
    }
    else{
        var today = moment();
        var seriesObj;
        var timeBarCategorySeries = [];
        var indexOfDate;
        var earningMoment;
        var data = [];
        var iterMoment;
        const displayCurrency = up.displayCurrency.toUpperCase();
        seriesObj={};
        seriesObj.name='Taxable Value';
        seriesObj.color='#2a8fff';
        var dateCatFormat = 'YYYY-MM';
        
        var barCategoryData = [];
        let numberOfMonths=3;
        if(period === '1year'){
            numberOfMonths=11;
        }
        else if(period === '6months'){
            numberOfMonths=5;
        }
        else if(period === '3months'){
            numberOfMonths=2;
        }
        else if(period === '1month'){
            numberOfMonths=1;
        }
        let i;
        if(period === '1month'){
            dateCatFormat = 'YYYY-MM-DD';
            for(i=30;i>=0;i--){
                iterMoment = moment().subtract(i,'days');
                barCategoryData.push(iterMoment.format(dateCatFormat));
                data.push(0);
            }
        }
        else{
            for(i=numberOfMonths;i>=0;i--){
                iterMoment = moment().subtract(i,'months');
                barCategoryData.push(iterMoment.format(dateCatFormat));
                data.push(0);
            }
        }
        
        
        seriesObj.data=data;

        
        
        timeBarCategorySeries.push(seriesObj);
        
        earnings.forEach(eObj => {
            earningMoment=moment(eObj.transactionDate);

		    indexOfDate = barCategoryData.indexOf(earningMoment.format(dateCatFormat));
            if(prices != null){
                seriesObj.data[indexOfDate] += sunvestyGetDisplayValueFromInternal(eObj.taxableValue,up.displayCurrency,up.baseCurrency,prices);
            }
            else{
                seriesObj.data[indexOfDate] += eObj.taxableValue;
            }
            
        });
        
        const options = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: 0,
                plotShadow: false,
                backgroundColor:'rgba(255,255,255,0.0)',
                type: 'column',
            },
            legend: {
                enabled: false
            },
            title: {
                text: 'Earnings',
                style:{ "color": "#FFF", "fontSize": "18px" }
            },
            xAxis: {
                categories: barCategoryData,
                labels:{
                    style:{"color":'rgba(255,255,255,0.25)'}
                }
            },
            yAxis:{
                labels:{
                    style:{"color":"#FFF"}
                },
                title:{
                    style:{"color":"#FFF"},
                    text:displayCurrency
                }
            },
            tooltip: {
                pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:.4f}</b> '+displayCurrency+'<br/>',
                shared: true
            },
            plotOptions: {
                
            },     
            credits: {
                enabled: false
            },
            series: timeBarCategorySeries
            
          };
          return (
            <div>
    
                <HighchartsReact highcharts={Highcharts} options={options} />
    
            </div>
        );
    }
    

    
}

export default BarChart;