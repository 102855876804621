import PortfolioCard from "./PortfolioCard";

function PortfolioCardGrid(props) {
    var portfolios = [];
    if(props.portfolios != null){
        portfolios = props.portfolios;
    }
    const handleClick = (clickedObj)=>{
      //console.log('Clicked id: ' + clickedObj.name);
      props.clickAction(clickedObj)
    }
    const portfolioCards = portfolios.map((pfObj) =>
      <PortfolioCard portfolio={pfObj} key={pfObj.id} clickFunction={handleClick}/>
    );
    

    return (
        <div>
          <div>Total: {portfolios.length}</div>
          <div className="card-layout">
            {portfolioCards}
          </div>
           
        </div>
      );
}
export default PortfolioCardGrid;